import React, { Component } from "react";
import { connect } from "react-redux";
import axios from "axios";
import urljoin from "url-join";
import queryString from "query-string";
import { isMobile } from "react-device-detect";
import axiosRetry from "axios-retry";

import { HoverButton, HeatMapImage } from "./helpers";

import "./Flujo.css";

import illus1 from "./img/question.png";
import illus2 from "./img/eye.png";
import illus3 from "./img/check.png";
import practica from "./img/practica.jpg";
import arrow from "./img/yellow-arrow.png";
import question2 from "./img/question2.png";
import check2 from "./img/check2.png";
import rotate from "./img/gire.png";
import arrow2 from "./img/arrow-v.png";

axiosRetry(axios, { retries: 3 });
axiosRetry(axios, { retryDelay: axiosRetry.exponentialDelay });

class Flujo extends Component {
  constructor(props) {
    super(props);
    this.getData = this.getData.bind(this);
    this.heatmapFinished = this.heatmapFinished.bind(this);
    this.setStep = this.setStep.bind(this);
    this.setScore = this.setScore.bind(this);
    this.checkOrientation = this.checkOrientation.bind(this);
    this.finishTest = this.finishTest.bind(this);
    this.screenRotated = this.screenRotated.bind(this);
    this.state = {
      step: "presentacion",
      type:
        this.props.type === "T"
          ? "online"
          : queryString.parse(this.props.location.search).eyetracker == "true"
          ? "on-the-field"
          : "online",
      currentImage: 0,
      toSend: [],
      loading: true,
      showScore: true,
      isVertical: window.innerHeight > window.innerWidth,
      experiment_id: parseInt(this.props.match.params.id, 10),
      user_id: parseInt(
        queryString.parse(this.props.location.search).user_id,
        10
      ),
      showRotate: window.innerHeight > window.innerWidth,
      lastHeatMap: {}
    };
  }
  componentDidMount() {
    this.getData();
    if (isMobile) {
      window.addEventListener("orientationchange", this.screenRotated, false);
      this.checkOrientation();
      setTimeout(() => {
        this.checkOrientation();
      }, 200);
    }
  }
  checkOrientation() {
    console.log(window.innerHeight, window.innerWidth);
    this.setState({
      isVertical: window.innerHeight > window.innerWidth,
      showRotate: window.innerHeight > window.innerWidth
    });
  }
  screenRotated() {
    setTimeout(() => {
      this.checkOrientation();
      setTimeout(() => {
        this.checkOrientation();
      }, 200);
    }, 200);
  }
  componentWillUpdate(nextProps, nextState) {}
  componentWillUnmount() {
    window.removeEventListener("orientationchange", this.screenRotated);
  }

  finishTest() {
    const { experiment_id, user_id, type, toSend } = this.state;
    if (this.state.type === "on-the-field") {
      toSend.shift();
    }

    axios
      .post(urljoin(this.props.api, "tests/finish/"), {
        experiment_id,
        user_id,
        experiment_id: this.state.experiment_id,
        test_id: this.state.test_id
      })
      .then(() => {
        setTimeout(() => {
          if (type === "on-the-field" || this.props.type === "R") {
            window.location.href = "/admin/tomarTest";
          }
        }, 5000);
      });
  }
  getData() {
    const id = this.props.match.params.id;
    axios
      .get(
        urljoin(
          this.props.api,
          "tests",
          `${id}${
            this.props.type === "R" ? `?user_id=${this.state.user_id}` : ""
          }`
        )
      )
      .then(response => {
        if (response.data.message) {
          alert(response.data.message);
          window.location.href = "/experimentos";
          return;
        }
        this.setState({ ...this.state, ...response.data, loading: false });

        const images = [];
        if (this.state.type === "on-the-field") {
          images.push({
            id: -1,
            original_url: practica,
            type: "beforeafter"
          });
        }
        images.push(...this.state.images);
        this.setState({
          images
        });
      })
      .catch(res => {
        alert(res);
        if (this.state.type === "on-the-field" || this.props.type === "R") {
          window.location.href = "/admin/tomarTest";
        } else {
          window.location.href = "/experimentos";
        }
      });
  }
  setStep(step) {
    this.setState({ step });
    if (step === "agradecimiento") {
      this.finishTest();
    }
  }
  heatmapFinished(data) {
    this.setState({
      lastHeatMap: data,
      show_score: true
    });
    if (isMobile) {
      this.setStep("newScore");
    } else {
      this.setStep("score");
    }
  }
  setScore(score) {
    const {
      currentImage,
      lastHeatMap,
      experiment_id,
      test_id,
      user_id
    } = this.state;
    console.log(this.state.images[currentImage]);
    const { image_before_after_id, image_map_id, type, id } = this.state.images[
      currentImage
    ];
   let image_id = id;
    if (!image_id) {
      image_id = image_before_after_id;
    }
    if (!image_id) {
      image_id = image_map_id;
    }
    axios.post(urljoin(this.props.api, "tests/answers/add/"), {
      score,
      heatmap: lastHeatMap,
      type,
      image_id,
      experiment_id,
      test_id,
      user_id
    })
  .catch(error => {
        alert(error);
      });

    this.setState({
      lastHeatMap: {},
      show_score: false,
      currentImage:
        currentImage >= this.state.images.length - 1
          ? 0
          : this.state.currentImage + 1
    });
    if (currentImage >= this.state.images.length - 1) {
      this.setStep("agradecimiento");
    } else {
      this.setStep("images");
    }
  }
  render() {
    const scores = [];
    for (let index = 1; index <= 10; index++) {
      scores.push(
        <HoverButton
          online={this.state.type !== "on-the-field"}
          scoreType={true}
          action={() => this.setScore(index)}
          key={index}
          number={index}
        >
          {index}
        </HoverButton>
      );
    }
    if (this.state.loading) {
      return (
        <div className="Degradado-bg">
          <div className="cargando">
            <i className="fa fa-spin fa-spinner" />
            <h3>Cargando Experimento</h3>
          </div>
        </div>
      );
    }
    if (this.state.showRotate) {
      return (
        <div className="Test dark-bg">
          <div className="container text-center py-5 p-lg-5">
            <div className="row justify-content-center align-items-center">
              <div className="col-12" id="rotate">
                <img src={rotate} alt="rotate" />
                <p className="h2">Gire su dispositivo</p>
              </div>
            </div>
          </div>
        </div>
      );
    }

    switch (this.state.step) {
      case "presentacion": {
        return (
          <div
            className={
              this.state.type === "on-the-field"
                ? "TestWrapper TestWrapper-onthefield"
                : "TestWrapper"
            }
          >
            <div className="Test dark-bg">
              <div className="container text-center py-5 p-lg-5">
                <div className="title">
                  <h3 className="h1">Instrucciones</h3>
                </div>
                <div className="description">
                  POR FAVOR EVALUE LAS SIGUENTES IMÁGENES SIGUIENDO ESTOS{" "}
                  {this.state.type === "online" ? 2 : 3} PASOS
                </div>
                <div className="row justify-content-center align-items-center">
                  {this.state.type === "online" ? (
                    <div className="row instructions-no-eye justify-content-center align-items-center">
                      <div className="col-12 col-sm-4">
                        <div className="illustration inst-img">
                          <img alt="¿?" src={question2} draggable="false" />
                        </div>
                        <div className="box-title">
                          <p className="h1">Paso 01</p>
                        </div>
                        <div className="box-description">
                          Lee atentamente la pregunta
                        </div>
                      </div>

                      <div className="d-block d-sm-none col-12">
                        <div className="ints-arrow-v">
                          <img alt="arrow" src={arrow2} draggable="false" />
                        </div>
                      </div>
                      <div className="d-none d-sm-block col-2">
                        <div className="arrow">
                          <img alt="arrow" src={arrow} draggable="false" />
                        </div>
                      </div>

                      <div className="col-12 col-sm-4">
                        <div className="illustration inst-img">
                          <img alt="Ojo" src={check2} draggable="false" />
                        </div>
                        <div className="box-title">
                          <p className="h1">Paso 02</p>
                        </div>
                        <div className="box-description">
                          Evalúe en una escala de 1 a 10
                        </div>
                      </div>
                    </div>
                  ) : (
                    <React.Fragment>
                      <div className="col-3">
                        <div className="illustration">
                          <img alt="¿?" src={question2} draggable="false" />
                        </div>
                        <div className="box-title">Paso 01</div>
                        <div className="box-description">
                          Lea atentamente la pregunta
                        </div>
                      </div>
                      <div className="col-1">
                        <div className="arrow">
                          <img
                            alt="Yellow Arrow"
                            src={arrow}
                            draggable="false"
                          />
                        </div>
                      </div>
                      <div className="col-3">
                        <div className="illustration">
                          <img alt="Ojo" src={illus2} draggable="false" />
                        </div>
                        <div className="box-title">Paso 02</div>
                        <div className="box-description">
                          Pensando en la pregunta, observe la fotografía
                        </div>
                      </div>
                      <div className="col-1">
                        <div className="arrow">
                          <img
                            alt="Yellow Arrow"
                            src={arrow}
                            draggable="false"
                          />
                        </div>
                      </div>
                      <div className="col-3">
                        <div className="illustration">
                          <img alt="Check" src={check2} draggable="false" />
                        </div>
                        <div className="box-title">Paso 03</div>
                        <div className="box-description">
                          Evalúe la fotografía en una escala de 1 a 10
                        </div>
                      </div>{" "}
                    </React.Fragment>
                  )}
                </div>
                <div className="continue-button">
                  <HoverButton
                    online={this.state.type !== "on-the-field"}
                    action={() =>
                      this.state.type === "on-the-field"
                        ? this.setStep("pregunta")
                        : this.setStep("images")
                    }
                  >
                    Continuar
                  </HoverButton>
                </div>
              </div>
            </div>
          </div>
        );
      }
      case "newScore": {
        return (
          <div className="newScore">
            <div className="head-question">
              <p>{this.state.pregunta}</p>
            </div>
            <div className="img-container">
              <img
                src={this.state.images[this.state.currentImage].original_url}
                alt="image question"
              />
            </div>
            <div className="range">
              <div className="text1">{this.state.text_left}</div>
              <ul className="circles">{scores}</ul>
              <div className="text2">{this.state.text_right}</div>
            </div>
          </div>
        );
      }
      case "pregunta": {
        return (
          <div
            className={
              this.state.type === "on-the-field"
                ? "TestWrapper TestWrapper-onthefield"
                : "TestWrapper"
            }
          >
            <div className="Test grey-bg centered-content">
              <div className="container text-center p-5">
                <div className="description2">{this.state.pregunta}</div>

                <HoverButton
                  online={this.state.type !== "on-the-field"}
                  action={() => this.setStep("images")}
                >
                  Continuar
                </HoverButton>
              </div>
            </div>
          </div>
        );
      }
      case "images": {
        return (
          <div
            className={
              this.state.type === "on-the-field"
                ? "TestWrapper TestWrapper-onthefield"
                : "TestWrapper"
            }
          >
            <div className="images">
              <div className="Test dark-bg centered-content">
                <HeatMapImage
                  key={this.state.currentImage}
                  url={this.state.images[this.state.currentImage].original_url}
                  record={this.state.type === "on-the-field"}
                  onFinish={this.heatmapFinished}
                />
              </div>
            </div>
          </div>
        );
      }
      case "score": {
        return (
          <div
            className={
              this.state.type === "on-the-field"
                ? "TestWrapper TestWrapper-onthefield"
                : "TestWrapper"
            }
          >
            <div className="Test grey-bg centered-content">
              <div className="container text-center p-5">
                <div className="description4">
                  Marque del 1 al 10 según su opinión
                </div>
                <div className="description3">{this.state.pregunta}</div>

                <div className="range">
                  <div className="text1">{this.state.text_left}</div>
                  <ul className="circles">{scores}</ul>
                  <div className="text2">{this.state.text_right}</div>
                </div>
                <div className="image">
                  <img
                    src={
                      this.state.images[this.state.currentImage].original_url
                    }
                    alt=""
                    height="480"
                  />
                </div>
              </div>
            </div>
          </div>
        );
      }
      case "agradecimiento":
        return (
          <div
            className={
              this.state.type === "on-the-field"
                ? "TestWrapper TestWrapper-onthefield"
                : "TestWrapper"
            }
          >
            <div className="Agra">
              <div className="container d-flex flex-column flex-wrap justify-content-center align-items-center pt-5">
                <div className="Agra-title-content">
                  <h3 className="Agra-title">Test Finalizado</h3>
                </div>
                <p className="Agra-text py-3 py-lg-5">
                  <span className="text-focus">Gracias</span> por su
                  participación
                </p>
              </div>
            </div>
          </div>
        );

      default:
        return <div>Paso no definido</div>;
    }
  }
}

function mapStateToProps(state) {
  return {
    user: state.auth.currentUser,
    type: state.auth.type,
    api: state.settings.api_url
  };
}
export default connect(mapStateToProps)(Flujo);
