import React, { Component } from "react";
import { Route, Switch } from "react-router"; // react-router v4
import { Flujo, ExperimentList } from "components/Test";
import { Nav, Footer } from "components";
import axios from "axios";
import { connect } from "react-redux";
import { isMobile } from "react-device-detect";

class Test extends Component {
  constructor(props) {
    super(props);
    if (!this.props.user) {
      window.location = "/";
    }
    axios.defaults.headers.common["Authorization"] = this.props.token;
    setTimeout(() => {
      this.props.dispatch({ type: "REMOVE_SUCCESS" });
    }, 10000);
  }
  render() {
    return (
      <React.Fragment>
        <div className="Test">
          {isMobile ? null : <Nav />}

          <Switch>
            <Route path="/experimentos/:id" component={Flujo} />
            <Route path="/" component={ExperimentList} />

            <Route render={() => <div>Miss</div>} />
          </Switch>
        </div>
        {isMobile ? null : <Footer />}
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    user: state.auth.currentUser,
    type: state.auth.type,
    token: state.auth.token
  };
}
export default connect(mapStateToProps)(Test);
