import React, { Component } from "react";
import axios from "axios";
import { connect } from "react-redux";
import urljoin from "url-join";
import { Link } from "react-router-dom";

import {
  PolygonsDrawer,
  handleChange,
  RequestError,
  calculatePolygons
} from "helpers";
import SharedPolygonsCategories from "./shared/SharedPolygonsCategories";
import "./ImagesBanks.css";

class Experiments extends Component {
  constructor(props) {
    super(props);
    this.add = this.add.bind(this);
    this.handleFocus = this.handleFocus.bind(this);
    this.edit = this.edit.bind(this);
    this.handleChange = this.handleChange.bind(this);

    this.getData = this.getData.bind(this);
    this.handleChangeEdit = this.handleChangeEdit.bind(this);

    this.addPolygon = this.addPolygon.bind(this);
    this.changeCategory = this.changeCategory.bind(this);
    this.removePolygon = this.removePolygon.bind(this);
    this.toggleVertex = this.toggleVertex.bind(this);
    this.changeImage = this.changeImage.bind(this);
    this.state = {
      section_name: "Bancos de Imágenes",
      section_name_singular: "Banco de Imágenes",
      url_folder: "imagesbanks",
      error: "",
      success: "",
      edit: {
        name: "",
        experiment: "",
        polygons: [],
        is_approved: false,
        images: [],
        answers: 0
      },
      add: { name: "", experiment: "", is_approved: false },
      table: [],
      users: [],
      experiments: [],
      categories: [],
      PolygonsDrawer: new PolygonsDrawer(),
      addingVertex: false,
      polygonsCategories: {}
    };
  }
  handleChange(e) {
    handleChange(e, this, "add");
  }
  changeImage(e) {
    this.setState({ backgroundimage: e.target.value });
  }
  handleChangeEdit(e) {
    handleChange(e, this, "edit");
  }
  getData() {
    const id = this.props.match.params.id;

    axios
      .get(urljoin(this.props.api, "experiments", "/"))
      .then(response => {
        this.setState({ experiments: response.data });
      })
      .catch(res => {
        alert(res);
      });

    if (this.props.user_type === "A") {
      axios
        .get(urljoin(this.props.api, "clients"))
        .then(response => {
          this.setState({ users: response.data });
        })
        .catch(res => {
          alert(res);
        });
    }

    if (id) {
      axios
        .get(urljoin(this.props.api, "imagebanks", id))
        .then(response => {
          this.setState({
            edit: {
              ...response.data,
              experiment: response.data.experiment.id
            },
            polygonsCategories: calculatePolygons(response.data.polygons),
            backgroundimage: response.data.original_url
          });

          this.state.PolygonsDrawer.setImage(document.getElementById("imagen"));
          this.state.PolygonsDrawer.setCanvas(
            document.getElementById("myCanvas")
          );
          if (response.data.original_url) {
            this.state.PolygonsDrawer.startCanvas();
            if (response.data.polygons) {
              const poligonos = response.data.polygons;
              for (let index = 0; index < poligonos.length; index++) {
                poligonos[index].coords = JSON.parse(poligonos[index].coords);
              }
              this.state.PolygonsDrawer.setPolygons(poligonos);
            }
          }
        })
        .catch(res => {
          alert(res);
        });

      axios
        .get(urljoin(this.props.api, "categories", "/"))
        .then(response => {
          this.setState({
            categories: response.data,

            add: {
              ...this.state.add,
              newcategory: response.data[0].id
            }
          });
        })
        .catch(res => {
          alert(res);
        });
    } else {
      axios
        .get(urljoin(this.props.api, this.state.url_folder, "/"))
        .then(response => {
          this.setState({ table: response.data });
        })
        .catch(res => {
          alert(res);
        });
    }
  }
  componentDidMount() {
    this.getData();
  }

  add(e) {
    this.setState({
      add: {
        ...this.state.add,
        client: parseInt(this.state.add.client, 10),
        experiment: parseInt(this.state.add.experiment, 10)
      }
    });
    e.preventDefault();
    axios
      .post(urljoin(this.props.api, "imagebank", "add", "/"), {
        ...this.state.add
      })

      .then(response => {
        if (response.data.status === "success") {
          this.props.dispatch({
            type: "SHOW_SUCCESS",
            success: response.data.message
          });
          window.location.href = urljoin("/admin/", this.state.url_folder, "/");
        } else {
          this.setState({ error: response.data.message });
        }
      })
      .catch(res => {
        this.setState({ error: RequestError(res) });
      });
  }
  edit(e) {
    e.preventDefault();
    const poligonos = this.state.PolygonsDrawer.getPolygons();
    for (let index = 0; index < poligonos.length; index++) {
      poligonos[index].coords = JSON.stringify(poligonos[index].coords);
    }
    this.setState({
      edit: {
        ...this.state.edit,
        polygons: poligonos ? poligonos : []
      }
    });
    this.setState({
      edit: {
        ...this.state.edit,
        client: parseInt(this.state.edit.client, 10),
        experiment: parseInt(this.state.edit.experiment, 10)
      }
    });
    console.log(this.state.edit);
    axios
      .put(
        urljoin(
          this.props.api,
          urljoin(
            "update_polygons",
            "imagebank",
            this.props.match.params.id.toString()
          )
        ),
        {
          ...this.state.edit
        }
      )

      .then(response => {
        this.props.dispatch({
          type: "SHOW_SUCCESS",
          success: "Banco de imágenes actualizado correctamente"
        });
        window.location.href = urljoin("/admin/", this.state.url_folder, "/");
      })
      .catch(error => {
        alert(error);
      });
  }

  componentDidUpdate = (prevProps, prevState) => {
    if (prevProps.location !== this.props.location) {
      this.getData();
      this.props.dispatch({
        type: "REMOVE_SUCCESS"
      });
    }
  };
  handleFocus(event) {
    event.target.select();
  }

  addPolygon() {
    const category = this.state.categories.filter(
      elem => elem.id === this.state.add.newcategory
    )[0];
    const polygons = this.state.PolygonsDrawer.addPolygon(category);
    this.setState({
      edit: {
        ...this.state.edit,
        polygons
      },
      polygonsCategories: calculatePolygons(polygons)
    });
  }
  changeCategory(event) {
    const id = event.target.getAttribute("data-id");
    const category = this.state.categories.filter(
      elem => elem.id === parseInt(event.target.value, 10)
    )[0];
    const polygons = this.state.PolygonsDrawer.changeCategory(
      parseInt(id, 10),
      category
    );
    this.setState({
      edit: {
        ...this.state.edit,
        polygons
      },
      polygonsCategories: calculatePolygons(polygons)
    });
  }
  removePolygon(event) {
    const id = event.target.getAttribute("data-id");
    const polygons = this.state.PolygonsDrawer.removePolygon(parseInt(id, 10));
    this.setState({
      edit: {
        ...this.state.edit,
        polygons
      },
      polygonsCategories: calculatePolygons(polygons)
    });
  }
  toggleVertex(event) {
    const id = event.target.getAttribute("data-id");
    this.state.PolygonsDrawer.ToggleAddVertex(
      parseInt(id, 10),
      this.state.addingVertex
    );
    const polygons = this.state.PolygonsDrawer.getPolygons();
    this.setState({
      addingVertex: !this.state.addingVertex,
      polygonsCategories: calculatePolygons(polygons)
    });
  }

  render() {
    const imagenes = [];
    if (this.props.match.params.id) {
      this.state.edit.images.forEach(imagen => {
        let nombre = imagen.split("/");
        nombre = nombre[nombre.length - 1];
        imagenes.push(
          <option key={imagen} value={imagen}>
            {nombre}
          </option>
        );
      });
    }

    const options = [];
    for (let key in this.state.experiments) {
      const { id, name, user_id } = this.state.experiments[key];
      let usuario = "";
      if (this.props.user_type === "A") {
        usuario = this.state.users.filter(
          elem => elem.client_id === user_id
        )[0];
        // console.log(usuario);
      }

      options.push(
        <option key={id} value={id}>
          {name}{" "}
          {this.props.user_type === "A"
            ? "(" + (usuario ? usuario.nombre_empresa : " ") + ")"
            : null}
        </option>
      );
    }
    const categories = [];
    this.state.categories.forEach(category => {
      categories.push(
        <option key={category.id} value={category.id}>
          {category.nombre}
        </option>
      );
    });

    const poligonos = [];
    if (this.state.edit.polygons) {
      this.state.edit.polygons.forEach((element, index) => {
        //console.log(element);
        poligonos.push(
          <li key={element.id} className="mb-3">
            Poligono {element.id}
            <select
              onChange={this.changeCategory}
              data-id={element.id}
              value={this.state.edit.polygons[index].category.id}
              className="mx-3"
            >
              {categories}
            </select>
            <button
              className="btn btn-urban btn-sm mr-3"
              data-id={element.id}
              onClick={this.toggleVertex}
              type="button"
            >
              {this.state.addingVertex ? "Dejar de Editar" : "Añadir Vertice"}
            </button>
            {element.id < 0 || this.state.edit.answers === 0 ? (
              <button
                className="btn btn-danger btn-sm"
                data-id={element.id}
                onClick={this.removePolygon}
                type="button"
              >
                <i className="fas fa-times" />
              </button>
            ) : null}
          </li>
        );
      });
    }

    if (this.props.match.params.id) {
      return (
        <div className="imageBanks">
          <form onSubmit={this.edit} method="post">
            {this.state.error ? (
              <div className="alert alert-danger" role="alert">
                {this.state.error}
              </div>
            ) : null}

            <h2 className="h4">
              Editar {this.state.section_name_singular} {this.state.edit.id}
            </h2>
            <div className="form-row my-3">
              <div className="form-group col-12 col-lg-6 field-input">
                <label htmlFor="name">Nombre</label>
                <input
                  type="text"
                  className="form-control"
                  name="name"
                  onChange={this.handleChangeEdit}
                  value={this.state.edit.name}
                  id="name"
                  placeholder="Ingrese Nombre"
                />
              </div>

              {this.props.user_type === "A" ? (
                <React.Fragment>
                  <div className="form-group col-12 col-lg-6">
                    <label htmlFor="is_approved" className="ckb">
                      Autorizado
                      <input
                        type="checkbox"
                        className="form-control"
                        name="is_approved"
                        onChange={this.handleChangeEdit}
                        value={this.state.edit.is_approved}
                        checked={this.state.edit.is_approved}
                        id="is_approved"
                      />
                      <i className="fas" />
                    </label>
                  </div>
                </React.Fragment>
              ) : null}

              <div className="form-group col-12 col-lg-6 field-select">
                <label htmlFor="experiment">Experimento</label>
                <select
                  className="form-control"
                  value={this.state.edit.experiment}
                  name="experiment"
                  onChange={this.handleChangeEdit}
                >
                  <option value="-1">Seleccione</option>
                  {options}
                </select>
              </div>

              {this.state.edit.original_url ? (
                <React.Fragment>
                  <div className="form-group col-12 col-lg-6 field-select">
                    <label htmlFor="tipo">Imagen de Fondo</label>
                    <select
                      className="form-control"
                      value={this.state.backgroundimage}
                      name="backgroundimage"
                      onChange={this.changeImage}
                    >
                      {imagenes}
                    </select>
                  </div>

                  <div className="canvasContainer col-12 my-3">
                    <img src={this.state.backgroundimage} id="imagen" />
                    <canvas id="myCanvas" />
                  </div>
                  <SharedPolygonsCategories
                    data={this.state.polygonsCategories}
                  />

                  <div id="canvasController" className="col-12">
                    <h3>Poligonos</h3>
                    <ul>{poligonos}</ul>
                    <h3>Acciones</h3>
                    <select
                      onChange={this.handleChange}
                      value={this.state.add.newcategory}
                      name="newcategory"
                      className="mr-3"
                    >
                      {categories}
                    </select>
                    <button
                      className="btn btn-urban btn-sm"
                      onClick={this.addPolygon}
                      type="button"
                    >
                      Añadir Poligono
                    </button>
                  </div>
                </React.Fragment>
              ) : null}
            </div>

            <button type="submit" className="btn btn-urban my-4 mb-5">
              Guardar Cambios
            </button>
          </form>
        </div>
      );
    }

    const rows = [];

    for (let key in this.state.table) {
      const el = this.state.table[key];
      rows.push(
        <tr key={el.id}>
          <td>{el.id}</td>
          <td>{el.name}</td>
          <td className="d-none d-md-block">{el.experiment.name}</td>
          <td>
            <Link to={`/admin/${this.state.url_folder}/${el.id}/edit`}>
              Editar
            </Link>
          </td>
        </tr>
      );
    }

    return (
      <div className="ImagesBanks">
        {!this.props.offline ? (
          <form onSubmit={this.add} method="post">
            <h2 className="h4">Añadir {this.state.section_name_singular}</h2>
            {this.state.error ? (
              <div className="alert alert-danger" role="alert">
                {this.state.error}
              </div>
            ) : null}
            {this.props.success ? (
              <div className="alert alert-urban alert-success" role="alert">
                {this.props.success}
              </div>
            ) : null}

            <div className="form-row my-3">
              <div className="form-group col-12 col-sm-12 col-lg-6 field-input">
                <label htmlFor="name">Nombre</label>
                <input
                  type="text"
                  className="form-control"
                  name="name"
                  onChange={this.handleChange}
                  value={this.state.add.name}
                  id="name"
                  placeholder="Ingrese Nombre"
                />
              </div>

              <div className="form-group col-12 col-sm-12 col-lg-6 field-select">
                <label htmlFor="experiment">Experimento</label>
                <select
                  className="form-control"
                  value={this.state.add.experiment}
                  name="experiment"
                  onChange={this.handleChange}
                >
                  <option value="-1">Seleccione</option>
                  {options}
                </select>
              </div>
            </div>

            <button type="submit" className="btn btn-urban">
              Añadir {this.state.section_name_singular}
            </button>
          </form>
        ) : null}
        <div className="urban-lista my-5">
          <h2 className="h6 mb-0">Listado de {this.state.section_name}</h2>
          <table className="table">
            <thead>
              <tr>
                <th>ID</th>
                <th>Nombre</th>
                <th className="d-none d-md-block">Experimento</th>
                <th>Acciones</th>
              </tr>
            </thead>
            <tbody>{rows}</tbody>
          </table>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    api: state.settings.api_url,
    success: state.ui.success,
    user_type: state.auth.type,
    test_route: state.settings.test_route,
    offline: state.settings.offline
  };
}
export default connect(mapStateToProps)(Experiments);
