import React, { Component } from "react";
import "./Producto.css";

export default class Producto extends Component {
  render() {
    return (
      <div className="Product">
        <div className="container">
          <div className="row py-5 d-none d-lg-flex">
            <nav className="Product-nav">
              <div className="nav nav-tabs" id="nav-tab" role="tablist">
                <a
                  className="nav-item nav-link active"
                  id="mapping-tab"
                  data-toggle="tab"
                  href="#tab-mapping"
                  role="tab"
                  aria-controls="tab-mapping"
                  aria-selected="true"
                >
                  <div className="tab-image" id="img-mapping" />
                  MAPAS
                </a>

                <a
                  className="nav-item nav-link"
                  id="prediction-tab"
                  data-toggle="tab"
                  href="#tab-prediction"
                  role="tab"
                  aria-controls="tab-prediction"
                  aria-selected="false"
                >
                  <div className="tab-image" id="img-prediction" />
                  PREDICCIÓN
                </a>

                <a
                  className="nav-item nav-link"
                  id="impact-tab"
                  data-toggle="tab"
                  href="#tab-impact"
                  role="tab"
                  aria-controls="tab-impact"
                  aria-selected="false"
                >
                  <div className="tab-image" id="img-impact" />
                  IMPACTO
                </a>
              </div>
            </nav>
            <div className="tab-content" id="nav-tabContent">
              <div
                className="tab-pane fade show active text-justify"
                id="tab-mapping"
                role="tabpanel"
                aria-labelledby="mapping-tab"
              >
                <div className="container">
                  <h3>Mapas</h3>
                  <p>
                    Este servicio permite determinar las áreas donde las
                    inversiones son más necesarias. Para esto, el inversor
                    determina el área urbana de su interés. Luego, Urban
                    Experiment hace un levantamiento y determina junto con el
                    inversor puntos adicionales a evaluar. Todos estos puntos
                    son posteriormente evaluados por los ciudadanos usando
                    nuestra metodología de Participatory Big Data. Como
                    resultado final, el inversor sabrá en que parte de un área
                    urbana determinada es más prioritario invertir.
                  </p>
                </div>
              </div>
              <div
                className="tab-pane fade text-justify"
                id="tab-prediction"
                role="tabpanel"
                aria-labelledby="prediction-tab"
              >
                <div className="container">
                  <h3>Predicción</h3>
                  <p>
                    Nuestra recolección de datos biométricos nos permite
                    identificar en que elementos del espacio público la gente se
                    centra para declarar sus percepciones. Utilizando nuestras
                    bases de datos recolectadas en terreno determinamos cuáles
                    son los elementos de esos espacios que conducen a
                    percepciones positivas y negativas de las inversiones. Con
                    este conocimiento generamos recomendaciones de
                    intervenciones adaptadas a distintos lugares y grupos de
                    personas
                  </p>
                </div>
              </div>
              <div
                className="tab-pane fade text-justify"
                id="tab-impact"
                role="tabpanel"
                aria-labelledby="impact-tab"
              >
                <div className="container">
                  <h3>Impacto</h3>
                  <p>
                    Este servicio permite determinar cuál de todas las
                    posibilidades de inversión es la más efectiva. Para esto
                    Urban Experiment en conjunto con los inversores determina
                    las opciones de inversión a considerar. Luego los ciudadanos
                    evalúan cada posibilidad de inversión usando nuestra
                    metodología de Participatory Big Data. Como resultado final,
                    los inversores sabrán en cuanto cada alternativa de
                    inversión mejora la percepción de los ciudadanos y cuál es
                    la alternativa más eficiente.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="d-lg-none py-4 services">
          <div className="services-content" id="mapping">
            <div className="services-img" id="mapping-image"></div>
            <div className="services-title-container">
              <h3 className="services-title">MAPAS</h3>
            </div>
            <div className="services-text container">
              <p>
                Este servicio permite determinar las áreas donde las
                inversiones son más necesarias. Para esto, el inversor
                determina el área urbana de su interés. Luego, Urban
                Experiment hace un levantamiento y determina junto con el
                inversor puntos adicionales a evaluar. Todos estos puntos
                son posteriormente evaluados por los ciudadanos usando
                nuestra metodología de Participatory Big Data. Como
                resultado final, el inversor sabrá en que parte de un área
                urbana determinada es más prioritario invertir.
              </p>
            </div>
          </div>
          <div className="services-content" id="prediction">
            <div className="services-img" id="prediction-image"></div>
            <div className="services-title-container">
              <h3 className="services-title">PREDICCIÓN</h3>
            </div>
            <div className="services-text container">
              <p>
                Nuestra recolección de datos biométricos nos permite
                identificar en que elementos del espacio público la gente se
                centra para declarar sus percepciones. Utilizando nuestras
                bases de datos recolectadas en terreno determinamos cuáles
                son los elementos de esos espacios que conducen a
                percepciones positivas y negativas de las inversiones. Con
                este conocimiento generamos recomendaciones de
                intervenciones adaptadas a distintos lugares y grupos de
                personas
              </p>
            </div>
          </div>
          <div className="services-content" id="impact">
            <div className="services-img" id="impact-image"></div>
            <div className="services-title-container">
              <h3 className="services-title">IMPACTO</h3>
            </div>
            <div className="services-text container">
              <p>
                Este servicio permite determinar cuál de todas las
                posibilidades de inversión es la más efectiva. Para esto
                Urban Experiment en conjunto con los inversores determina
                las opciones de inversión a considerar. Luego los ciudadanos
                evalúan cada posibilidad de inversión usando nuestra
                metodología de Participatory Big Data. Como resultado final,
                los inversores sabrán en cuanto cada alternativa de
                inversión mejora la percepción de los ciudadanos y cuál es
                la alternativa más eficiente.
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
