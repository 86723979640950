import React, { Component } from 'react';
import "./Conditions.css";

export default class Conditions extends Component {
    render() {
        return (
            <div className="Conditions py-4">
                <div className="container Conditions-text">
                    <h3>Términos y condiciones.</h3>
                    <p>
                        <span>Participant Code of Conduct</span>- Urban Experiment
                        respects your privacy and guarantees the anonymity of your
                        presonal data. Your personal data will not be shared with
                        third parties for commercial purposes. - By registering on our
                        website, you accept the terms and conditions of Urban
                        Experiment Management and Technology. - Please take your
                        participation in this experiment seriously. Remember that this
                        is a scientific research and has involved time and resources
                        to be organized. - The disrespectful behaviour of
                        participants, monitoring staff or researchers on this platform
                        may result in the project being prevented from carrying out
                        future studies and accessing grants or other funding once
                        agreed upon. Do you accept the code of conduct itemised in the
                        above points?
                    </p>
                </div>
            </div>
        )
    }
}
