import React, { Component } from "react";
import "./Page404.css";
import { Link } from "react-router-dom";

export default class Page404 extends Component {
  render() {
    return (
      <div className="Page404">
        <div className="container">
          <div className="d-flex flex-column justify-content-center align-items-center">
            <h1 className="text-center my-5 py-4">404 Not Found</h1>
            <Link to="/" className="btn-404">
              Volver al Inicio
            </Link>
          </div>
        </div>
      </div>
    );
  }
}
