import React, { Component } from "react";
import "./ArticleStudies.css";
// import { Link } from 'react-router-dom'

export default class ArticleStudie extends Component {
  render() {
    return (
      <div className="ArticleStudie">
        <div className="container px-0">
          <div className="row">
            <div className="col-12 col-lg-4">
              <div className="A-image-container">
                <img src={this.props.image} alt={this.props.title} />
              </div>
            </div>
            <div className="col-12 col-lg-8 mt-4 mt-lg-0">
              <div className="d-flex flex-column flex-wrap justify-content-center align-items-center">
                <div className="A-title-container">
                  <h1 className="Article-title">{this.props.title}</h1>
                </div>
                <div className="A-download-container">
                  <i className="fas fa-download" />
                  <a
                    href={this.props.downloadlink}
                    target="_blank"
                    className="A-donwload"
                  >
                    Download Link
                  </a>
                </div>
                <div className="A-body-container mt-3">
                  <p className="A-body-text">{this.props.abstract}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
