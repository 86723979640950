import React, { Component } from "react";
import axios from "axios";
import { connect } from "react-redux";
import urljoin from "url-join";
import { handleChange, RequestError } from "helpers";

import "./Register.css";
import arrow from "./img/arrow.png";

class Register extends Component {
  constructor(props) {
    super(props);
    this.registro = this.registro.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.getData = this.getData.bind(this);
    this.state = {
      error: "",
      comunas: [],
      success: "",
      add: {
        tipo: "T",
        genero: 0,
        rango_ingresos: 0,
        fecha_nacimiento: new Date().getFullYear() - 10,
        comuna: 0,
        n_hijos: 0
      }
    };
    if (this.props.user) {
      if (this.props.type !== "T") {
        window.location.href = "/admin/";
      } else {
        window.location.href = "/test/";
      }
    }
  }
  componentDidMount() {
    this.getData();
  }
  handleChange(e) {
    handleChange(e, this, "add");
  }
  getData() {
    axios
      .get(urljoin(this.props.api, "comunas", "/"))
      .then(response => {
        this.setState({ comunas: response.data });
      })
      .catch(res => {
        alert(res);
      });
  }
  registro(e) {
    e.preventDefault();
    const data = this.state.add;
    data.fecha_nacimiento = data.fecha_nacimiento + "-01-01";
    axios
      .post(urljoin(this.props.api, "users/add/"), {
        ...this.state.add
      })

      .then(response => {
        if (response.data.status === "success") {
          this.setState({
            success:
              "Registro realizado con éxito, hemos enviado tu contraseña al correo indicado. "
          });
          this.setState({
            add: {
              tipo: "T",
              genero: 0,
              rango_ingresos: 0,
              fecha_nacimiento: new Date().getFullYear() - 10,
              comuna: 0,
              n_hijos: 0
            }
          });
          if (sessionStorage.getItem("online_test") !== null) {
            if (response.data.auth_token) {
              localStorage.setItem("authToken", response.data.auth_token);
              const test_id = sessionStorage.getItem("online_test");
              sessionStorage.removeItem("online_test", "");
              window.location.href = "/share/" + test_id;
            } else {
              setTimeout(() => {
                window.location = "/login";
              }, 5000);
            }
          } else {
            setTimeout(() => {
              window.location = "/login";
            }, 5000);
          }
        } else {
          this.setState({ error: RequestError(response) });
        }
      })
      .catch(res => {
        this.setState({ error: RequestError(res) });
      });
  }

  render() {
    const options = [];
    this.state.comunas.forEach(key => {
      options.push(
        <option key={key} value={key}>
          {key}
        </option>
      );
    });

    const anios = [];
    for (
      let index = new Date().getFullYear() - 10;
      index > new Date().getFullYear() - 110;
      index--
    ) {
      anios.push(
        <option key={index} value={index}>
          {index}
        </option>
      );
    }

    return (
      <div className="Register">
        <div className="container">
          <form
            onSubmit={this.registro}
            method="post"
            className="container-box"
          >
            {this.state.error ? (
              <div className="alert alert-danger" role="alert">
                {this.state.error}
              </div>
            ) : null}
            {this.state.success ? (
              <div className="alert alert-urban alert-success" role="alert">
                {this.state.success}
              </div>
            ) : null}
            <div className="row align-items-center">
              <div className="col-lg-3">
                <div className="title">Registration</div>
                <div className="field-input">
                  <label htmlFor="nombre">Nombre</label>
                  <input
                    type="text"
                    className="form-control"
                    name="nombre"
                    onChange={this.handleChange}
                    value={this.state.add.nombre}
                    id="nombre"
                    placeholder="Ingrese nombre"
                  />
                </div>

                <div className="field-input">
                  <label htmlFor="email">Email</label>
                  <input
                    type="email"
                    className="form-control"
                    name="email"
                    onChange={this.handleChange}
                    value={this.state.add.email}
                    id="email"
                    placeholder="Ingrese email"
                  />
                </div>
              </div>
              <div className="d-none d-lg-block col-lg-1 p-0">
                <div className="arrow">
                  <img src={arrow} draggable="false" alt="Arrow" />
                </div>
              </div>
              <div className="col-lg-4">
                <div className="title">General Questions</div>
                <div className="field-selectable">
                  <label htmlFor="genero">Genero</label>
                  <div className="selectable">
                    <input
                      type="radio"
                      name="genero"
                      id="genero"
                      checked={this.state.add.genero === 0}
                      onChange={this.handleChange}
                      value="0"
                    />
                    <label htmlFor="genero">Masculino</label>
                  </div>
                  <div className="selectable">
                    <input
                      type="radio"
                      name="genero"
                      id="genre1"
                      checked={this.state.add.genero === 1}
                      onChange={this.handleChange}
                      value="1"
                    />
                    <label htmlFor="genre1">Femenino</label>
                  </div>
                  <div className="selectable">
                    <input
                      type="radio"
                      name="genero"
                      id="genre2"
                      checked={this.state.add.genero === 2}
                      onChange={this.handleChange}
                      value="2"
                    />
                    <label htmlFor="genre2">Otro / No Responde</label>
                  </div>
                </div>
                <div className="field-select">
                  <label htmlFor="comuna">Comuna</label>
                  <select
                    className="form-control select"
                    value={this.state.add.comuna}
                    name="comuna"
                    onChange={this.handleChange}
                  >
                    <option value="ninguna">Seleccione</option>
                    {options}
                  </select>
                </div>
                <div className="field-select">
                  <label htmlFor="fecha_nacimiento">Año Nacimiento</label>
                  <select
                    name="fecha_nacimiento"
                    id="fecha_nacimiento"
                    className="form-control select"
                    onChange={this.handleChange}
                  >
                    {anios}
                  </select>
                </div>
                <div className="field-select">
                  <label htmlFor="n_hijos">
                    ¿Tiene Hijos menores de 18 años?
                  </label>
                  <select
                    name="n_hijos"
                    id="_hijos"
                    onChange={this.handleChange}
                    className="form-control select"
                  >
                    <option value="0">No</option>
                    <option value="1">Si</option>
                  </select>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="field-selectable">
                  <div className="selectable">
                    <input
                      type="checkbox"
                      name="condition"
                      id="condition"
                      required
                      // checked={}
                      // onChange={this.handleChange}
                      value="0"
                    />
                    <label htmlFor="condition">
                      Sí, acepto los{" "}
                      <a href="/Conditions" target="_blank" className="">
                        términos y condiciones.
                      </a>
                    </label>
                  </div>
                </div>
                <button type="submit" className="btn btn-block btn-warning">
                  Registro
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    api: state.settings.api_url,
    user: state.auth.currentUser,
    type: state.auth.type
  };
}
export default connect(mapStateToProps)(Register);
