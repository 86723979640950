import React, { Component } from "react";
import axios from "axios";
import { connect } from "react-redux";
import urljoin from "url-join";
import { handleChange, RequestError } from "helpers";

class ChangePassword extends Component {
  constructor(props) {
    super(props);
    this.add = this.add.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.getData = this.getData.bind(this);
    this.state = {
      section_name: "Cambiar Contraseña",
      section_name_singular: "Contraseña",
      url_folder: "changepassword",
      error: "",
      success: "",

      add: {
        question: ""
      }
    };
  }
  handleChange(e) {
    handleChange(e, this, "add");
  }

  handleChangeEdit(e) {
    handleChange(e, this, "edit");
  }
  getData() {}
  componentDidMount() {
    this.getData();
  }

  add(e) {
    e.preventDefault();
    this.setState({
      add: {
        ...this.state.add
      }
    });
    axios
      .post(urljoin(this.props.api, "change_password/"), {
        ...this.state.add
      })

      .then(response => {
        if (response.data.status === "success") {
          this.props.dispatch({
            type: "SHOW_SUCCESS",
            success: response.data.message
          });
        }
      })
      .catch(res => {
        this.setState({ error: RequestError(res) });
      });
  }

  componentDidUpdate = (prevProps, prevState) => {
    if (prevProps.location !== this.props.location) {
      this.getData();
    }
  };

  render() {
    return (
      <div className="ChangePassword Degradado-bg">
        <div class="container">
          <form onSubmit={this.add} method="post">
            <h2>Cambiar {this.state.section_name_singular}</h2>
            {this.state.error ? (
              <div className="alert alert-danger" role="alert">
                {this.state.error}
              </div>
            ) : null}
            {this.props.success ? (
              <div className="alert alert-urban alert-success" role="alert">
                {this.props.success}
              </div>
            ) : null}
            <div className="form-group">
              <label htmlFor="question">Contraseña Actual</label>
              <input
                type="password"
                className="form-control"
                name="old_password"
                onChange={this.handleChange}
                value={this.state.add.old_password}
                id="old_password"
                placeholder="Ingrese Contraseña Antigua"
              />
            </div>
            <div className="form-group">
              <label htmlFor="new_password">Nueva Contraseña</label>
              <input
                type="password"
                className="form-control"
                name="new_password"
                onChange={this.handleChange}
                value={this.state.add.new_password}
                id="new_password"
                placeholder="Ingrese Nueva Contraseña"
              />
            </div>
            <div className="form-group">
              <label htmlFor="confirm_password">Repetir Nueva Contraseña</label>
              <input
                type="password"
                className="form-control"
                name="confirm_password"
                onChange={this.handleChange}
                value={this.state.add.confirm_password}
                id="confirm_password"
                placeholder="Repetir Nueva Contraseña"
              />
            </div>

            <button type="submit" className="btn btn-urban">
              Cambiar {this.state.section_name_singular}
            </button>
          </form>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    api: state.settings.api_url,
    success: state.ui.success,
    user_type: state.auth.type
  };
}
export default connect(mapStateToProps)(ChangePassword);
