import React, { Component } from "react";
import { Link, NavLink, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import logo from "./img/logo.svg";

import "./Nav.css";

class Nav extends Component {
  constructor(props) {
    super(props);
    this.cerrarSesion = this.cerrarSesion.bind(this);
  }
  cerrarSesion() {
    this.props.dispatch({ type: "LOGOUT" });
    window.location.href = "/";
  }
  render() {
    let texto_admin = "Administración";
    switch (this.props.type) {
      case "A":
        texto_admin = "(Administrador)";
        break;
      case "C":
        texto_admin = "(Cliente)";
        break;
      case "":
        texto_admin = "(Register)";
        break;

      default:
        texto_admin = "";
        break;
    }

    return (
      <nav className="navbar navbar-expand-lg global-navbar">
        <div className="container">
          <Link to="/" className="navbar-brand">
            <img src={logo} alt="Urban Experiment" height="50" />
          </Link>
          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <i className="fas fa-bars" />
          </button>

          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav mr-auto mt-2 mt-lg-0">
              <li className="nav item">
                <NavLink
                  exact
                  to="/"
                  className="nav-link"
                  activeClassName="active"
                >
                  Inicio
                </NavLink>
              </li>
              <li className="nav item">
                <NavLink
                  exact
                  to="/servicios"
                  className="nav-link"
                  activeClassName="active"
                >
                  Servicios
                </NavLink>
              </li>
              <li className="nav item">
                <NavLink
                  exact
                  to="/estudios"
                  className="nav-link"
                  activeClassName="active"
                >
                  estudios
                </NavLink>
              </li>
              <li className="nav item">
                <NavLink
                  exact
                  to="/contacto"
                  className="nav-link"
                  activeClassName="active"
                >
                  contacto
                </NavLink>
              </li>

              {this.props.user && this.props.type !== "T" ? (
                <li className="nav item d-none d-lg-flex">
                  <NavLink
                    to="/admin/"
                    className="nav-link"
                    activeClassName="active"
                  >
                    Administración
                  </NavLink>
                </li>
              ) : null}

              {this.props.user && this.props.type !== "T" ? (
                <li className="nav-item dropdown d-lg-none">
                  <a
                    className="nav-link dropdown-toggle"
                    href="#"
                    id="navbarDropdown"
                    role="button"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    Administración
                  </a>
                  <div
                    className="dropdown-menu"
                    aria-labelledby="navbarDropdown"
                  >
                    {this.props.type === "A" ? (
                      <React.Fragment>
                        <NavLink className="nav-link" to="/admin/mapsimages">
                          Subir Mapas
                        </NavLink>
                        <NavLink className="nav-link" to="/admin/imagesbanks">
                          Bancos de Imágenes
                        </NavLink>
                        <NavLink
                          className="nav-link"
                          to="/admin/imagesbeforeafter"
                        >
                          Imágenes Antes/Después
                        </NavLink>
                        <NavLink className="nav-link" to="/admin/experiments">
                          Experimentos
                        </NavLink>
                        <NavLink className="nav-link" to="/admin/questions">
                          Preguntas
                        </NavLink>
                        <NavLink className="nav-link" to="/admin/users">
                          Usuarios
                        </NavLink>
                        <NavLink className="nav-link" to="/admin/categories">
                          Categorías
                        </NavLink>
                        <NavLink className="nav-link" to="/admin/estadisticas">
                          Estadisticas
                        </NavLink>
                      </React.Fragment>
                    ) : null}
                    {this.props.type === "C" ? (
                      <React.Fragment>
                        <NavLink className="nav-link" to="/admin/mapsimages">
                          Subir Mapas
                        </NavLink>
                        <NavLink className="nav-link" to="/admin/imagesbanks">
                          Bancos de Imágenes
                        </NavLink>
                        <NavLink
                          className="nav-link"
                          to="/admin/imagesbeforeafter"
                        >
                          Imágenes Antes/Después
                        </NavLink>
                        <NavLink className="nav-link" to="/admin/experiments">
                          Experimentos
                        </NavLink>
                        <NavLink className="nav-link" to="/admin/questions">
                          Preguntas
                        </NavLink>
                        <NavLink className="nav-link" to="/admin/estadisticas">
                          Estadísticas
                        </NavLink>
                      </React.Fragment>
                    ) : null}

                    {this.props.type === "R" ? (
                      <React.Fragment>
                        <NavLink className="nav-link" to="/admin/users">
                          Crear Tester
                        </NavLink>
                        <NavLink className="nav-link" to="/admin/tomarTest">
                          Tomar Test
                        </NavLink>
                      </React.Fragment>
                    ) : null}
                  </div>
                </li>
              ) : null}

              {this.props.user && this.props.type === "T" ? (
                <li className="nav item">
                  <NavLink
                    to="/experimentos/"
                    className="nav-link"
                    activeClassName="active"
                  >
                    Experimentos
                  </NavLink>
                </li>
              ) : null}
            </ul>

            <ul className="navbar-nav">
              {this.props.user ? (
                <li className="nav-item ">
                  <div className="dropdown">
                    <a
                      className="dropdown-toggle"
                      href="#"
                      role="button"
                      id="dropdownMenuLink"
                      data-toggle="dropdown"
                    >
                      {this.props.user_name} {texto_admin}
                    </a>

                    <div className="dropdown-menu">
                      <Link to="/user/changepassword" className="nav-link">
                        Cambiar Contraseña
                      </Link>
                      <span className="nav-link" onClick={this.cerrarSesion}>
                        Cerrar Sesión
                      </span>
                    </div>
                  </div>
                </li>
              ) : (
                <React.Fragment>
                  <li className="nav-item ">
                    <Link to="/login" className="nav-link">
                      Iniciar Sesión
                    </Link>
                  </li>
                  <li className="nav-item ">
                    <Link to="/register" className="btn btn-warning white-text">
                      Registrarse
                    </Link>
                  </li>
                </React.Fragment>
              )}
              <li />
              <li className="nav-item">
                <a href="#google_translate_element" className="nav-link">
                  <i className="fas fa-globe" /> Idioma{" "}
                </a>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    );
  }
}

function mapStateToProps(state) {
  return {
    user: state.auth.id,
    type: state.auth.type,
    user_name: state.auth.name
  };
}
export default withRouter(connect(mapStateToProps)(Nav));
