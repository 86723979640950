import React, { Component } from "react";
import axios from "axios";
import { connect } from "react-redux";
import jwtDecode from "jwt-decode";
import urljoin from "url-join";
import { Link } from "react-router-dom";

import "./Login.css";

class Login extends Component {
  constructor(props) {
    super(props);
    this.iniciarSesion = this.iniciarSesion.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.state = {
      error: "",
      email: "",
      password: ""
    };
    if (this.props.user) {
      if (this.props.type !== "T") {
        window.location.href = "/admin/";
      } else {
        window.location.href = "/experimentos/";
      }
    }
  }
  componentDidUpdate() {
    if (this.props.user) {
      if (this.props.type !== "T") {
        window.location.href = "/admin/";
      } else {
        window.location.href = "/experimentos/";
      }
    }
  }
  handleChange(e) {
    let change = {};
    change[e.target.name] = e.target.value;
    this.setState(change);
  }

  iniciarSesion(e) {
    e.preventDefault();
    this.props.dispatch({ type: "LOGIN_REQUEST" });
    axios
      .post(urljoin(this.props.api, "login/"), {
        email: this.state.email,
        password: this.state.password
      })

      .then(response => {
        if (response.data.status === "success") {
          localStorage.authToken = response.data.token;
          const user = jwtDecode(response.data.token);
          this.props.dispatch({
            type: "LOGIN_SUCCESS",
            user: user,
            token: response.data.token
          });
          if (sessionStorage.getItem("online_test")) {
            const test_id = sessionStorage.getItem("online_test");
            sessionStorage.removeItem("online_test", "");
            window.location.href = "/share/" + test_id;
          }
          if (user.type !== "T") {
            window.location.href = "/admin/";
          }
        } else {
          this.props.dispatch({
            type: "LOGIN_FAILURE",
            errorMessage: response.data.message
          });
          this.setState({ error: response.data.message });
        }
      })
      .catch(res => {
        this.props.dispatch({
          type: "LOGIN_FAILURE",
          errorMessage: res.error
        });
        this.setState({
          error: res.error
        });
      });
  }

  render() {
    return (
      <div className="Client">
        <div className="container">
          <div className="row py-4">
            <div className="col-12 col-lg-4 mt-5">
              <div className="Login FormLogin">
                <div className="FormLogin-container">
                  <form onSubmit={this.iniciarSesion} method="post">
                    {this.state.error ? (
                      <div className="alert alert-danger" role="alert">
                        {this.state.error}
                      </div>
                    ) : null}
                    <div className="form-group">
                      <input
                        type="email"
                        className="form-control"
                        name="email"
                        onChange={this.handleChange}
                        value={this.state.email}
                        id="Email"
                        placeholder="Ingrese email"
                      />
                    </div>
                    <div className="form-group">
                      <input
                        type="password"
                        className="form-control"
                        name="password"
                        onChange={this.handleChange}
                        value={this.state.password}
                        id="password"
                        placeholder="Contraseña"
                      />
                    </div>

                    <button type="submit" className="btn FormLogin-btn">
                      Iniciar Sesión
                    </button>
                    <Link to="/register">
                      <button
                        className="btn FormLogin-btn btn-register mb-4"
                        type="button"
                      >
                        Registro
                      </button>
                    </Link>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    api: state.settings.api_url,
    user: state.auth.currentUser,
    type: state.auth.type
  };
}
export default connect(mapStateToProps)(Login);
