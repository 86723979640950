import React from "react";

export default users => {
  const output = [];
  for (var key in users) {
    output.push(
      <option key={users[key].client_id} value={users[key].client_id}>
        {users[key].nombre_empresa}
      </option>
    );
  }
  return output;
};
