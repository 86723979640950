import React, { Component } from "react";
import Mousetrap from "mousetrap";

import "./HoverButton.css";

class HoverButton extends Component {
  constructor(props) {
    super(props);
    this.mouseEnter = this.mouseEnter.bind(this);
    this.onMouseLeave = this.onMouseLeave.bind(this);
    this.onClick = this.onClick.bind(this);
    let numero = "0";
    if (this.props.scoreType) {
      numero = this.props.number.toString();
      if (numero === "10") {
        numero = "0";
      }
    }

    this.state = {
      timer: 0,
      entered: false,
      number: numero
    };
  }

  onClick() {
    if (this.props.online) {
      this.props.action();
    }
  }
  mouseEnter() {
    this.setState({ entered: true });
    if (this.props.online) {
      return;
    }
    this.setState({
      timer: setTimeout(() => {
        this.props.action();
      }, 750)
    });
  }
  onMouseLeave() {
    this.setState({ entered: false });
    if (this.props.online) {
      return;
    }
    clearTimeout(this.state.timer);
  }
  componentDidMount() {
    if (!this.props.scoreType) {
      Mousetrap.bind("enter", this.props.action);
    } else {
      Mousetrap.bind(this.state.number, this.props.action);
    }
  }
  componentWillUnmount() {
    if (!this.props.scoreType) {
      Mousetrap.unbind("enter");
    } else {
      Mousetrap.unbind(this.state.number);
    }
  }
  render() {
    if (this.props.scoreType) {
      return (
        <li
          onMouseEnter={this.mouseEnter}
          onMouseLeave={this.onMouseLeave}
          onClick={this.onClick}
        >
          {this.props.children}
        </li>
      );
    } else {
      return (
        <div className="hoverButton">
          <button
            onMouseEnter={this.mouseEnter}
            onMouseLeave={this.onMouseLeave}
            onClick={this.onClick}
            className="btn btn-warning continue"
          >
            {this.props.children}
          </button>
        </div>
      );
    }
  }
}

export default HoverButton;
