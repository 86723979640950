import React, { Component } from "react";
import { connect } from "react-redux";
import "./Logout.css";

class Logout extends Component {
  constructor(props) {
    super(props);
    this.props.dispatch({ type: "LOGOUT" });
    this.state = {
      timer: setTimeout(() => {
        window.location.href = "/";
      }, 5000 * 10000000)
    };
  }
  componentWillUnmount() {
    clearTimeout(this.state.timer);
  }

  render() {
    return (
      <div className="Degradado-bg logout">
        {" "}
        <h3>Sesión Cerrada correctamente, enviando al inicio en 5 segundos.</h3>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    user: state.auth.currentUser
  };
}
export default connect(mapStateToProps)(Logout);
