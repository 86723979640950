import React, { Component } from "react";
import axios from "axios";
import { connect } from "react-redux";
import urljoin from "url-join";
import { Link } from "react-router-dom";
import { handleChange, RequestError } from "helpers";
import "./ImagesBanks.css";

function remove(array, element) {
  return array.filter(el => el !== element);
}

class Experiments extends Component {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);

    this.getData = this.getData.bind(this);
    this.filterData = this.filterData.bind(this);
    this.segmentar = this.segmentar.bind(this);
    this.segmentarTodas = this.segmentarTodas.bind(this);
    this.segmentarUna = this.segmentarUna.bind(this);
    this.state = {
      section_name: "segmentador",
      section_name_singular: "segmentador",
      url_folder: "segmentador",
      error: "",
      success: "",
      table: [],
      users: [],
      experiments: [],
      categories: [],
      ya_segmentado: [],
      en_segmentacion: [],
      running_tasks: [],
      totalSegment: 0,
      currentSegment: 0,
      is_segmenting: false,
      available_segmentacion: [],
      form: {
        experiment: "",
        tipo: 1
      }
    };
  }
  segmentar(id) {
    return new Promise((resolve, reject) => {
      if (this.state.ya_segmentado.includes(id)) {
        reject();
      } else {
        this.setState({
          currentSegment: this.state.currentSegment + 1,
          is_segmenting: true
        });

        axios
          .get(
            urljoin(
              this.props.api,
              "segment/",
              this.state.form.tipo == 1 ? "imagemaps" : "beforeafter",
              id.toString()
            )
          )
          .then(response => {
            console.log(response);
            this.setState({
              ya_segmentado: [...this.state.ya_segmentado, id],
              is_segmenting: false
            });
            resolve();
          })
          .catch(res => {
            console.log(res);
            this.setState({ error: RequestError(res) });
            reject(res.message);
          })
          .then(() => {
            this.setState({
              en_segmentacion: remove(this.state.en_segmentacion, id)
            });
          });
      }
    });
  }
  handleChange(e) {
    if (e.target.name === "experiment" || e.target.name === "tipo") {
      this.filterData(e);
    }
    handleChange(e, this, "form");
  }

  filterData(e) {
    let tipo = "";
    let experiment = "";

    if (e.target.name === "tipo") {
      tipo = parseInt(e.target.value, 10);
      experiment = this.state.form.experiment.toString();
    } else {
      tipo = this.state.form.tipo;
      experiment = e.target.value.toString();
    }
    if (experiment === "") {
      return;
    }
    axios
      .get(
        urljoin(
          this.props.api,
          "experiment",
          `${tipo === 0 ? "image_bank" : "image_map"}`,
          experiment
        )
      )
      .then(response => {
        const available_segmentacion = this.state.available_segmentacion;
        response.data.forEach(el => {
          if (!el.is_segmented || this.state.ya_segmentado.includes(el.id)) {
            available_segmentacion.push(el.id);
          }
        });

        this.setState({
          table: response.data,
          ya_segmentado: [],
          en_segmentacion: [],
          available_segmentacion
        });
      })
      .catch(res => {
        alert(res);
      });
  }
  getData() {
    axios
      .get(urljoin(this.props.api, "experiments", "/"))
      .then(response => {
        this.setState({ experiments: response.data });
        response.data.forEach(currentItem => {});
      })
      .catch(res => {
        alert(res);
      });

    axios
      .get(urljoin(this.props.api, "clients"))
      .then(response => {
        this.setState({ users: response.data });
      })
      .catch(res => {
        alert(res);
      });
  }
  componentDidMount() {
    this.getData();
  }

  componentDidUpdate = (prevProps, prevState) => {
    if (prevProps.location !== this.props.location) {
      this.getData();
      this.props.dispatch({
        type: "REMOVE_SUCCESS"
      });
    }
  };
  async segmentarTodas() {
    this.setState({
      totalSegment:
        this.state.totalSegment + this.state.available_segmentacion.length,
      en_segmentacion: this.state.available_segmentacion
    });
    for (
      let index = 0;
      index < this.state.available_segmentacion.length;
      index++
    ) {
      const id = this.state.available_segmentacion[index];

      await this.segmentar(id);
    }
    this.setState({
      available_segmentacion: []
    });
  }
  segmentarUna(e) {
    const id = parseInt(e.currentTarget.dataset.id, 10);
    if (this.state.en_segmentacion.includes(id)) {
      return false;
    }
    this.setState({
      totalSegment: this.state.totalSegment + 1,
      en_segmentacion: [...this.state.en_segmentacion, id],
      available_segmentacion: remove(this.state.available_segmentacion, id)
    });
    Promise.all(this.state.running_tasks).then(() => {
      this.setState({
        running_tasks: [...this.state.running_tasks, this.segmentar(id)]
      });
    });
  }

  render() {
    const options = [];
    this.state.experiments.forEach(element => {
      const { id, name, user_id } = element;
      let usuario = this.state.users.filter(
        elem => elem.client_id === user_id
      )[0];
      options.push(
        <option key={id} value={id}>
          {name} ({usuario ? usuario.nombre_empresa : " "})
        </option>
      );
    });

    const rows = [];

    for (let key in this.state.table) {
      const el = this.state.table[key];
      let nombre = el.original_url.split("/");
      nombre = nombre[nombre.length - 1];

      rows.push(
        <tr key={el.id}>
          <td>{el.id}</td>
          <td>{nombre}</td>
          <td>
            <a href={el.original_url} target="_blank">
              Ver Imagen
            </a>
          </td>
          <td>
            <Link
              to={`/admin/${this.state.url_folder}/edit/${
                this.state.form.tipo == 1 ? "imagemaps" : "beforeafter"
              }/${el.id}`}
            >
              Editar
            </Link>{" "}
            {el.is_segmented ||
            this.state.ya_segmentado.includes(el.id) ? null : (
              <button
                className="btn btn-urban"
                data-id={el.id}
                onClick={this.segmentarUna}
                disabled={this.state.en_segmentacion.includes(el.id)}
              >
                {this.state.en_segmentacion.includes(el.id) ? (
                  <i className="fa fa-spin fa-spinner" />
                ) : (
                  "Segmentar"
                )}
              </button>
            )}
          </td>
        </tr>
      );
    }

    return (
      <div className="ImagesBanks">
        <h2 className="h4">Gestión Segmentador</h2>
        <div className="row">
          <div className="form-group col-12 col-sm-12 col-lg-6 field-select">
            <label htmlFor="experiment">Experimento</label>
            <select
              className="form-control"
              value={this.state.form.experiment}
              name="experiment"
              disabled={this.state.is_segmenting}
              onChange={this.handleChange}
            >
              <option value="-1">Seleccione</option>
              {options}
            </select>
          </div>
          <div className="form-group col-12 col-sm-12 col-lg-6 field-select">
            <label htmlFor="tipo">Tipo</label>
            <select
              className="form-control"
              value={this.state.form.tipo}
              name="tipo"
              onChange={this.handleChange}
            >
              <option value="0">Imágenes Antes/Después</option>
              <option value="1">Mapas</option>
            </select>
          </div>
        </div>
        {rows.length == 0 ? (
          <p className="sindatos">
            Seleccione un Experimento para ver opciones
          </p>
        ) : (
          <div className="urban-lista my-5">
            {this.state.is_segmenting ? (
              <div className="alert alert-urban">
                Segmentando {this.state.currentSegment} de{" "}
                {this.state.totalSegment}
              </div>
            ) : null}
            <h2 className="h6 mb-0">Listado de Imágenes</h2>
            <table className="table">
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Nombre</th>
                  <th>Ver</th>
                  <th>
                    Acciones{" "}
                    <button
                      className="btn btn-urban"
                      onClick={this.segmentarTodas}
                      disabled={this.state.is_segmenting}
                    >
                      {this.state.is_segmenting ? (
                        <i className="fa fa-spin fa-spinner" />
                      ) : (
                        "Segmentar Todas"
                      )}
                    </button>
                  </th>
                </tr>
              </thead>
              <tbody>{rows}</tbody>
            </table>
          </div>
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    api: state.settings.api_url,
    success: state.ui.success,
    user_type: state.auth.type,
    test_route: state.settings.test_route,
    offline: state.settings.offline
  };
}
export default connect(mapStateToProps)(Experiments);
