import React, { Component } from "react";
import axios from "axios";
import { connect } from "react-redux";
import urljoin from "url-join";
import { Link } from "react-router-dom";
import "./PublicTest.css";

class PublicTest extends Component {
  constructor(props) {
    super(props);
    this.registro = this.registro.bind(this);
    this.getData = this.getData.bind(this);
    this.state = {
      add: {}
    };
    if (this.props.user) {
      if (this.props.type !== "T" && this.props.type !== "R") {
        window.location.href = "/";
      }
    }
  }
  componentDidMount() {
    if (this.props.user) {
      axios.defaults.headers.common["Authorization"] = this.props.token;
      this.getData();
    } else {
      this.getData();
      sessionStorage.setItem("online_test", this.props.match.params.id);
    }
  }

  getData() {
    const id = this.props.match.params.id;
    axios
      .get(urljoin(this.props.api, "tests/getid/", id, "/"))
      .then(response => {
        this.setState({
          test_id: response.data.id,
          backgroundImage: response.data.url_background_image,
          description: response.data.description,
          logo: response.data.logo
        });
      })
      .catch(res => {
        alert(res);
      });
  }
  registro(e) {}

  render() {
    if (this.props.user) {
      return (
        <div
          className="PublicTest"
          style={{
            backgroundImage: `url("${this.state.backgroundImage}")`
          }}
        >
          <div class="container">
            <div className="intro">
              <div className="row">
                <div className="col-sm-4 text-center">
                  <img src={this.state.logo} alt="" />
                </div>
                <div className="col-sm-8">
                  <p>{this.state.description}</p>
                </div>
              </div>
            </div>
          </div>
          <Link
            to={"/experimentos/" + this.state.test_id}
            className="btn btn-urban"
          >
            Iniciar Experimento
          </Link>
        </div>
      );
    }
    return (
      <div
        className="PublicTest"
        style={{
          backgroundImage: `url("${this.state.backgroundImage}")`
        }}
      >
        <div class="container">
          <div className="intro">
            <div className="row">
              <div className="col-sm-4 text-center">
                <img src={this.state.logo} alt="" />
              </div>
              <div className="col-sm-8">
                <p>{this.state.description}</p>
              </div>
            </div>
          </div>
        </div>
        <div className="enlaces">
          <Link to="/register" className="btn btn-warning btn-urban">
            Registro
          </Link>
          <Link to="/login" className="btn btn-warning btn-urban">
            Iniciar Sesión
          </Link>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    api: state.settings.api_url,
    user: state.auth.currentUser,
    type: state.auth.type,
    token: state.auth.token
  };
}
export default connect(mapStateToProps)(PublicTest);
