import React, { Component } from "react";
import { Route, Switch } from "react-router"; // react-router v4
import { ChangePassword } from "components/User";
import { Nav, Footer } from "components";
import axios from "axios";
import { connect } from "react-redux";

class User extends Component {
  constructor(props) {
    super(props);
    if (!this.props.user) {
      window.location = "/";
    }
    axios.defaults.headers.common["Authorization"] = this.props.token;
    setTimeout(() => {
      this.props.dispatch({ type: "REMOVE_SUCCESS" });
    }, 10000);
  }
  render() {
    return (
      <React.Fragment>
        <div className="User">
          <Nav />

          <Switch>
            <Route path="/user/changepassword" component={ChangePassword} />
          </Switch>
        </div>
        <Footer />
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    user: state.auth.currentUser,
    type: state.auth.type,
    token: state.auth.token
  };
}
export default connect(mapStateToProps)(User);
