import React, { Component } from "react";
import axios from "axios";
import { connect } from "react-redux";
import urljoin from "url-join";
import { handleChange, RequestError } from "helpers";
class Users extends Component {
  constructor(props) {
    super(props);
    this.addUser = this.addUser.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.getData = this.getData.bind(this);
    this.handleChangeEdit = this.handleChangeEdit.bind(this);
    this.state = {
      error: "",
      success: "",
      nombre: "",
      email: "",
      edit: { nombre: "", color: "", id: "", active: false },
      add: {
        nombre: "",
        email: "",
        tipo: this.props.user_type && !this.props.offline === "A" ? "A" : "T",
        nombre_empresa: "",
        rut_empresa: "",
        tipo_institucion: "publica",
        genero: "0",
        comuna: "",
        fecha_nacimiento: "",
        n_hijos: "0",
        rango_ingresos: "1"
      },
      table: [],
      comunas: []
    };
  }
  handleChange(e) {
    handleChange(e, this, "add");
  }

  handleChangeEdit(e) {
    handleChange(e, this, "edit");
  }
  getData() {
    axios
      .get(urljoin(this.props.api, "comunas", "/"))
      .then(response => {
        this.setState({ comunas: response.data });
      })
      .catch(res => {
        alert(res);
      });
  }

  componentDidMount() {
    this.getData();
  }

  addUser(e) {
    e.preventDefault();
    let data = new FormData();
    for (var key in this.state.add) {
      data.append(key, this.state.add[key]);
    }
    axios
      .post(urljoin(this.props.api, "users", "add", "/"), data)

      .then(response => {
        if (response.data.status === "success") {
          let message = response.data.message;
          if (this.props.user_type === "R") {
            message += " ID Usuario: " + response.data.id_user;
          }
          if (this.props.user_type === "S") {
            message += " Password: " + response.data.password;
          }
          this.props.dispatch({
            type: "SHOW_SUCCESS",
            success: message
          });
          window.location.href = "/admin/users/";
        } else {
          this.setState({
            error: response.data.message
          });
        }
      })
      .catch(res => {
        this.setState({ error: RequestError(res) });
      });
  }

  componentDidUpdate = (prevProps, prevState) => {
    if (prevProps.location !== this.props.location) {
      this.getData();
      this.props.dispatch({
        type: "REMOVE_SUCCESS"
      });
    }
  };

  render() {
    if (this.props.match.params.id) {
      return (
        <form onSubmit={this.editCategory} method="post">
          EDITAR USER
        </form>
      );
    }

    const options = [];
    this.state.comunas.forEach(key => {
      options.push(
        <option key={key} value={key}>
          {key}
        </option>
      );
    });
    return (
      <div className="Users">
        <form onSubmit={this.addUser} method="post">
          <h2 className="h4">Añadir Usuario</h2>
          {this.state.error ? (
            <div className="alert alert-danger" role="alert">
              {this.state.error}
            </div>
          ) : null}
          {this.props.success ? (
            <div className="alert alert-urban alert-success" role="alert">
              {this.props.success}
            </div>
          ) : null}
          <div class="form-row my-3">
            <div className="form-group col-12 col-lg-4 field-input">
              <label htmlFor="nombre">Nombre</label>
              <input
                type="text"
                className="form-control"
                name="nombre"
                onChange={this.handleChange}
                value={this.state.add.nombre}
                id="nombre"
                placeholder="Ingrese nombre"
              />
            </div>
            <div className="form-group col-12 col-lg-4 field-input">
              <label htmlFor="email">Email</label>
              <input
                type="email"
                className="form-control"
                name="email"
                onChange={this.handleChange}
                value={this.state.add.email}
                id="email"
                placeholder="Ingrese email"
              />
            </div>
            <div className="form-group col-12 col-lg-4 field-select">
              <label htmlFor="tipo">Tipo</label>
              <select
                className="form-control"
                value={this.state.add.tipo}
                name="tipo"
                onChange={this.handleChange}
              >
                {this.props.user_type === "A" && !this.props.offline ? (
                  <React.Fragment>
                    <option value="A">Admin</option>
                    <option value="C">Client</option>
                    <option value="R">Register</option>
                    <option value="S">Sync</option>
                  </React.Fragment>
                ) : null}
                <option value="T">Tester</option>
              </select>
            </div>

            {this.state.add.tipo === "C" ? (
              <React.Fragment>
                <div className="form-group col-12 col-lg-4 field-input">
                  <label htmlFor="nombre_empresa">Nombre Empresa</label>
                  <input
                    type="text"
                    className="form-control"
                    name="nombre_empresa"
                    onChange={this.handleChange}
                    value={this.state.add.nombre_empresa}
                    id="nombre_empresa"
                    placeholder="Ingrese Nombre"
                  />
                </div>
                <div className="form-group col-12 col-lg-4 up-images">
                  <label htmlFor="file" className="w-100">
                    Logo Empresa
                    <div className="btn btn-outline mt-2">
                      Seleccionar archivo{" "}
                      <i className="fas fa-upload color-urban ml-3" />
                    </div>
                  </label>
                  <input
                    type="file"
                    className="form-control"
                    name="file"
                    id="file"
                    onChange={this.handleChange}
                  />
                </div>
                <div className="form-group col-12 col-lg-4 field-input">
                  <label htmlFor="rut_empresa">Rut Empresa</label>
                  <input
                    type="text"
                    className="form-control"
                    name="rut_empresa"
                    onChange={this.handleChange}
                    value={this.state.add.rut_empresa}
                    id="rut_empresa"
                    placeholder="Ingrese Rut Empresa"
                  />
                </div>
                <div className="form-group col-12 col-lg-4 field-select">
                  <label htmlFor="tipo_institucion">Tipo Institución</label>
                  <select
                    className="form-control"
                    value={this.state.add.tipo_institucion}
                    name="tipo_institucion"
                    onChange={this.handleChange}
                  >
                    <option value="publica">Publica</option>
                  </select>
                </div>
              </React.Fragment>
            ) : null}
            {this.state.add.tipo === "T" ? (
              <React.Fragment>
                <div className="form-group col-12 col-lg-4 field-select">
                  <label htmlFor="genero">Genero</label>
                  <select
                    className="form-control"
                    value={this.state.add.genero}
                    name="genero"
                    onChange={this.handleChange}
                  >
                    <option value="0">Masculino</option>
                    <option value="1">Femenino</option>
                    <option value="2">Otros</option>
                  </select>
                </div>
                <div className="form-group col-12 col-lg-4 field-select">
                  <label htmlFor="comuna">Comuna</label>
                  <select
                    className="form-control"
                    value={this.state.add.comuna}
                    name="comuna"
                    onChange={this.handleChange}
                  >
                    <option value="ninguna">Seleccione</option>
                    {options}
                  </select>
                </div>
                <div className="form-group col-12 col-lg-4 field-input">
                  <label htmlFor="fecha_nacimiento">Año Nacimiento</label>
                  <input
                    type="date"
                    className="form-control"
                    name="fecha_nacimiento"
                    onChange={this.handleChange}
                    value={this.state.add.fecha_nacimiento}
                    id="fecha_nacimiento"
                    placeholder="Ingrese Fecha Nacimiento"
                  />
                </div>
                <div className="form-group col-12 col-lg-4 field-input">
                  <label htmlFor="n_hijos">Nº de Hijos</label>
                  <input
                    type="numeric"
                    min="0"
                    max="15"
                    className="form-control"
                    name="n_hijos"
                    onChange={this.handleChange}
                    value={this.state.add.n_hijos}
                    id="n_hijos"
                    placeholder="Ingrese Nº de Hijos"
                  />
                </div>
                <div className="form-group col-12 col-lg-4 field-select">
                  <label htmlFor="rango_ingresos">Rango de Ingresos</label>
                  <select
                    className="form-control"
                    value={this.state.add.rango_ingresos}
                    name="rango_ingresos"
                    onChange={this.handleChange}
                  >
                    <option value="0">0-500.000</option>
                    <option value="1">500.001-1.000.000</option>
                    <option value="2">1.000.001-1.500.000</option>
                    <option value="3">1.500.001 o más</option>
                  </select>
                </div>
              </React.Fragment>
            ) : null}
          </div>

          <button type="submit" className="btn btn-urban mb-3">
            Añadir Usuario
          </button>

          {this.props.user_type === "A" ? (
            <div className="my-3">
              <h2>Usuarios Actuales</h2>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={this.props.django_admin + "auth/user/"}
              >
                Ir al Administrador de Usuarios
              </a>
            </div>
          ) : null}
        </form>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    api: state.settings.api_url,
    django_admin: state.settings.django_admin_url,
    success: state.ui.success,
    user_type: state.auth.type,
    offline: state.settings.offline
  };
}
export default connect(mapStateToProps)(Users);
