import React, { Component } from "react";

import ReactCompareImage from "react-compare-image";

import Slider from "react-slick";

import home2 from "./img/home2.png";
import home4 from "./img/home4.png";
import "./Home.css";

class Home extends Component {
  importAll(r) {
    let images = {};
    r.keys().forEach(item => {
      images[item.replace("./", "")] = r(item);
    });
    return images;
  }
  render() {
    var settings = {
      dots: false,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      swipe: false,
      adaptiveHeight: true
    };

    const images = this.importAll(
      require.context("./img/comparsion", false, /\.(png|jpe?g|svg)$/)
    );
    console.log(Object.keys(images));

    return (
      <div className="Home">
        <div className="Home-section" id="section-1">
          <div className="container my-4">
            <Slider {...settings}>
              <ReactCompareImage
                leftImage={images["0.1.jpg"]}
                rightImage={images["0.2.jpg"]}
              />
              <ReactCompareImage leftImage={images['1.1.jpg']} rightImage={images['1.2.jpg']} />
              <ReactCompareImage leftImage={images['2.1.jpg']} rightImage={images['2.2.jpg']} />
              <ReactCompareImage leftImage={images['3.1.jpg']} rightImage={images['3.2.jpg']} />
              <ReactCompareImage leftImage={images['4.1.jpg']} rightImage={images['4.2.jpg']} />
            </Slider>

            <div className="Home-info my-4 py-2 py-lg-4 text-justify" id="info-1">
              <h3 className="text-center mb-4" id="ub-title">Urban Experiment</h3>
              <p>
                Urban Experiment es un servicio que evalúa de manera científica
                el impacto de las inversiones en la percepción de los
                ciudadanos. Para esto usamos una metodología única en el mundo
                que combina simulaciones, Big Data, análisis experimental y
                neurociencias. Nuestra tecnología y metodología nos permite
                descubrir tres cosas: 1) cuales son los elementos que producen
                percepciones positivas o negativas del espacio público, 2) en
                cuanto las soluciones propuestas por inversores, mejoran la
                percepción de los ciudadanos, y 3) cuales son las soluciones más
                eficientes para cada lugar. Nuestra metodología y el uso Big
                Data hace que Urban Experiment sea un servicio más barato, más
                eficiente y que provee información más certera que los métodos
                tradicionales.
              </p>
              <p>
                Con esto mejoramos la eficiencia de la inversión pública y
                llevamos a la participación ciudadana en inversión pública a la
                era digital.
              </p>
            </div>
          </div>
        </div>
        <div className="Home-section" id="section-2">
          <div className="container my-4">
            <div className="row p-0">
              <div className="col-12 col-lg-5 order-2 order-lg-1">
                <div className="d-flex h-100">
                  <div
                    className="Home-info my-4 py-2 py-lg-4 col-12 mt-auto text-justify"
                    id="info-2"
                  >
                    <h3 className="h1 mb-3">Neurociencia</h3>
                    <p>
                      Nuestra metodología se basa en la captura de datos
                      subjetivos y objetivos de la percepción de las personas.
                      Esto nos permite superar un problema que hasta ahora
                      ninguna metodología tradicional puede: ¿están los
                      ciudadanos declarando lo que realmente piensan? A través
                      de dispositivos biométricos podremos ver a través de los
                      ojos de los ciudadanos, entender sus emociones y entregar
                      al inversor un plan certero para tomar las decisiones.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-12 col-lg-4 offset-lg-3 order-1 order-lg-2 d-flex justify-content-center">
                <img
                  className="section-image py-4"
                  src={home2}
                  alt="Neurociencia"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="Home-section d-flex" id="section-3">
          <div className="container d-flex">
            <div
              className="Home-info col-12 col-lg-5 mt-auto text-justify"
              id="info-3"
            >
              <h3 className="h1 mb-4">Urbanismo</h3>
              <p>
                Con el fin de hacer el proceso de inversión más efectivo,
                nuestro equipo urbanístico en conjunto con los inversores
                determina que elementos son los a alterar en el espacio
                público para mejorar la percepción ciudadana de cada
                intervención. Para esto contamos con un equipo consultor
                urbano formado en las mejores universidades del mundo, lates
                como London School of Economics o University of Cambridge, y
                con amplia experiencia internacional, tales como el gobierno
                local de Londres o el Attelier Jean Nouvel. Este equipo
                analiza los datos generados y en base a alternativas testeadas
                ofrece diseño actuales y probados. Con esto los inversores
                ahorran recursos y maximizan el impacto de sus acciones.
              </p>
            </div>
          </div>
        </div>
        <div className="Home-section" id="section-4">
          <div className="container my-4">
            <div className="row p-0">
              <div className="col-12 col-lg-5 order-2 order-lg-1">
                <div className="d-flex h-100">
                  <div
                    className="Home-info my-4 py-2 py-lg-4 col-12 mt-auto text-justify"
                    id="info-4"
                  >
                    <h3 className="h1 mb-4">Participación</h3>
                    <p>
                      Urban Experiment usa la metodología de Participatory Big
                      Data. Esta metodología única en el mundo, recolecta un
                      gran número de datos declarados y biométricos sobre la
                      opinión de los ciudadanos sobre espacios públicos, así
                      como también a través de la eficiencia en su reelección
                      permite que más ciudadanos puedan formar parte de los
                      procesos participativos. Los datos generados por este
                      proceso nos permiten informar a los inversores como cada
                      comunidad y grupo será afectado por las intervenciones
                      proyectadas.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-12 col-lg-4 offset-lg-3 order-1 order-lg-2 d-flex justify-content-center">
                <img
                  className="section-image py-4"
                  src={home4}
                  alt="Participación"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Home;
