import React, { Component } from "react";
import axios from "axios";
import { connect } from "react-redux";
import urljoin from "url-join";
import { Doughnut, defaults } from "react-chartjs-2";

import { handleChange } from "helpers";
import "./Resultados.css";

defaults.global.maintainAspectRatio = false;

class Experiments extends Component {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);

    this.getData = this.getData.bind(this);
    this.updateGraph = this.updateGraph.bind(this);
    this.getStats = this.getStats.bind(this);
    this.state = {
      error: "",
      success: "",
      add: { experiment: 0 },
      users: [],
      experiments: [],
      data: {}
    };
  }
  handleChange(e) {
    handleChange(e, this, "add");
    if (e.target.name === "experiment") {
      this.getStats(e);
    }
  }
  getStats(e) {
    let id = parseInt(e.target.value, 10);

    if (id > 0) {
      axios
        .get(urljoin(this.props.api, "mini_panel_result", id.toString()))
        .then(response => {
          this.setState({ data: response.data });
          this.updateGraph();
        })
        .catch(res => {
          alert(res);
        });
    }
  }
  getData() {
    axios
      .get(urljoin(this.props.api, "experiments", "/"))
      .then(response => {
        this.setState({ experiments: response.data });
      })
      .catch(res => {
        alert(res);
      });

    if (this.props.user_type === "A") {
      axios
        .get(urljoin(this.props.api, "clients"))
        .then(response => {
          this.setState({ users: response.data });
        })
        .catch(res => {
          alert(res);
        });
    }
  }
  updateGraph() {
    console.log(this.state.data);
    this.setState({
      generos: {
        labels: ["Masculino", "Femenino", "Otros / no Responde"],
        datasets: [
          {
            data: [
              this.state.data.total_men,
              this.state.data.total_women,
              this.state.data.total_other
            ],
            backgroundColor: ["#36A2EB", "#FF6384", "#FFCE56"],
            hoverBackgroundColor: ["#36A2EB", "#FF6384", "#FFCE56"]
          }
        ]
      },
      age: {
        labels: ["0-18", "19-30", "31-61", "65+"],
        datasets: [
          {
            data: [
              this.state.data.total_range_age_0_18,
              this.state.data.total_range_age_19_30,
              this.state.data.total_range_age_31_65,
              this.state.data.total_range_age_65
            ],
            backgroundColor: ["#36A2EB", "#FF6384", "#FFCE56", "#795548"],
            hoverBackgroundColor: ["#36A2EB", "#FF6384", "#FFCE56", "#795548"]
          }
        ]
      },
      childrens: {
        labels: ["0", "1", "2", "3", "4", "5", "6"],
        datasets: [
          {
            data: [
              this.state.data.total_children_0,
              this.state.data.total_children_1,
              this.state.data.total_children_2,
              this.state.data.total_children_3,
              this.state.data.total_children_4,
              this.state.data.total_children_5,
              this.state.data.total_children_6
            ],
            backgroundColor: [
              "#f44336",
              "#e91e63",
              "#9c27b0",
              "#673ab7",
              "#3f51b5",
              "#009688",
              "#4caf50"
            ],
            backgroundColor: [
              "#f44336",
              "#e91e63",
              "#9c27b0",
              "#673ab7",
              "#3f51b5",
              "#009688",
              "#4caf50"
            ]
          }
        ]
      },
      ingresos: {
        labels: [
          "0-500.000",
          "500.001-1.000.000",
          "1.000.001-1.500.000",
          "1.500.000+"
        ],
        datasets: [
          {
            data: [
              this.state.data.total_entry_0,
              this.state.data.total_entry_1,
              this.state.data.total_entry_2,
              this.state.data.total_entry_3
            ],
            backgroundColor: ["#36A2EB", "#FF6384", "#FFCE56", "#795548"],
            hoverBackgroundColor: ["#36A2EB", "#FF6384", "#FFCE56", "#795548"]
          }
        ]
      }
    });
  }
  componentDidMount() {
    this.getData();
    console.log(this.state.data);
  }

  render() {
    const options = [];
    for (let key in this.state.experiments) {
      const { id, name, user_id } = this.state.experiments[key];
      let usuario = "";
      if (this.props.user_type === "A") {
        usuario = this.state.users.filter(
          elem => elem.client_id === user_id
        )[0];
      }

      options.push(
        <option key={id} value={id}>
          {name}{" "}
          {this.props.user_type === "A"
            ? "(" + (usuario ? usuario.nombre_empresa : " ") + ")"
            : null}
        </option>
      );
    }
    console.log(Object.keys(this.state.data).length);

    return (
      <div className="Resultados">
        <form method="post">
          <h2 className="h4">Ver Estadisticas Experimentos</h2>
          {this.state.error ? (
            <div className="alert alert-danger" role="alert">
              {this.state.error}
            </div>
          ) : null}
          {this.props.success ? (
            <div className="alert alert-urban alert-success" role="alert">
              {this.props.success}
            </div>
          ) : null}
          
          <div class="form-row my-3">
            <div className="form-group col-12 col-lg-6 field-select">
              <label htmlFor="experiment">Experimento</label>
              <select
                className="form-control"
                value={this.state.add.experiment}
                name="experiment"
                onChange={this.handleChange}
              >
                <option value="-1">Seleccione</option>
                {options}
              </select>
            </div>
          </div>
        </form>
        {Object.keys(this.state.data).length !== 0 ? (
          <div className="Graficos pb-5">
            <h2 className="h4 mb-4">
              Total Respuestas: 
              {this.state.data.total_users}
            </h2>
            <div className="row">
              <div className="col-12 col-md-6 my-2">
                <h2 className="h4">Generos</h2>
                {this.state.generos ? (
                  <div className="canvas-container">
                    <Doughnut data={this.state.generos} options={{ legend: {labels: { fontColor: "white"}}}} />
                  </div>
                ) : null}
              </div>
              <div className="col-12 col-md-6 my-2">
                <h2 className="h4">Edades</h2>
                {this.state.age ? (
                  <div className="canvas-container">
                    <Doughnut data={this.state.age} options={{ legend: {labels: { fontColor: "white"}}}} />
                  </div>
                ) : null}
              </div>
              <div className="col-12 col-md-6 my-2">
                <h2 className="h4">Hijos</h2>
                {this.state.childrens ? (
                  <div className="canvas-container">
                    <Doughnut data={this.state.childrens} options={{ legend: {labels: { fontColor: "white"}}}} />
                  </div>
                ) : null}
              </div>
              <div className="col-12 col-md-6 my-2">
                <h2 className="h4">Ingresos</h2>
                {this.state.ingresos ? (
                  <div className="canvas-container" id="last-canvas">
                    <Doughnut data={this.state.ingresos} options={{ legend: {labels: { fontColor: "white"}}}} />
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        ) : (
          <div className="Graficos">
            {" "}
            <h2 className="h4">Seleccione un experimento</h2>
          </div>
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    api: state.settings.api_url,
    success: state.ui.success,
    user_type: state.auth.type,
    test_route: state.settings.test_route
  };
}
export default connect(mapStateToProps)(Experiments);
