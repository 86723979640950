/* global google */
import axios from "axios";
import urljoin from "url-join";
import EXIF from "exif-js";
import { findEXIFinHEIC, findEXIFinJPEG } from "./exif-reader";

class MapsHandler {
  constructor(api, mapskey) {
    this.mapa = null;
    this.api = api;
    // lista de imágenes cargadas al browser
    this.contador = 0;
    this.contador_imagenes = 0;
    this.contador_poligonos = 0;
    this.contador_puntos = 0;
    this.imagenes_names = []; // Arreglo con los nombres de las imagenes
    this.imagenes = []; // arreglo con las imágenes subidas por el usuario
    this.arrayMarkerImagenes = []; // arreglo con la latitudes y longitudes de las imagenes
    this.arrayUrlsMarkers = []; // arreglo con imagenes de los markers
    this.arrayCoordenadasMarkers = []; // arreglo con la latitudes y longitudes de los markers puestos por el usuario
    this.arrayPolygon = []; // arreglo con la latitudes y longitudes de los vértices de los polígonos puestos por el usuario
    this.drag = { lat: -33.44080817181511, lng: -70.65504902648922 };

    this.initMap = this.initMap.bind(this);
    this.getData = this.getData.bind(this);
    this.loadImage = this.loadImage.bind(this);
    this.loadMultipleImages = this.loadMultipleImages.bind(this);
    this.mapkey = mapskey;
  }
  BinaryToString(binary) {
    var error;

    try {
      return decodeURIComponent(escape(binary));
    } catch (_error) {
      error = _error;
      if (error instanceof URIError) {
        return binary;
      } else {
        throw error;
      }
    }
  }

  ArrayBufferToString(buffer) {
    return this.BinaryToString(
      String.fromCharCode.apply(
        null,
        Array.prototype.slice.apply(new Uint8Array(buffer))
      )
    );
  }

  getData() {
    return {
      contador_imagenes: this.contador_imagenes,
      contador_puntos: this.contador_puntos,
      arrayMarkerImagenes: this.arrayMarkerImagenes,
      arrayUrlsMarkers: this.arrayUrlsMarkers,
      arrayCoordenadasMarkers: this.arrayCoordenadasMarkers,
      imagenes: this.imagenes,
      imagenes_names: this.imagenes_names
    };
  }

  initMap() {
    this.mapa = new google.maps.Map(document.getElementById("map"), {
      zoom: 14,
      center: this.drag
      // disableDefaultUI: true
    });
  }

  /////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////// SUBIR IMÁGENES DESDE CARPETA /////////////////////////////
  /////////////////////////////////////////////////////////////////////////////////////
  //funcion de apoyo
  setAttributes(el, attrs) {
    for (var key in attrs) {
      el.setAttribute(key, attrs[key]);
    }
  }

  //esta función permite cargar las imágenes una por una sin entrar en conflicto
  //debido a la asincronía de javascript con respecto a la función loadImage
  //la estrategia: usar un intervalo de tiempo entre cada invocación de la funcion loadImage
  //si ocurren problemas: implementar alguna solución con filosofía de Promises- no setInterval
  //carga múltiples imágenes del usuario haciendo llamadas a loadImage
  async loadMultipleImages() {
    var files = document.getElementById("file").files;
    for (let index = 0; index < files.length; index++) {
      await this.loadImage(index);
    }
  }
  toDecimal(number) {
    return (
      number[0].numerator +
      number[1].numerator / (60 * number[1].denominator) +
      number[2].numerator / (3600 * number[2].denominator)
    );
  }

  procesarImagen(file, tags, filetype, customCords = false, filename) {
    const c = this.contador;
    const c_img = this.contador_imagenes;
    if (!customCords) {
      const latitud = tags.GPSLatitude;
      const longitud = tags.GPSLongitude;
      let ladoLatitud = 1;
      let ladoLongitud = 1;
      if (tags.GPSLatitudeRef == "S") {
        ladoLatitud = -1;
      }
      if (tags.GPSLongitudeRef == "W") {
        ladoLongitud = -1;
      }

      var latitudDD = this.toDecimal(latitud) * ladoLatitud;
      var longitudDD = this.toDecimal(longitud) * ladoLongitud;
    } else {
      var latitudDD = tags.GPSLatitude;
      var longitudDD = tags.GPSLongitude;
    }
    var center = { lat: latitudDD, lng: longitudDD };
    this.drag = { lat: latitudDD - 0.0005, lng: longitudDD + 0.001 };
    this.mapa.setCenter(center);
    var marker = new google.maps.Marker({
      position: center,
      map: this.mapa,
      label: c.toString(),
      draggable: true
    });
    google.maps.event.addListener(marker, "rightclick", event => {
      marker.setMap(null);
      delete this.arrayMarkerImagenes[c_img];
      delete this.imagenes[c];
      delete this.imagenes_names[c];
      var element = document.getElementById(c);
      element.parentNode.removeChild(element);
      return;
    });
    google.maps.event.addListener(marker, "dragend", async () => {
      this.arrayMarkerImagenes[c_img] = [
        marker.getPosition().lat(),
        marker.getPosition().lng()
      ];
    });
    var element = document.createElement("div");
    element.style.marginTop = "5px";
    element.style.textAlign = "center";
    element.setAttribute("id", "imagen-" + c);
    var text = document.createElement("div");
    text.classList.add("image-number");

    text.appendChild(document.createTextNode(c.toString().concat(" ")));
    var image = document.createElement("img");
    this.setAttributes(image, { width: "70%", height: "140px" });

    var arrayBufferView = new Uint8Array(file);
    var blob = new Blob([arrayBufferView], { type: filetype });
    var urlCreator = window.URL || window.webkitURL;
    var imageUrl = urlCreator.createObjectURL(blob);

    image.src = imageUrl;
    var boton = document.createElement("button");
    boton.innerHTML = `<i class="fas fa-times"></i>`;
    boton.style.margin = "0 auto";
    boton.type = "button";
    //al hacer click la imagen se agranda
    image.onclick = () => {
      this.setAttributes(image, { width: "100%", height: "auto" });
    };
    //al hacer doble click la imagen se minimiza
    image.ondblclick = () => {
      this.setAttributes(image, { width: "70%", height: "140px" });
    };
    //boton que alimina la imagen del usuario y el marker
    boton.onclick = () => {
      marker.setMap(null);
      delete this.arrayMarkerImagenes[c_img];
      delete this.imagenes[c];
      delete this.imagenes_names[c];
      var element = document.getElementById("imagen-" + c);
      element.parentNode.removeChild(element);
      return;
    };
    element.appendChild(text);
    element.appendChild(image);
    element.appendChild(boton);
    document.getElementById("places").append(element);
    //
    this.imagenes.push(blob);
    this.imagenes_names.push(filename);
    this.arrayMarkerImagenes.push([
      marker.getPosition().lat(),
      marker.getPosition().lng()
    ]);
    this.contador += 1;
    this.contador_imagenes += 1;
  }

  //carga una imagen, crea un marker en la geolocalización de la imágen
  //y permite visualizarla en la pantalla negra

  loadImage(index) {
    return new Promise((resolve, reject) => {
      // Llamamos a resolve(...) cuando lo que estabamos haciendo finaliza con éxito, y reject(...) cuando falla.
      // En este ejemplo, usamos setTimeout(...) para simular código asíncrono.
      // En la vida real, probablemente uses algo como XHR o una API HTML5.

      //recibe la imágen
      const file = document.getElementById("file").files[index];
      const extension = file.name
        .split(".")
        .pop()
        .toLowerCase();

      if (["jpg", "jpeg", "png"].includes(extension)) {
        const reader = new FileReader();
        let tags = [];
        reader.onload = e => {
          if (extension == "heic") {
            tags = findEXIFinHEIC(reader.result);
          } else if (["jpeg", "jpg"].includes(extension)) {
            tags = findEXIFinJPEG(reader.result);
          }
          if (
            typeof tags.GPSLongitude !== "undefined" &&
            typeof tags.GPSLatitude !== "undefined"
          ) {
            this.procesarImagen(
              reader.result,
              tags,
              file.type,
              false,
              file.name
            );
          } else {
            const center = this.mapa.getCenter();
            const lat = center.lat();
            const long = center.lng();

            console.log(lat, long);
            this.procesarImagen(
              reader.result,
              { GPSLongitude: long, GPSLatitude: lat },
              file.type,
              true,
              file.name
            );
            console.log(tags);
          }
          resolve("¡Éxito!"); // ¡Todo salió bien!
        };
        reader.readAsArrayBuffer(file);
      } else if (extension == "heic") {
        alert(
          "El formato heic no es soportado, te recomendamos la aplicación Luma: Convert Heic 2 Jpg o https://heictojpg.com/"
        );
        resolve("¡Éxito!"); // ¡Todo salió bien!
      } else {
        alert(`Error al importar ${file.name}, el formato no es valido`);
        resolve("¡Éxito!"); // ¡Todo salió bien!
      }
    });
  }
  /////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////// COLOCAR PUNTOS DE INTERES ////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////////////
  //hay que modificar la forma en que se le pasa la key.
  //retorna la url de la imagen de streetview del marker
  //para conocer opciones disponibles (fov,pitch,heading,etc) revise documentacion de google api maps

  getUrl(marker) {
    console.log("Call");
    return new Promise((resolve, reject) => {
      // Obtener coordenadas centro imagen
      var panorama = new google.maps.StreetViewPanorama(
        document.getElementById("test"),
        {
          position: {
            lat: marker.getPosition().lat(),
            lng: marker.getPosition().lng()
          },
          pov: {
            pitch: 0,
            heading: 0
          },
          visible: true,
          linksControl: false,
          panControl: false,
          enableCloseButton: false,
          zoomControl: false,
          addressControl: false,
          fullscreenControl: false,
          motionTrackingControl: false
        }
      );
      let tiempo = 0;
      const interval = setInterval(() => {
        tiempo += 40;
        if (panorama.getPhotographerPov() !== undefined) {
          clearInterval(interval);
          var string = `https://maps.googleapis.com/maps/api/streetview?size=640x360&location=`
            .concat(
              marker
                .getPosition()
                .lat()
                .toString()
            )
            .concat(",")
            .concat(
              marker
                .getPosition()
                .lng()
                .toString()
            )
            .concat("&fov=90")
            .concat("&heading=".concat(panorama.getPhotographerPov().heading))
            .concat(`&pitch=0`)
            .concat(`&key=${this.mapkey}`);
          panorama.setPov({
            heading: panorama.getPhotographerPov().heading,
            pitch: 0
          });
          return resolve(string);
        }
        if (tiempo > 4000) {
          reject("time_passed");
        }
      }, 40);
    });
  }
  async getUrlStreet(marker, heading = -10) {
    console.log(heading);
    if (heading > 0) {
      var string = `https://maps.googleapis.com/maps/api/streetview?size=640x360&location=`
        .concat(
          marker
            .getPosition()
            .lat()
            .toString()
        )
        .concat(",")
        .concat(
          marker
            .getPosition()
            .lng()
            .toString()
        )
        .concat("&fov=90")
        .concat("&heading=")
        .concat(heading)
        .concat(`&pitch=0`)
        .concat(`&key=${this.mapkey}`);
      return string;
    } else {
      return this.getUrl(marker);
    }
  }
  //agrega un marker y su imagen de streetview en la pantalla negra
  async addPoint(posicion = null) {
    if (posicion == null) {
      const center = this.mapa.getCenter();
      const lat = center.lat();
      const lng = center.lng();
      posicion = { lat, lng };
    }
    var c = this.contador;
    var c_ptos = this.contador_puntos;
    //crea el marker
    var marker = new google.maps.Marker({
      position: posicion,
      map: this.mapa,
      draggable: true,
      label: c.toString()
    });
    //elimina el marker y la imagen de streetview con right click
    google.maps.event.addListener(marker, "rightclick", event => {
      marker.setMap(null);
      delete this.arrayCoordenadasMarkers[c_ptos];
      delete this.arrayUrlsMarkers[c_ptos];
      var element = document.getElementById(c);
      element.parentNode.removeChild(element);
      return;
    });
    //crea la imagen de street view que se presenta en la pantalla negra
    var element = document.createElement("div");
    element.style.marginTop = "5px";
    element.style.textAlign = "center";
    element.setAttribute("id", "imagen-" + c);
    var text = document.createElement("div");
    text.classList.add("image-number");

    text.appendChild(document.createTextNode(c.toString().concat(" ")));
    var image = document.createElement("img");
    this.setAttributes(image, { width: "70%", height: "140px" });
    await this.getUrlStreet(marker)
      .then(response => {
        image.src = response;
      })
      .catch(() => {
        marker.setMap(null);
        delete this.arrayCoordenadasMarkers[c_ptos];
        delete this.arrayUrlsMarkers[c_ptos];
      });
    var boton = document.createElement("button");
    boton.innerHTML = `<i class="fas fa-times"></i>`;
    boton.style.margin = "0 auto";
    var bigSize = false;
    //agranda la imagen de street view
    image.onclick = () => {
      bigSize = true;
      this.setAttributes(image, { width: "100%", height: "auto" });
    };
    //achica la imagen de streetview
    image.ondblclick = () => {
      bigSize = false;
      this.setAttributes(image, { width: "70%", height: "140px" });
    };

    var grado = 120;
    var lastX = 0;
    //permite el movimiento de la imagen de streetview cuando se agranda
    image.onmousemove = async event => {
      if (bigSize == true) {
        grado += 5 * event.movementX;
        if (grado < 0) {
          grado = 360;
        }
        if (grado > 360) {
          grado = 0;
        }
        await this.getUrlStreet(marker, grado)
          .then(response => {
            image.src = response;
            this.arrayUrlsMarkers[c_ptos] = response;
          })
          .catch(() => {
            marker.setMap(null);
            delete this.arrayCoordenadasMarkers[c_ptos];
            delete this.arrayUrlsMarkers[c_ptos];
            var element = document.getElementById(c);
            element.parentNode.removeChild(element);
          });
      }
    };
    //elimina imagen de streetview y su marker
    boton.onclick = () => {
      marker.setMap(null);
      delete this.arrayCoordenadasMarkers[c_ptos];
      delete this.arrayUrlsMarkers[c_ptos];
      var element = document.getElementById(c);
      element.parentNode.removeChild(element);
      return;
    };
    //se cargan los elementos
    element.appendChild(text);
    element.appendChild(image);
    element.appendChild(boton);
    document.getElementById("places").append(element);
    //se guardan los valores relevantes en arreglos
    await this.getUrlStreet(marker)
      .then(response => {
        this.arrayUrlsMarkers.push(response);
      })
      .catch(() => {
        marker.setMap(null);
        delete this.arrayCoordenadasMarkers[c_ptos];
        delete this.arrayUrlsMarkers[c_ptos];
        var element = document.getElementById(c);
        element.parentNode.removeChild(element);
      });
    this.arrayCoordenadasMarkers.push([
      marker.getPosition().lat(),
      marker.getPosition().lng()
    ]);
    this.contador += 1;
    this.contador_puntos += 1;
    //se actualizan los valores si el pin del marker es desplazado
    google.maps.event.addListener(marker, "dragend", async () => {
      this.arrayCoordenadasMarkers[c_ptos] = [
        marker.getPosition().lat(),
        marker.getPosition().lng()
      ];
      await this.getUrlStreet(marker)
        .then(response => {
          image.src = response;
          this.arrayUrlsMarkers[c_ptos] = response;
        })
        .catch(() => {
          marker.setMap(null);
          delete this.arrayCoordenadasMarkers[c_ptos];
          delete this.arrayUrlsMarkers[c_ptos];
          var element = document.getElementById(c);
          element.parentNode.removeChild(element);
        });
    });
  }
  /////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////// CREAR POLIGONO      //////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////////////
  //indica true si el punto está dentro del polígono. false si no.
  IsPointInPolygon(vs, point) {
    // basado en:
    // http://www.ecse.rpi.edu/Homepages/wrf/Research/Short_Notes/pnpoly.html
    var x = point[0],
      y = point[1];
    var inside = false;
    for (var i = 0, j = vs.length - 1; i < vs.length; j = i++) {
      var xi = vs[i][0],
        yi = vs[i][1];
      var xj = vs[j][0],
        yj = vs[j][1];
      var intersect =
        yi > y != yj > y && x < ((xj - xi) * (y - yi)) / (yj - yi) + xi;
      if (intersect) inside = !inside;
    }
    return inside;
  }
  //retorna los valores minimos y máximos de los márgenes del arreglo=polígono
  limitesPoligono(arreglo, string) {
    if (string == "este") {
      var longitudes = [];
      for (var i in arreglo) {
        longitudes.push(parseFloat(arreglo[i][0]));
      }
      return Math.max.apply(Math, longitudes);
    }
    if (string == "oeste") {
      var longitudes = [];
      for (var i in arreglo) {
        longitudes.push(parseFloat(arreglo[i][0]));
      }
      return Math.min.apply(Math, longitudes);
    }
    if (string == "norte") {
      var latitudes = [];
      for (var i in arreglo) {
        latitudes.push(parseFloat(arreglo[i][1]));
      }
      return Math.max.apply(Math, latitudes);
    }
    if (string == "sur") {
      var latitudes = [];
      for (var i in arreglo) {
        latitudes.push(parseFloat(arreglo[i][1]));
      }
      return Math.min.apply(Math, latitudes);
    }
  }
  //se agregan markers aleatoriamente dentro del polígono
  async addMarkersPolygon(arreglo, poligono) {
    var cantidad_puntos = 0;
    var x_max = this.limitesPoligono(arreglo, "este");
    var x_min = this.limitesPoligono(arreglo, "oeste");
    var y_max = this.limitesPoligono(arreglo, "norte");
    var y_min = this.limitesPoligono(arreglo, "sur");
    var n =
      (google.maps.geometry.spherical.computeArea(poligono.getPath()) * 11) /
      500000;
    console.log(
      google.maps.geometry.spherical.computeArea(poligono.getPath()) / 10
    );
    while (cantidad_puntos < n) {
      var lng = y_min + Math.random() * (y_max - y_min);
      var lat = x_min + Math.random() * (x_max - x_min);
      var point = [lat, lng];
      if (this.IsPointInPolygon(arreglo, point)) {
        this.puntoNuevo = { lat: point[0], lng: point[1] };
        cantidad_puntos += 1;
        await this.addPoint(this.puntoNuevo);
      }
    }
  }
  //convierte un polígono en un arreglo de coordenadas de sus vértices
  polygonToArr(arr) {
    var arreglo = [];
    for (var i = 0; i < arr.length; i++) {
      this.lat = arr[i].lat();
      this.lng = arr[i].lng();
      arreglo.push([this.lat, this.lng]);
    }
    return arreglo;
  }
  //permite crear un polígono que irá siendo dibujado por el usuario
  addPolygon() {
    var c = this.contador_poligonos;
    var drawingManager = new google.maps.drawing.DrawingManager({
      drawingControl: false,
      polygonOptions: {
        strokeColor: "#958989",
        fillColor: "#c2b1b1",
        fillOpacity: 0.5,
        strokeWeight: 2,
        clickable: false,
        editable: true,
        zIndex: 1
      }
    });
    drawingManager.setMap(this.mapa);
    drawingManager.setDrawingMode(google.maps.drawing.OverlayType.POLYGON);
    //cuando se dibuja el polígono por completo, se agregan los markers
    //y se guardan valores relevantes
    google.maps.event.addListener(drawingManager, "overlaycomplete", event => {
      this.arrayPolygon.push(
        this.polygonToArr(event.overlay.getPath().getArray())
      );
      drawingManager.setDrawingMode(null);
      this.addMarkersPolygon(this.arrayPolygon[c], event.overlay);
      //elimina el poligono con right click
      google.maps.event.addListener(
        event.overlay,
        "rightclick",
        (index, obj) => {
          delete this.arrayPolygon[c];
          event.overlay.setMap(null);
        }
      );
      //actualiza las coordenadas cuando se modifica un vértice
      google.maps.event.addListener(
        event.overlay.getPath(),
        "set_at",
        (index, obj) => {
          this.arrayPolygon[c] = this.polygonToArr(
            event.overlay.getPath().getArray()
          );
        }
      );
      //actualiza coordenadas cuando se inserta un vértice
      google.maps.event.addListener(
        event.overlay.getPath(),
        "insert_at",
        (index, obj) => {
          this.arrayPolygon[c] = this.polygonToArr(
            event.overlay.getPath().getArray()
          );
        }
      );
    });
    this.contador_poligonos += 1;
  }
}

export default MapsHandler;
