import React, { Component } from "react";
import axios from "axios";
import { connect } from "react-redux";
import urljoin from "url-join";
import { Link } from "react-router-dom";
import { ClientsToOptions, handleChange, RequestError } from "helpers";
class Experiments extends Component {
  constructor(props) {
    super(props);
    this.add = this.add.bind(this);
    this.handleFocus = this.handleFocus.bind(this);
    this.edit = this.edit.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleChangeMultiple = this.handleChangeMultiple.bind(this);
    this.handleChangeMultipleEdit = this.handleChangeMultipleEdit.bind(this);

    this.getData = this.getData.bind(this);
    this.handleChangeEdit = this.handleChangeEdit.bind(this);
    this.state = {
      section_name: "Experimentos",
      section_name_singular: "Experimento",
      url_folder: "experiments",
      error: "",
      success: "",
      edit: {
        name: "",
        active: false,
        is_public: false,
        authorized: false,
        imagebanks_quantity: 5,
        imagemaps_quantity: 20,
        description: ""
      },
      add: {
        name: "",
        active: false,
        is_public: false,
        authorized: false,
        questions: [],
        imagebanks_quantity: 5,
        imagemaps_quantity: 20,
        description: ""
      },
      table: [],
      users: {},
      questions: []
    };
  }
  handleChange(e) {
    handleChange(e, this, "add");
  }

  handleChangeEdit(e) {
    handleChange(e, this, "edit");
  }
  handleChangeMultiple(e) {
    let change = {};
    var options = e.target.options;
    var value = [];
    for (var i = 0, l = options.length; i < l; i++) {
      if (options[i].selected) {
        value.push(options[i].value);
      }
    }
    change[e.target.name] = value;
    this.setState({ add: { ...this.state.add, ...change } });
  }
  handleChangeMultipleEdit(e) {
    let change = {};
    var options = e.target.options;
    var value = [];
    for (var i = 0, l = options.length; i < l; i++) {
      if (options[i].selected) {
        value.push(options[i].value);
      }
    }
    change[e.target.name] = value;
    this.setState({ edit: { ...this.state.edit, ...change } });
  }
  getData() {
    const id = this.props.match.params.id;

    axios
      .get(urljoin(this.props.api, "questions", "/"))
      .then(response => {
        this.setState({ questions: response.data });
      })
      .catch(res => {
        alert(res);
      });

    if (this.props.user_type === "A") {
      axios
        .get(urljoin(this.props.api, "clients"))
        .then(response => {
          this.setState({ users: response.data });
        })
        .catch(res => {
          alert(res);
        });
    }

    if (id) {
      axios
        .get(urljoin(this.props.api, this.state.url_folder, id))
        .then(response => {
          console.log(response.data);
          this.setState({
            edit: {
              ...response.data,
              client_id: response.data.user_id,
              id: parseInt(id, 10)
            }
          });
        })
        .catch(res => {
          alert(res);
        });
    } else {
      axios
        .get(urljoin(this.props.api, this.state.url_folder, "/"))
        .then(response => {
          this.setState({ table: response.data });
        })
        .catch(res => {
          alert(res);
        });
    }
  }
  componentDidMount() {
    this.getData();
  }

  add(e) {
    e.preventDefault();
    let data = new FormData();
    for (var key in this.state.add) {
      data.append(key, this.state.add[key]);
    }
    axios
      .post(urljoin(this.props.api, this.state.url_folder, "add", "/"), data)

      .then(response => {
        if (response.data.status === "success") {
          this.props.dispatch({
            type: "SHOW_SUCCESS",
            success: response.data.message
          });
          window.location.href = urljoin("/admin/", this.state.url_folder, "/");
        } else {
          this.setState({ error: response.data.message });
        }
      })
      .catch(res => {
        this.setState({ error: RequestError(res) });
      });
  }
  edit(e) {
    e.preventDefault();
    let data = new FormData();
    for (var key in this.state.edit) {
      data.append(key, this.state.edit[key]);
    }
    axios
      .put(
        urljoin(this.props.api, "experiment/", this.state.edit.id.toString()),
        data
      )

      .then(response => {
        this.props.dispatch({
          type: "SHOW_SUCCESS",
          success: "Experimento editado correctamente"
        });
        window.location.href = urljoin("/admin/", this.state.url_folder, "/");
      })
      .catch(res => {
        this.setState({ error: RequestError(res) });
      });
  }

  componentDidUpdate = (prevProps, prevState) => {
    if (prevProps.location !== this.props.location) {
      this.getData();
      this.props.dispatch({
        type: "REMOVE_SUCCESS"
      });
    }
  };
  handleFocus(event) {
    event.target.select();
  }

  render() {
    const users = ClientsToOptions(this.state.users);

    let key = 0;
    const preguntas = [];
    this.state.questions.forEach(row => {
      const { id, question } = row;

      preguntas.push(
        <option key={id} value={id}>
          {" "}
          {question}{" "}
        </option>
      );
    });

    if (this.props.match.params.id) {
      return (
        <form onSubmit={this.edit} method="post">
          {this.state.error ? (
            <div className="alert alert-danger" role="alert">
              {this.state.error}
            </div>
          ) : null}

          <h2 className="h4">
            Editar {this.state.section_name_singular} {this.state.edit.id}
          </h2>

          <div className="form-row my-3">
            <div className="form-group col-12 field-input">
              <label htmlFor="name">Nombre</label>
              <input
                type="text"
                className="form-control"
                name="name"
                onChange={this.handleChangeEdit}
                value={this.state.edit.name}
                id="name"
                placeholder="Ingrese Nombre"
              />
            </div>
            <div className="form-group col-12">
              <label htmlFor="active" className="ckb">
                Activo
                <input
                  type="checkbox"
                  className="form-control"
                  name="active"
                  onChange={this.handleChangeEdit}
                  value={this.state.edit.active}
                  checked={this.state.edit.active}
                  id="active"
                />
                <i className="fas" />
              </label>
            </div>
            <div className="form-group col-12 field-input">
              <label htmlFor="description">Descripción</label>
              <textarea
                className="form-control"
                name="description"
                onChange={this.handleChangeEdit}
                value={this.state.edit.description}
                id="description"
              />
            </div>
            <div className="form-group col-12 field-input">
              <label htmlFor="imagebanks_quantity">Imagebanks_quantity</label>
              <input
                type="numeric"
                className="form-control"
                name="imagebanks_quantity"
                onChange={this.handleChangeEdit}
                value={this.state.edit.imagebanks_quantity}
                min="0"
                max="25"
                id="imagebanks_quantity"
              />
            </div>

            <div className="d-none d-lg-flex w-100" />

            <div className="form-group col-12 field-input">
              <label htmlFor="imagemaps_quantity">Imagemaps_quantity</label>
              <input
                type="numeric"
                className="form-control"
                name="imagemaps_quantity"
                onChange={this.handleChangeEdit}
                value={this.state.edit.imagemaps_quantity}
                min="0"
                max="25"
                id="imagemaps_quantity"
              />
            </div>
            <div className="form-group col-12">
              <label htmlFor="is_public" className="ckb">
                Is_publico
                <input
                  type="checkbox"
                  className="form-control"
                  name="is_public"
                  onChange={this.handleChangeEdit}
                  value={this.state.edit.is_public}
                  checked={this.state.edit.is_public}
                  id="is_public"
                />
                <i className="fas" />
              </label>
            </div>
            <div className="form-group col-12 up-images">
              <label htmlFor="file" className="w-100">
                Imagen Fondo Experimento
                <div className="btn btn-outline mt-2">
                  Seleccionar archivo{" "}
                  <i className="fas fa-upload color-urban ml-3" />
                </div>
              </label>
              <input
                type="file"
                className="form-control"
                name="file"
                id="file"
                onChange={this.handleChangeEdit}
              />
            </div>

            {this.props.user_type === "A" ? (
              <React.Fragment>
                <div className="form-group col-12">
                  <label htmlFor="authorized" className="ckb">
                    Autorizado
                    <input
                      type="checkbox"
                      className="form-control"
                      name="authorized"
                      onChange={this.handleChangeEdit}
                      value={this.state.edit.authorized}
                      checked={this.state.edit.authorized}
                      id="authorized"
                    />
                    <i className="fas" />
                  </label>
                </div>
                <div className="form-group col-12 field-select">
                  <label htmlFor="client_id">Usuario</label>
                  <select
                    className="form-control"
                    value={this.state.edit.client_id}
                    name="client_id"
                    onChange={this.handleChangeEdit}
                  >
                    <option value="-1">Seleccione...</option>
                    {users}
                  </select>
                </div>
              </React.Fragment>
            ) : null}

            <div className="d-none d-lg-flex w-100" />

            <div className="form-group col-12">
              <label htmlFor="questions">Preguntas</label>
              <select
                multiple={true}
                className="form-control"
                value={this.state.edit.questions}
                name="questions"
                onChange={this.handleChangeMultipleEdit}
              >
                {preguntas}
              </select>
            </div>
          </div>

          <button type="submit" className="btn btn-urban mb-4">
            Editar {this.state.section_name_singular}
          </button>
        </form>
      );
    }

    const rows = [];

    for (key in this.state.table) {
      const el = this.state.table[key];

      rows.push(
        <tr key={el.id}>
          <td>{el.id}</td>
          <td>{el.name}</td>
          <td>
            <i
              className={`fas fa-${
                !el.is_public ? "times-circle" : "check-circle"
              }`}
            />
          </td>
          <td>
            <i
              className={`fas fa-${
                el.active ? "times-circle" : "check-circle"
              }`}
            />
          </td>
          <td>
            <i
              className={`fas fa-${
                !el.authorized ? "times-circle" : "check-circle"
              }`}
            />
          </td>
          <td>
            <input
              readOnly
              type="text"
              value={this.props.test_route + el.online_code}
              onFocus={this.handleFocus}
            />{" "}
          </td>
          <td>
            <Link to={`/admin/${this.state.url_folder}/${el.id}/edit`}>
              Editar
            </Link>
          </td>
        </tr>
      );
    }

    return (
      <div className="Experiments">
        <form onSubmit={this.add} method="post">
          <h2 className="h4">Añadir {this.state.section_name_singular}</h2>
          {this.state.error ? (
            <div className="alert alert-danger" role="alert">
              {this.state.error}
            </div>
          ) : null}
          {this.props.success ? (
            <div className="alert alert-urban alert-success" role="alert">
              {this.props.success}
            </div>
          ) : null}

          <div className="form-row my-3">
            <div className="form-group col-12 field-input">
              <label htmlFor="name">Nombre</label>
              <input
                type="text"
                className="form-control"
                name="name"
                onChange={this.handleChange}
                value={this.state.add.name}
                id="name"
                placeholder="Ingrese Nombre"
              />
            </div>

            <div className="form-group col-12">
              <label htmlFor="active" className="ckb">
                Activo
                <input
                  type="checkbox"
                  className="form-control"
                  name="active"
                  onChange={this.handleChange}
                  value={this.state.add.active}
                  checked={this.state.add.active}
                  id="active"
                />
                <i className="fas" />
              </label>
            </div>
            <div className="form-group col-12 field-input">
              <label htmlFor="description">Descripción</label>
              <textarea
                type="TEXT"
                className="form-control"
                name="description"
                onChange={this.handleChange}
                value={this.state.add.description}
                id="description"
              />
            </div>
            <div className="form-group col-12 field-input">
              <label htmlFor="imagebanks_quantity">Imagebanks_quantity</label>
              <input
                type="numeric"
                className="form-control"
                name="imagebanks_quantity"
                onChange={this.handleChange}
                value={this.state.add.imagebanks_quantity}
                min="0"
                max="25"
                id="imagebanks_quantity"
              />
            </div>

            <div className="d-none d-lg-flex w-100" />

            <div className="form-group col-12 field-input">
              <label htmlFor="imagemaps_quantity">Imagemaps_quantity</label>
              <input
                type="numeric"
                className="form-control"
                name="imagemaps_quantity"
                onChange={this.handleChange}
                value={this.state.add.imagemaps_quantity}
                min="0"
                max="25"
                id="imagemaps_quantity"
              />
            </div>

            <div className="form-group col-12">
              <label htmlFor="is_public" className="ckb">
                Is_publico
                <input
                  type="checkbox"
                  className="form-control"
                  name="is_public"
                  onChange={this.handleChange}
                  value={this.state.add.is_public}
                  checked={this.state.add.is_public}
                  id="is_public"
                />
                <i className="fas" />
              </label>
            </div>
            <div className="form-group col-12 up-images">
              <label htmlFor="file" className="w-100">
                Imagen Fondo Experimento
                <div className="btn btn-outline mt-2">
                  Seleccionar archivo{" "}
                  <i className="fas fa-upload color-urban ml-3" />
                </div>
              </label>
              <input
                type="file"
                className="form-control"
                name="file"
                id="file"
                onChange={this.handleChange}
              />
            </div>

            {this.props.user_type === "A" ? (
              <React.Fragment>
                <div className="form-group col-12">
                  <label htmlFor="authorized" className="ckb">
                    Autorizado
                    <input
                      type="checkbox"
                      className="form-control"
                      name="authorized"
                      onChange={this.handleChange}
                      value={this.state.add.authorized}
                      checked={this.state.add.authorized}
                      id="authorized"
                    />
                    <i className="fas" />
                  </label>
                </div>

                <div className="form-group col-12 field-select">
                  <label htmlFor="client_id">Usuario</label>
                  <select
                    className="form-control"
                    value={this.state.add.client_id}
                    name="client_id"
                    onChange={this.handleChange}
                  >
                    <option value="-1">Seleccione</option>
                    {users}
                  </select>
                </div>
              </React.Fragment>
            ) : null}

            <div className="d-none d-lg-flex w-100" />

            <div className="form-group col-12">
              <label htmlFor="questions">Preguntas</label>
              <select
                multiple={true}
                className="form-control"
                value={this.state.add.questions}
                name="questions"
                onChange={this.handleChangeMultiple}
              >
                {preguntas}
              </select>
            </div>
          </div>

          <button type="submit" className="btn btn-urban">
            Añadir {this.state.section_name_singular}
          </button>
        </form>

        <div className="urban-lista my-5">
          <h2 className="h6 mb-0">Listado de {this.state.section_name}</h2>
          <table className="table">
            <thead>
              <tr>
                <th>ID</th>
                <th>Nombre</th>
                <th>Publico</th>
                <th>Desactivado</th>
                <th>Autorizado</th>
                <th>URL</th>
                <th>Acciones</th>
              </tr>
            </thead>
            <tbody>{rows}</tbody>
          </table>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    api: state.settings.api_url,
    success: state.ui.success,
    user_type: state.auth.type,
    test_route: state.settings.test_route
  };
}
export default connect(mapStateToProps)(Experiments);
