import React, { Component } from "react";
import axios from "axios";
import { connect } from "react-redux";
import urljoin from "url-join";
import { handleChange } from "helpers";

import "./Contact.css";

class Contact extends Component {
  constructor(props) {
    super(props);
    this.contact = this.contact.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.state = {
      error: "",
      success: "",
      add: {
        name: "",
        role: "",
        phone: "",
        institution: "",
        mail: "",
        message: ""
      }
    };
  }
  handleChange(e) {
    handleChange(e, this, "add");
  }
  contact(e) {
    e.preventDefault();
    axios
      .post(urljoin(this.props.api, "contact/"), {
        ...this.state.add
      })

      .then(response => {
        if (response.data.status === "success") {
          this.setState({
            success: response.data.message
          });
        } else {
          this.setState({ error: response.data.message });
        }
      })
      .catch(res => {
        this.setState({ error: res.error });
      });
  }
  render() {
    return (
      <div className="Contact">
        <div className="container py-5">
          {this.state.error ? (
            <div className="alert alert-danger" role="alert">
              {this.state.error}
            </div>
          ) : null}
          {this.state.success ? (
            <div className="alert alert-urban alert-success" role="alert">
              {this.state.success}
            </div>
          ) : null}
          <div className="row justify-content-center">
            <div className="col-12 col-md-8 col-lg-6">
              <form onSubmit={this.contact}>
                <div className="form-group input-group">
                  <div className="input-group-prepend">
                    <span className="input-group-text Contact-prepend"><i className="fas fa-user"></i></span>
                  </div>
                  <input
                    type="text"
                    className="Contact-input form-control "
                    id="name"
                    name="name"
                    onChange={this.handleChange}
                    value={this.state.add.name}
                    placeholder="Nombre *"
                  />
                </div>
                <div className="form-group input-group">
                  <div className="input-group-prepend">
                    <span className="input-group-text Contact-prepend"><i className="fas fa-star"></i></span>
                  </div>
                  <input
                    type="text"
                    className="Contact-input form-control"
                    id="role"
                    name="role"
                    onChange={this.handleChange}
                    value={this.state.add.role}
                    placeholder="Rol *"
                  />
                </div>
                <div className="form-group input-group">
                  <div className="input-group-prepend">
                    <span className="input-group-text Contact-prepend"><i className="fas fa-phone"></i></span>
                  </div>
                  <input
                    type="tel"
                    className="Contact-input form-control"
                    id="phone"
                    name="phone"
                    onChange={this.handleChange}
                    value={this.state.add.phone}
                    placeholder="Número de teléfono *"
                  />
                </div>
                <div className="form-group input-group">
                  <div className="input-group-prepend">
                    <span className="input-group-text Contact-prepend"><i className="fas fa-landmark"></i></span>
                  </div>
                  <input
                    type="text"
                    className="Contact-input form-control"
                    id="institution"
                    name="institution"
                    onChange={this.handleChange}
                    value={this.state.add.institution}
                    placeholder="Institución *"
                  />
                </div>
                <div className="form-group input-group">
                  <div className="input-group-prepend">
                    <span className="input-group-text Contact-prepend"><i className="fas fa-envelope-open"></i></span>
                  </div>
                  <input
                    type="email"
                    className="Contact-input form-control"
                    id="mail"
                    name="mail"
                    onChange={this.handleChange}
                    value={this.state.add.mail}
                    placeholder="Correo electrónico *"
                  />
                </div>
                <div className="form-group">
                  <textarea
                    className="Contact-textarea form-control"
                    id="message"
                    name="message"
                    onChange={this.handleChange}
                    value={this.state.add.message}
                    placeholder="Mensaje *"
                  />
                </div>
                <button className="btn Contact-btn" type="submit">
                  Enviar
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
function mapStateToProps(state) {
  return {
    api: state.settings.api_url
  };
}
export default connect(mapStateToProps)(Contact);
