function calcPolygonArea(vertices) {
  var total = 0;

  for (var i = 0, l = vertices.length; i < l; i++) {
    var addX = vertices[i].x;
    var addY = vertices[i == vertices.length - 1 ? 0 : i + 1].y;
    var subX = vertices[i == vertices.length - 1 ? 0 : i + 1].x;
    var subY = vertices[i].y;

    total += addX * addY * 0.5;
    total -= subX * subY * 0.5;
  }

  return Math.abs(total);
}

export default function(polygons) {
  const polygons_categories = {};
  // Calcular areas de cada categoria
  polygons.forEach(polygon => {
    const name =
      typeof polygon.category.name === "undefined"
        ? polygon.category.nombre
        : polygon.category.name;
    if (!(name in polygons_categories)) {
      polygons_categories[name] = {
        area: 0,
        color: polygon.category.color
      };
    }
    const vertices =
      typeof polygon.coords === "string"
        ? JSON.parse(polygon.coords)
        : polygon.coords;
    polygons_categories[name].area += calcPolygonArea(vertices);
  });
  // Normalizamos las areas para calcular %

  Object.keys(polygons_categories).forEach(category => {
    polygons_categories[category].area = parseFloat(
      (polygons_categories[category].area / (1280 * 720)).toFixed(4)
    );
  });

  return polygons_categories;
}
