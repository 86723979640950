import jwtDecode from "jwt-decode";

const initialState = (token => ({
  isAuthenticating: false,
  errorMessage: null,
  id: token ? jwtDecode(token).id : null,
  currentUser: token ? jwtDecode(token).id : null,
  type: token ? jwtDecode(token).type : null,
  name: token ? jwtDecode(token).name : null,
  token: token || null
}))(localStorage.authToken);

const reducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case "LOGIN_REQUEST":
      return {
        ...state,
        isAuthenticating: true
      };
    case "LOGIN_FAILURE":
      return {
        ...state,
        isAuthenticating: false,
        errorMessage: action.errorMessage
      };
    case "LOGIN_SUCCESS":
      return {
        isAuthenticating: false,
        currentUser: action.user.id,
        errorMessage: null,
        type: action.user.type,
        id: action.user.id,
        name: action.user.name,
        token: action.token
      };
    case "LOGOUT":
      localStorage.removeItem("authToken");
      return {
        isAuthenticating: false,
        currentUser: null,
        errorMessage: null,
        type: null,
        name: null,
        token: null
      };
    default:
      return state;
  }
};
export default reducer;
