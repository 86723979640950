import React, { Component } from "react";
import { connect } from "react-redux";
import axios from "axios";
import urljoin from "url-join";
import { Link } from "react-router-dom";
import PerfectScrollbar from "react-perfect-scrollbar";

import "./ExperimentList.css";
import "react-perfect-scrollbar/dist/css/styles.css";

class ExperimentList extends Component {
  constructor(props) {
    super(props);
    this.getData = this.getData.bind(this);
    this.state = {
      loading: true,
      experiments: []
    };
  }
  componentDidMount() {
    this.getData();
  }

  getData() {
    axios
      .get(urljoin(this.props.api, "tests/"))
      .then(response => {
        this.setState({ experiments: response.data.data, loading: false });
      })
      .catch(res => {
        alert(res);
      });
  }
  render() {
    const experiments = [];
    if (typeof this.state.experiments.forEach === "function") {
      this.state.experiments.forEach(item => {
        if (item.state == "complete") {
          return;
        }
        experiments.push(
          <div key={item.id} className="col-12 col-sm-6 ">
            <div
              className="experimento"
              style={
                item.url_background_image != "background_image"
                  ? { backgroundImage: `url("${item.url_background_image}")` }
                  : {}
              }
            >
              <Link to={"/experimentos/" + item.id}>
                <h2 className="h4">{item.name}</h2>
              </Link>
              <div className="row">
                <div className="col-8">
                  {" "}
                  <div className="descripcion">
                    <PerfectScrollbar>
                      <p>{item.description}</p>
                    </PerfectScrollbar>
                  </div>
                </div>
                <div className="col">
                  <div className="institucion">
                    {!item.logo.startsWith("url:") ? (
                      <img
                        src={item.logo}
                        className="img-fluid"
                        width="100"
                        alt={"Logo de " + item.client}
                      />
                    ) : null}

                    <small>{item.client}</small>
                  </div>
                </div>
              </div>
              <Link to={"/experimentos/" + item.id} className="btn btn-urban">
                Iniciar Experimento
              </Link>
            </div>
          </div>
        );
      });
    }

    return (
      <div className="Degradado-bg">
        <div className="container">
          <div className="ExperimentList">
            <h1>Bienvenido</h1>
            <p>Seleccione un experimento para realizar</p>
            <div className="row">{experiments}</div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    user: state.auth.currentUser,
    api: state.settings.api_url
  };
}
export default connect(mapStateToProps)(ExperimentList);
