class PoligonsDrawer {
  constructor() {
    this.states_types = {
      STATE_WAIT: 0,
      STATE_ADDVERTEX_WAIT: 1,
      STATE_POLYGON_SELECTED: 2,
      STATE_VERTEX_SELECTED: 3
    };
    this.state = {
      polyInputState: this.states_types.STATE_WAIT,
      selectedPolygonIdx: -1,
      selectedVertexIdx: -1,
      mouseDown: false,
      lastX: 0,
      lastY: 0
    };

    this.startCanvas = this.startCanvas.bind(this);
    this.onCanvasMouseDown = this.onCanvasMouseDown.bind(this);
    this.onCanvasMouseMove = this.onCanvasMouseMove.bind(this);
    this.onCanvasMouseUp = this.onCanvasMouseUp.bind(this);
    this.pnpoly = this.pnpoly.bind(this);

    // Public Api
    this.setPolygons = this.setPolygons.bind(this);
    this.getPolygons = this.getPolygons.bind(this);
    this.addPolygon = this.addPolygon.bind(this);
    this.removePolygon = this.removePolygon.bind(this);
    this.changeCategory = this.changeCategory.bind(this);
    this.ToggleAddVertex = this.ToggleAddVertex.bind(this);
    this.newPolygonsAdded = -100000;
  }

  setImage(image) {
    this.image = image;
  }
  setCanvas(canvas) {
    this.canvas = canvas;
  }

  setPolygons(polygons) {
    this.polygons = polygons;

    this.drawCanvas();
  }

  getPolygons() {
    return this.polygons || [];
  }

  addPolygon(category) {
    this.newPolygonsAdded++;
    const addedPolygonId = this.polygons.push({
      id: this.newPolygonsAdded,
      coords: [],
      type: 1,
      category
    });
    this.selectPolygonWithIndex(addedPolygonId - 1);
    return this.polygons;
  }

  ToggleAddVertex(polygonId, toggle) {
    this.polygons.forEach((polygon, key) => {
      if (polygon.id === polygonId) {
        this.selectPolygonWithIndex(toggle ? -1 : key);
        this.state.polyInputState = toggle
          ? this.states_types.STATE_WAIT
          : this.states_types.STATE_ADDVERTEX_WAIT;
      }
    });
  }

  changeCategory(polygonId, category) {
    this.polygons.forEach((polygon, key) => {
      if (polygon.id === polygonId) {
        this.polygons[key].category = category;
      }
    });
    this.drawCanvas();
    return this.polygons;
  }

  removePolygon(polygonId) {
    this.polygons.forEach((polygon, key) => {
      console.log(polygon.id, polygonId);
      if (polygon.id === polygonId) {
        this.polygons.splice(key, 1);
      }
    });
    this.drawCanvas();
    return this.polygons;
  }

  selectPolygonWithIndex(idx) {
    this.state.selectedPolygonIdx = idx;
    this.state.polyInputState = this.states_types.STATE_POLYGON_SELECTED;
  }

  startCanvas() {
    this.image.onload = () => {
      this.canvas.height = this.image.clientHeight + 4;
      this.canvas.width = this.image.clientWidth + 4;

      this.drawCanvas();
    };
    // Run onload code.

    this.canvas.onmousedown = this.onCanvasMouseDown;
    this.canvas.onmousemove = this.onCanvasMouseMove;
    this.canvas.onmouseup = this.onCanvasMouseUp;
  }

  hexToRgb(hex) {
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return `${parseInt(result[1], 16)}, ${parseInt(result[2], 16)}, ${parseInt(
      result[3],
      16
    )},`;
  }

  onCanvasMouseDown(evt) {
    this.state.mouseDown = true;

    const rect = this.canvas.getBoundingClientRect();
    const x = evt.clientX - rect.left;
    const y = evt.clientY - rect.top;
    this.state.lastX = x;
    this.state.lastY = y;

    let newSelectedVertexIdx = -1;
    let newSelectedPolygonIdx = -1;
    for (let j = 0; j < this.polygons.length; j++) {
      const dots = this.polygons[j].coords;
      for (let i = 0; i < dots.length; i++) {
        const dot = dots[i];
        if ((x - dot.x) * (x - dot.x) + (y - dot.y) * (y - dot.y) < 25) {
          newSelectedVertexIdx = i;
          newSelectedPolygonIdx = j;
          break;
        }
      }
    }
    // Selecciono un vertice
    if (newSelectedVertexIdx >= 0) {
      this.state.selectedPolygonIdx = newSelectedPolygonIdx;
      this.state.selectedVertexIdx = newSelectedVertexIdx;
      this.state.polyInputState = this.states_types.STATE_VERTEX_SELECTED;
      // Añadiendo vertices
    } else if (
      this.state.polyInputState === this.states_types.STATE_ADDVERTEX_WAIT
    ) {
      console.log(this.polygons, this.state.selectedPolygonIdx);

      const dots = this.polygons[this.state.selectedPolygonIdx].coords;

      if (this.selectedVertexIdx >= 0) {
        dots.splice(this.selectedVertexIdx, 0, { x, y });
      } else {
        dots.push({ x, y });
      }
      this.polygons[this.state.selectedPolygonIdx].coords = dots;
      this.drawCanvas();
    } else if (
      this.state.polyInputState === this.states_types.STATE_VERTEX_SELECTED
    ) {
      const dots = this.polygons[this.state.selectedPolygonIdx].coords;
      dots[this.state.selectedPolygonIdx].x = x;
      dots[this.state.selectedPolygonIdx].y = y;

      this.drawCanvas();

      // Verificar si hace click dentro de un poligono
    } else {
      let newSelectedPolygonIdx2 = -1;
      for (let j = 0; j < this.polygons.length; j++) {
        const dots = this.polygons[j].coords;
        if (this.pnpoly(dots, x, y)) {
          newSelectedPolygonIdx2 = j;
          break;
        }
      }
      if (newSelectedPolygonIdx2 >= 0) {
        this.selectPolygonWithIndex(newSelectedPolygonIdx2);
      }
    }
  }

  onCanvasMouseMove(evt) {
    if (this.state.mouseDown) {
      const canvas = document.getElementById("myCanvas");
      const rect = canvas.getBoundingClientRect();
      const x = evt.clientX - rect.left;
      const y = evt.clientY - rect.top;

      if (
        this.state.polyInputState === this.states_types.STATE_VERTEX_SELECTED
      ) {
        console.log(this.state.selectedVertexIdx);
        const dots = this.polygons[this.state.selectedPolygonIdx].coords;
        dots[this.state.selectedVertexIdx].x = x;
        dots[this.state.selectedVertexIdx].y = y;
        this.drawCanvas();
        // this.refreshOptions();
      }
      if (
        this.state.polyInputState === this.states_types.STATE_POLYGON_SELECTED
      ) {
        const deltaX = x - this.state.lastX;
        const deltaY = y - this.state.lastY;
        const dots = this.polygons[this.state.selectedPolygonIdx].coords;
        for (let i = 0; i < dots.length; i++) {
          this.polygons[this.state.selectedPolygonIdx].coords[i].x += deltaX;
          this.polygons[this.state.selectedPolygonIdx].coords[i].y += deltaY;
        }
        this.drawCanvas();
        // this.refreshOptions();
      }
      this.state.lastX = x;
      this.state.lastY = y;
    }
  }

  onCanvasMouseUp() {
    this.state.mouseDown = false;
  }

  pnpoly(dots, x, y) {
    let i;
    let j;
    let c = 0;
    const npol = dots.length;
    for (i = 0, j = npol - 1; i < npol; j = i++) {
      if (
        ((dots[i].y <= y && y < dots[j].y) ||
          (dots[j].y <= y && y < dots[i].y)) &&
        x <
          ((dots[j].x - dots[i].x) * (y - dots[i].y)) /
            (dots[j].y - dots[i].y) +
            dots[i].x
      ) {
        c = !c;
      }
    }
    return c;
  }

  drawCanvas() {
    const clearTxt = this.canvas.getContext("2d");
    let i = 0;
    clearTxt.clearRect(0, 0, this.canvas.width, this.canvas.height);
    if (!this.polygons) {
      return;
    }
    this.polygons.forEach(polygon => {
      const dots = polygon.coords;
      let dot = 0;
      const c2 = this.canvas.getContext("2d");
      clearTxt.fillStyle = `rgba(${this.hexToRgb(polygon.category.color)} 0.5)`;
      c2.beginPath();

      // Dibujar Arcos
      if (dots.length > 0) {
        c2.moveTo(dots[0].x, dots[0].y);
        for (i = 1; i < dots.length; i++) {
          dot = dots[i];
          c2.lineTo(dot.x, dot.y);
        }
        c2.lineTo(dots[0].x, dots[0].y);
      }
      c2.closePath();
      c2.fill();

      // Pintar los nodos de Rojo

      for (i = 0; i < dots.length; i++) {
        dot = dots[i];
        const context = this.canvas.getContext("2d");
        context.beginPath();
        context.arc(dot.x, dot.y, 4, 0, 2 * Math.PI, false);
        context.fillStyle = "red";
        context.fill();
      }
    });
  }
}

export default PoligonsDrawer;
