import React, { Component } from "react";
import axios from "axios";
import { connect } from "react-redux";
import urljoin from "url-join";
import loadGoogleMapsApi from "load-google-maps-api";
import { MapsHandler, handleChange, RequestError } from "helpers";
import { isIOS, isAndroid, isMobile } from "react-device-detect";

import "./MapsImages.css";

class MapsImages extends Component {
  constructor(props) {
    super(props);
    this.add = this.add.bind(this);
    this.handleFocus = this.handleFocus.bind(this);
    this.edit = this.edit.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.getData = this.getData.bind(this);
    this.handleChangeEdit = this.handleChangeEdit.bind(this);
    this.toggleActive = this.toggleActive.bind(this);
    this.getDots = this.getDots.bind(this);

    this.addPolygon = this.addPolygon.bind(this);
    this.addPoint = this.addPoint.bind(this);

    this.state = {
      section_name: "Mapas",
      section_name_singular: "Mapa",
      url_folder: "mapimages",
      error: "",
      success: "",
      add: {
        experiment_id: -1
      },
      table: [],
      experiments: [],
      estado: "",
      MapsHandler: "",
      loadGoogle: false,
      uploading: false,
      users: []
    };
  }
  handleChange(e) {
    handleChange(e, this, "add");
    if (e.target.name === "experiment_id") {
      this.getDots(e);
    }
  }

  handleChangeEdit(e) {
    handleChange(e, this, "edit");
  }
  getDots(e, elid) {
    let id;
    if (elid) {
      id = elid;
    } else {
      id = parseInt(e.target.value, 10);
    }

    if (id > 0) {
      axios
        .get(urljoin(this.props.api, this.state.url_folder, id.toString()))
        .then(response => {
          this.setState({ table: response.data });
        })
        .catch(res => {
          alert(res);
        });
    }
  }
  getData() {
    const id = this.props.match.params.id;

    axios
      .get(urljoin(this.props.api, "experiments", "/"))
      .then(response => {
        this.setState({ experiments: response.data });
      })
      .catch(res => {
        alert(res);
      });

    if (id) {
      axios
        .get(urljoin(this.props.api, this.state.url_folder, id, "/"))
        .then(response => {
          this.setState({ edit: response.data });
        })
        .catch(res => {
          alert(res);
        });
    }
    if (this.props.user_type === "A") {
      axios
        .get(urljoin(this.props.api, "clients"))
        .then(response => {
          this.setState({ users: response.data });
        })
        .catch(res => {
          alert(res);
        });
    }
  }
  toggleActive(e) {
    console.log(e.target.dataset);
    axios
      .put(urljoin(this.props.api, "toggle_imagemap/"), {
        id: parseInt(e.target.dataset.id),
        active: e.target.checked
      })

      .then(response => {
        this.props.dispatch({
          type: "SHOW_SUCCESS",
          success: response.data.message
        });
        this.getDots("", this.state.add.experiment_id);
      })
      .catch(res => {
        this.setState({ error: RequestError(res) });
      });
  }

  componentDidMount() {
    this.getData();
    this.props.dispatch({
      type: "REMOVE_SUCCESS"
    });
    if (!this.props.offline) {
      this.setState({
        MapsHandler: new MapsHandler(this.props.api, this.props.maps_api_key),
        loadGoogle: true
      });

      loadGoogleMapsApi({
        key: this.props.maps_api_key,
        libraries: ["geometry", "drawing"]
      })
        .then(googleMaps => {
          this.state.MapsHandler.initMap(googleMaps);
        })
        .catch(function(error) {
          console.error(error);
        });
      // Asynchronously load the Google Maps script, passing in the callback reference
    }
  }

  enviarAsync(toSend) {
    return new Promise((resolve, reject) => {
      axios
        .post(
          urljoin(this.props.api, this.state.url_folder, "add", "/"),
          toSend
        )
        .then(() => {
          return resolve();
        })
        .catch(error => {
          return reject(error.message);
        });
    });
  }
  async add(e) {
    e.preventDefault();
    this.setState({
      uploading: true
    });
    let toSend = new FormData();
    for (let key in this.state.add) {
      toSend.append(key, this.state.add[key]);
    }

    const mapsData = this.state.MapsHandler.getData();

    this.setState({
      estado: "Subiendo Puntos... "
    });

    const cantidadMarcadores = mapsData.arrayUrlsMarkers.length;
    if (cantidadMarcadores > 0) {
      this.setState({ estado: "Subiendo Puntos... " });
      for (let index = 0; index < cantidadMarcadores; index++) {
        toSend = new FormData();
        for (let key in this.state.add) {
          toSend.append(key, this.state.add[key]);
        }
        toSend.append("markerQuantity", 1);

        toSend.append("markerUrls_0", mapsData.arrayUrlsMarkers[index]);
        toSend.append(
          "markerCoords_0",
          JSON.stringify(mapsData.arrayCoordenadasMarkers[index])
        );
        this.setState({
          estado: `Subiendo Marcador ${index + 1} de ${cantidadMarcadores}`
        });
        if (mapsData.arrayUrlsMarkers[index] !== undefined) {
          await this.enviarAsync(toSend);
        }
      }
    }

    const cantidadImagenes = mapsData.arrayMarkerImagenes.length;
    let imagenesFallidas = 0;
    const idFallidas = [];
    if (cantidadImagenes > 0) {
      this.setState({ estado: "Subiendo Imagenes... " });
      for (let index = 0; index < cantidadImagenes; index++) {
        toSend = new FormData();
        for (let key in this.state.add) {
          toSend.append(key, this.state.add[key]);
        }
        toSend.append("imagesQuantity", 1);
        toSend.append("imagesFiles_0", mapsData.imagenes[index]);
        toSend.append(
          "imagesCoords_0",
          JSON.stringify(mapsData.arrayMarkerImagenes[index])
        );
        this.setState({
          estado: `Subiendo Imagen ${index + 1} de ${cantidadImagenes} [${
            mapsData.imagenes_names[index]
          }]`
        });
        if (
          mapsData.imagenes[index] !== undefined &&
          mapsData.arrayMarkerImagenes[index] !== undefined
        ) {
          await this.enviarAsync(toSend);
        } else {
          imagenesFallidas += 1;
          idFallidas.push(index + 1);
        }
      }
    }

    this.setState({
      estado: `Cargadas ${cantidadImagenes -
        imagenesFallidas} de ${cantidadImagenes}${
        idFallidas.length > 0 ? `,han fallado ${idFallidas.join(", ")}` : ""
      } `
    });
    this.setState({ uploading: false });
  }
  edit(e) {
    e.preventDefault();
    axios
      .post(
        urljoin(this.props.api, urljoin(this.state.url_folder, "/edit", "/")),
        {
          ...this.state.edit
        }
      )

      .then(response => {
        if (response.data.status === "success") {
          this.props.dispatch({
            type: "SHOW_SUCCESS",
            success: response.data.message
          });
          window.location.href = urljoin("/admin/", this.state.url_folder);
        } else {
          this.setState({
            error: response.data.message
          });
        }
      })
      .catch(error => {
        alert(error);
      });
  }

  componentDidUpdate = (prevProps, prevState) => {
    if (prevProps.location !== this.props.location) {
      this.getData();
      this.props.dispatch({
        type: "REMOVE_SUCCESS"
      });
    }
  };
  handleFocus(event) {
    event.target.select();
  }

  addPolygon() {
    this.state.MapsHandler.addPolygon();
  }
  addPoint() {
    this.state.MapsHandler.addPoint();
  }

  render() {
    const experiments = [];
    for (let key in this.state.experiments) {
      const { id, name, user_id } = this.state.experiments[key];
      let usuario = "";
      if (this.props.user_type === "A") {
        usuario = this.state.users.filter(
          elem => elem.client_id === user_id
        )[0];
      }

      experiments.push(
        <option key={id} value={id}>
          {name}{" "}
          {this.props.user_type === "A"
            ? "(" + (usuario ? usuario.nombre_empresa : " ") + ")"
            : null}
        </option>
      );
    }

    const rows = [];

    if (typeof this.state.table.forEach === "function") {
      this.state.table.forEach(punto => {
        let nombre = punto.original_url.split("/");
        nombre = nombre[nombre.length - 1];
        rows.push(
          <tr key={punto.id}>
            <td>{punto.id}</td>
            <td className="d-none d-md-block">
              {punto.x}, {punto.y}
            </td>
            <td>
              <a href={punto.original_url} target="_blank">
                Ver Imagen
              </a>
            </td>
            <td className="d-none d-md-block">{nombre}</td>
            <td>
              <form>
                <label className="ckb">
                  <input
                    type="checkbox"
                    className="form-control"
                    name="active"
                    onChange={this.toggleActive}
                    value={punto.active}
                    checked={punto.active}
                    id="active"
                    data-id={punto.id}
                  />
                  <i className="fas" />
                </label>
              </form>
            </td>
          </tr>
        );
      });
    }

    return (
      <div className="MapsImages">
        {isIOS ? (
          <div className="alert alert-urban">
            <strong>Atención: </strong> Detectamos que tu sistema operativo es{" "}
            {isIOS ? "iOS" : "Android"}, desde este dispositivo no es posible
            obtener las coordenadas GPS de las fotografías tomadas mediante este
            formulario, te recomendamos subirlas desde un computador. <br />{" "}
            <strong>Importante:</strong> Al enviar las imagenes: Aplicaciones
            como Whatsapp reducen mucho la calidad de la imagen, ademas de
            eliminar metadata importante como las coordenadas GPS de la imagen,
            te recomendamos utilizar aplicaciones como Google Drive u otras que
            preservan los datos de la imagen.{" "}
          </div>
        ) : null}
        <div id="test" style={{ height: 0 }} />
        <div>
          {this.state.estado ? (
            <div className="alert alert-urban">{this.state.estado}</div>
          ) : null}
        </div>
        <form onSubmit={this.add} method="post" encType="multipart/form-data">
          <h2 className="h4">Añadir Imágenes/Puntos</h2>
          {this.state.error ? (
            <div className="alert alert-danger" role="alert">
              {this.state.error}
            </div>
          ) : null}
          {this.props.success ? (
            <div className="alert alert-urban alert-success" role="alert">
              {this.props.success}
            </div>
          ) : null}

          <div className="form-row my-3">
            <div className="form-group field-select col-12 col-lg-6">
              <label htmlFor="experiment_id">Experimento</label>
              <select
                className="form-control"
                value={this.state.add.experiment_id}
                name="experiment_id"
                onChange={this.handleChange}
              >
                <option value="-1">Seleccione</option>
                {experiments}
              </select>
            </div>
          </div>
          {this.props.offline ? null : (
            <React.Fragment>
              <div className="up-images">
                <label htmlFor="file">
                  Subir Imagenes Geo Posicionadas:
                  <div className="btn btn-outline mt-2">
                    Elegir archivos{" "}
                    <i className="fas fa-upload color-urban ml-3" />
                  </div>
                </label>
                <input
                  multiple
                  type="file"
                  placeholder="Subir Imagenes Geo Posicionadas"
                  onChange={this.state.MapsHandler.loadMultipleImages}
                  name="filename"
                  id="file"
                />
              </div>

              <div className="my-5">
                <div className="btn-content">
                  <button
                    className="btn mx-1 mx-md-3"
                    type="button"
                    onClick={this.addPolygon}
                  >
                    Agregar POLIGONO
                  </button>
                  <button className="btn" type="button" onClick={this.addPoint}>
                    Agregar PUNTOS
                  </button>
                </div>

                <div className="row" id="map-container">
                  <div className="col pr-0">
                    <div id="map" />
                  </div>
                  <div className="col pr-0" id="place-container">
                    <div id="places" />
                  </div>
                </div>
              </div>

              <button
                type="submit"
                className="btn btn-urban"
                disabled={
                  this.state.uploading || this.state.add.experiment_id < 0
                }
              >
                Guardar Imagenes
                {this.state.uploading ? (
                  <i className="fas fa-spinner fa-pulse" />
                ) : null}
              </button>
              {this.state.add.experiment_id < 0 ? (
                <small className="helper">
                  <i className="fas fa-info-circle" /> Selecciona un experimento
                  para poder subir las imágenes
                </small>
              ) : null}
            </React.Fragment>
          )}
        </form>
        <div className="urban-lista my-5">
          <h2 className="h6 mb-0">Puntos Cargados</h2>
          {rows.length > 0 ? (
            <table className="table">
              <thead>
                <tr>
                  <th>ID</th>
                  <th className="d-none d-md-block">Ubicación</th>
                  <th>Imagen</th>
                  <th className="d-none d-md-block">Nombre</th>
                  <th>Activo</th>
                </tr>
              </thead>
              <tbody>{rows}</tbody>
            </table>
          ) : (
            <p className="sindatos">
              Seleccione un experimento para ver las imágenes asociadas
            </p>
          )}
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    api: state.settings.api_url,
    success: state.ui.success,
    user_type: state.auth.type,
    test_route: state.settings.test_route,
    maps_api_key: state.settings.maps_api_key,
    offline: state.settings.offline
  };
}
export default connect(mapStateToProps)(MapsImages);
