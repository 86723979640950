import React, { Component } from "react";
import { Route, Switch } from "react-router"; // react-router v4
import { Public, Admin, Test, User } from "./layouts";

import "./App.css";

class App extends Component {
  render() {
    return (
      <div className="App">
        <Switch>
          <Route path="/experimentos/" component={Test} />
          <Route path="/admin/" component={Admin} />
          <Route path="/user/" component={User} />
          <Route path="/" component={Public} />
        </Switch>
      </div>
    );
  }
}

export default App;
