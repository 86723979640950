import React, { Component } from "react";
import "./Studies.css";
import ArticleStudies from "./ArticleStudies";
import paper01 from "./img/papers/paper_01.jpg";

export default class Studie extends Component {
  render() {
    return (
      <div className="Studie">
        <div className="container">
          <div className="row py-5">
            <div className="col-12">
              <ArticleStudies
                image={paper01}
                title="A Greener Concrete Jungle: The Impact of Green Spaces on Citizens’ 	Perceptions."
                abstract="This work presents a novel approach aimed at advancing our understanding of the causal impact of different types and scales of green interventions on the urban residents’ perceptions of the sites prior to their being rolled out. In an experimental study involving photo simulation techniques and randomised assignment carried out with attendees of the Biennale on Architecture and Urbanism in Chile in 2017, the impact of four different types of street-level green interventions on people’s subjective perceptions of urban sites is examined. The results indicate that all types of street-level green space interventions significantly increase the perceived happiness and reduce perceived stress associated with the sites, with different effect sizes across different types of interventions. Moreover, the findings suggest that the larger the green intervention, the greater the positive impact on people’s perceived wellbeing and that a subset of the green space interventions examined also positively impact people’s perceptions of safety. The approach offers valuable insights into the causal impact of green space interventions on people’s perceptions and could be used by urban planners to weigh up the benefits of different types of urban green interventions."
                downloadlink="http://storage.urban-experiment.com/papers/Paper_25102018.pdf"
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
