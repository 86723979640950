import React, { Component } from "react";
import PropTypes from "prop-types";

class SharedPolygonsCategories extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    const tbody = [];
    if (this.props.data.length == 0) {
      return;
    }
    Object.entries(this.props.data).forEach(([key, value]) => {
      tbody.push(
        <tr key={key}>
          <td>{key}</td>
          <td>
            <div
              className="color color-lg"
              style={{ backgroundColor: value.color }}
            />
          </td>
          <td>{(value.area * 100).toFixed(2)} %</td>
        </tr>
      );
    });
    return (
      <React.Fragment>
        <div className="urban-lista">
          <h2 className="h6 mb-0">Resumen por Categoría: </h2>
          {tbody.length == 0 ? (
            <p className="sindatos">
              Seleccione un experimento para ver las imágenes asociadas
            </p>
          ) : (
            <table className="table">
              <thead>
                <tr>
                  <td>Categoría</td>
                  <td>Color</td>
                  <td>Cobertura Imagen</td>
                </tr>
              </thead>
              <tbody>{tbody}</tbody>
            </table>
          )}
        </div>
      </React.Fragment>
    );
  }
}
SharedPolygonsCategories.propTypes = {
  data: PropTypes.shape({
    area: PropTypes.number,
    color: PropTypes.string
  }).isRequired
};
export default SharedPolygonsCategories;
