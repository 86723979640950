import React, { Component } from "react";
import logo from "./img/logo.svg";
import "./Footer.css";

class Footer extends Component {
  render() {
    return (
      <footer className="global-footer">
        <div className="container d-lg-flex flex-column flex-lg-row flex-wrap justify-content-start justify-content-lg-between">
          <div className="text-center">
            <img className="UE-logo" src={logo} alt="logo" />
          </div>
          <div className="mt-4 mt-lg-0 d-lg-flex flex-wrap">
            <div className="Footer-text">
              <p>Patrocinado por:</p>
            </div>
            <div className="Footer-images">
              <div id="corfo" />
              <div id="su" />
              <div id="lse" />
            </div>
          </div>
        </div>
        <div id="google_translate_element" />
      </footer>
    );
  }
}

export default Footer;
