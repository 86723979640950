import React, { Component } from "react";
import { Route, Switch } from "react-router"; // react-router v4
import { NavLink } from "react-router-dom";
import {
  Categories,
  Users,
  Preguntas,
  TomarTest,
  Experiments,
  ImagesBanks,
  BeforeAfter,
  MapsImages,
  Resultados,
  SegmentadorList,
  SegmentadorPolygonsEditor
} from "components/Admin";
import { Nav, Footer } from "components";
import { connect } from "react-redux";
import axios from "axios";
import "./admin.css";

class Admin extends Component {
  constructor(props) {
    super(props);
    if (!this.props.user) {
      window.location = "/";
    }
    axios.defaults.headers.common["Authorization"] = this.props.token;
    setTimeout(() => {
      this.props.dispatch({
        type: "REMOVE_SUCCESS"
      });
    }, 10000);
  }
  render() {
    if (!this.props.user) {
      return (
        <div className="Admin">
          <Nav />
          Not Authorized
        </div>
      );
    }
    return (
      <React.Fragment>
        <div className="Admin">
          <Nav />
        </div>
        <div className="Degradado-bg">
          <div className="container pt-4">
            <div className="row">
              <div className="col-2  d-none d-lg-block">
                <ul className="list-group list-group-flush sidemenu">
                  {this.props.type === "A" ? (
                    <React.Fragment>
                      <li className="list-group-item">
                        <NavLink to="/admin/mapsimages">Subir Mapas</NavLink>
                      </li>
                      <li className="list-group-item">
                        <NavLink to="/admin/imagesbanks">
                          Bancos de Imágenes
                        </NavLink>
                      </li>
                      <li className="list-group-item">
                        <NavLink to="/admin/imagesbeforeafter">
                          Imágenes Antes/Después
                        </NavLink>
                      </li>
                      <li className="list-group-item">
                        <NavLink to="/admin/experiments">Experimentos</NavLink>
                      </li>
                      <li className="list-group-item">
                        <NavLink to="/admin/questions">Preguntas</NavLink>
                      </li>
                      <li className="list-group-item">
                        <NavLink to="/admin/users">Usuarios</NavLink>
                      </li>
                      <li className="list-group-item">
                        <NavLink to="/admin/categories">Categorías</NavLink>
                      </li>
                      <li className="list-group-item">
                        <NavLink to="/admin/estadisticas">Estadisticas</NavLink>
                      </li>
                      <li className="list-group-item">
                        <NavLink to="/admin/segmentador">Segmentador</NavLink>
                      </li>
                    </React.Fragment>
                  ) : null}
                  {this.props.type === "C" ? (
                    <React.Fragment>
                      <li className="list-group-item">
                        <NavLink to="/admin/mapsimages">Subir Mapas</NavLink>
                      </li>
                      <li className="list-group-item">
                        <NavLink to="/admin/imagesbanks">
                          Bancos de Imágenes
                        </NavLink>
                      </li>
                      <li className="list-group-item">
                        <NavLink to="/admin/imagesbeforeafter">
                          Imágenes Antes/Después
                        </NavLink>
                      </li>
                      <li className="list-group-item">
                        <NavLink to="/admin/experiments">Experimentos</NavLink>
                      </li>
                      <li className="list-group-item">
                        <NavLink to="/admin/questions">Preguntas</NavLink>
                      </li>
                      <li className="list-group-item">
                        <NavLink to="/admin/estadisticas">Estadísticas</NavLink>
                      </li>
                    </React.Fragment>
                  ) : null}

                  {this.props.type === "R" ? (
                    <React.Fragment>
                      <li className="list-group-item">
                        <NavLink to="/admin/users">Crear Tester</NavLink>
                      </li>
                      <li className="list-group-item">
                        <NavLink to="/admin/tomarTest">Tomar Test</NavLink>
                      </li>
                    </React.Fragment>
                  ) : null}
                </ul>
              </div>
              <div className="col-12 col-lg-10 ">
                <div className="container">
                  <Switch>
                    <Route path="/admin/mapsimages" component={MapsImages} />
                    <Route
                      path="/admin/imagesbeforeafter/:id/edit"
                      component={BeforeAfter}
                    />
                    <Route
                      path="/admin/imagesbeforeafter"
                      component={BeforeAfter}
                    />
                    <Route
                      path="/admin/categories/:id/edit"
                      component={Categories}
                    />
                    <Route path="/admin/categories" component={Categories} />
                    <Route
                      path="/admin/imagesbanks/:id/edit"
                      component={ImagesBanks}
                    />
                    <Route path="/admin/imagesbanks" component={ImagesBanks} />
                    <Route
                      path="/admin/experiments/:id/edit"
                      component={Experiments}
                    />
                    <Route path="/admin/experiments" component={Experiments} />

                    <Route path="/admin/users/:id/edit" component={Users} />
                    <Route path="/admin/users" component={Users} />
                    <Route
                      path="/admin/questions/:id/edit"
                      component={Preguntas}
                    />
                    <Route path="/admin/questions" component={Preguntas} />
                    <Route path="/admin/tomarTest" component={TomarTest} />
                    <Route path="/admin/estadisticas" component={Resultados} />
                    <Route
                      path="/admin/segmentador/edit/:type/:id"
                      component={SegmentadorPolygonsEditor}
                    />
                    <Route
                      path="/admin/segmentador"
                      component={SegmentadorList}
                    />

                    <Route
                      path="/admin/questions"
                      render={() => <div>Index</div>}
                    />

                    <Route
                      render={() => (
                        <div className="emptyStates">Seleccione una acción</div>
                      )}
                    />
                  </Switch>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    user: state.auth.currentUser,
    type: state.auth.type,
    token: state.auth.token
  };
}
export default connect(mapStateToProps)(Admin);
