import React, { Component } from "react";
import { Route, Switch } from "react-router"; // react-router v4
import {
  Login,
  Logout,
  Register,
  PublicTest,
  Home,
  Productos,
  Studies,
  Contact,
  Conditions
} from "components/Public";
import { Nav, Footer, Page404 } from "components";

class Landing extends Component {
  render() {
    return (
      <React.Fragment>
        <div className="Landing">
          <Nav />
        </div>

        <Switch>
          <Route path="/share/:id" component={PublicTest} />
          <Route path="/login" component={Login} />
          <Route path="/register" component={Register} />
          <Route path="/logout" component={Logout} />
          <Route path="/servicios" component={Productos} />
          <Route path="/estudios" component={Studies} />
          <Route path="/contacto" component={Contact} />
          <Route path="/conditions" component={Conditions} />
          <Route exact path="/" component={Home} />
          <Route component={Page404} />
        </Switch>
        <Footer />
      </React.Fragment>
    );
  }
}

export default Landing;
