import React, { Component } from "react";
import axios from "axios";
import { connect } from "react-redux";
import urljoin from "url-join";
import { Link } from "react-router-dom";
import { PolygonsDrawer, handleChange, calculatePolygons } from "helpers";
import "./ImagesBanks.css";
import SharedPolygonsCategories from "./shared/SharedPolygonsCategories";

class Experiments extends Component {
  constructor(props) {
    super(props);
    this.handleFocus = this.handleFocus.bind(this);
    this.edit = this.edit.bind(this);
    this.handleChange = this.handleChange.bind(this);

    this.getData = this.getData.bind(this);
    this.handleChangeEdit = this.handleChangeEdit.bind(this);

    this.changeCategory = this.changeCategory.bind(this);
    this.removePolygon = this.removePolygon.bind(this);
    this.toggleVertex = this.toggleVertex.bind(this);
    this.changeImage = this.changeImage.bind(this);
    this.state = {
      section_name: "Bancos de Imágenes",
      section_name_singular: "Banco de Imágenes",
      url_folder: "segmentador",
      error: "",
      success: "",
      edit: {
        name: "",
        experiment: "",
        polygons: [],
        is_approved: false,
        images: [],
        amount_answers: 0
      },
      add: { name: "", experiment: "", is_approved: false },
      table: [],
      users: [],
      experiments: [],
      categories: [],
      PolygonsDrawer: new PolygonsDrawer(),
      addingVertex: false,
      polygonsCategories: {}
    };
  }
  handleChange(e) {
    handleChange(e, this, "add");
  }
  changeImage(e) {
    this.setState({ backgroundImage: e.target.value });
  }
  handleChangeEdit(e) {
    handleChange(e, this, "edit");
  }
  getData() {
    const { id, type } = this.props.match.params;
    axios
      .get(urljoin(this.props.api, "experiments", "/"))
      .then(response => {
        this.setState({
          experiments: response.data
        });
      })
      .catch(res => {
        alert(res);
      });

    axios
      .get(urljoin(this.props.api, "polygons", type, id))
      .then(response => {
        this.setState({
          edit: {
            ...response.data
          },
          polygonsCategories: calculatePolygons(response.data.polygons),
          backgroundImage: response.data.original_url
        });
        this.state.PolygonsDrawer.setImage(document.getElementById("imagen"));
        this.state.PolygonsDrawer.setCanvas(
          document.getElementById("myCanvas")
        );
        if (response.data.original_url) {
          this.state.PolygonsDrawer.startCanvas();
          if (response.data.polygons) {
            const poligonos = response.data.polygons;
            for (let index = 0; index < poligonos.length; index++) {
              poligonos[index].coords = JSON.parse(poligonos[index].coords);
            }
            this.state.PolygonsDrawer.setPolygons(poligonos);
          }
        }
      })
      .catch(res => {
        alert(res);
      });

    axios
      .get(urljoin(this.props.api, "categories", "/"))
      .then(response => {
        this.setState({
          categories: response.data,

          add: {
            ...this.state.add,
            newcategory: response.data[0].id
          }
        });
      })
      .catch(res => {
        alert(res);
      });
  }
  componentDidMount() {
    this.getData();
  }

  edit(e) {
    e.preventDefault();
    const poligonos = this.state.PolygonsDrawer.getPolygons();
    console.log(poligonos);
    for (let index = 0; index < poligonos.length; index++) {
      poligonos[index].coords = JSON.stringify(poligonos[index].coords);
    }
    this.setState({
      edit: {
        ...this.state.edit,
        polygons: poligonos ? poligonos : []
      }
    });
    console.log(this.state.edit);
    axios
      .put(
        urljoin(
          this.props.api,
          urljoin(
            "update_polygons",
            this.props.match.params.type,
            this.props.match.params.id.toString()
          )
        ),
        {
          ...this.state.edit
        }
      )

      .then(response => {
        this.props.dispatch({
          type: "SHOW_SUCCESS",
          success: "Banco de imágenes actualizado correctamente"
        });
        window.location.href = urljoin("/admin/", this.state.url_folder, "/");
      })
      .catch(error => {
        alert(error);
      });
  }

  componentDidUpdate = (prevProps, prevState) => {
    if (prevProps.location !== this.props.location) {
      this.getData();
      this.props.dispatch({
        type: "REMOVE_SUCCESS"
      });
    }
  };
  handleFocus(event) {
    event.target.select();
  }

  changeCategory(event) {
    const id = event.target.getAttribute("data-id");
    const category = this.state.categories.filter(
      elem => elem.id === parseInt(event.target.value, 10)
    )[0];
    const polygons = this.state.PolygonsDrawer.changeCategory(
      parseInt(id, 10),
      category
    );

    this.setState({
      edit: {
        ...this.state.edit,
        polygons
      },
      polygonsCategories: calculatePolygons(polygons)
    });
  }
  removePolygon(event) {
    const id = event.target.getAttribute("data-id");
    const polygons = this.state.PolygonsDrawer.removePolygon(parseInt(id, 10));

    this.setState({
      edit: {
        ...this.state.edit,
        polygons
      },
      polygonsCategories: calculatePolygons(polygons)
    });
  }
  toggleVertex(event) {
    const id = event.target.getAttribute("data-id");
    this.state.PolygonsDrawer.ToggleAddVertex(
      parseInt(id, 10),
      this.state.addingVertex
    );
    this.setState({
      addingVertex: !this.state.addingVertex
    });
  }

  render() {
    const imagenes = [];

    const options = [];
    for (let key in this.state.experiments) {
      const { id, name, user_id } = this.state.experiments[key];
      let usuario = "";
      if (this.props.user_type === "A") {
        usuario = this.state.users.filter(
          elem => elem.client_id === user_id
        )[0];
      }

      options.push(
        <option key={id} value={id}>
          {name}{" "}
          {this.props.user_type === "A"
            ? "(" + (usuario ? usuario.nombre_empresa : " ") + ")"
            : null}
        </option>
      );
    }
    const categories = [];
    this.state.categories.forEach(category => {
      categories.push(
        <option key={category.id} value={category.id}>
          {category.nombre}
        </option>
      );
    });

    const poligonos = [];
    if (this.state.edit.polygons) {
      this.state.edit.polygons.forEach((element, index) => {
        poligonos.push(
          <li key={element.id} className="mb-3">
            Poligono {element.id}
            <select
              onChange={this.changeCategory}
              data-id={element.id}
              value={this.state.edit.polygons[index].category.id}
              className="mx-3"
            >
              {categories}
            </select>
            <button
              className="btn btn-urban btn-sm mr-3"
              data-id={element.id}
              onClick={this.toggleVertex}
              type="button"
            >
              {this.state.addingVertex ? "Dejar de Editar" : "Añadir Vertice"}
            </button>
            {this.state.edit.amount_answers === 0 ? (
              <button
                className="btn btn-danger btn-sm"
                data-id={element.id}
                onClick={this.removePolygon}
                type="button"
              >
                <i className="fas fa-times" />
              </button>
            ) : null}
          </li>
        );
      });
    }

    return (
      <div className="imageBanks">
        <form onSubmit={this.edit} method="post">
          {this.state.error ? (
            <div className="alert alert-danger" role="alert">
              {this.state.error}
            </div>
          ) : null}

          <div className="form-row my-3">
            {this.state.backgroundImage ? (
              <React.Fragment>
                <div className="canvasContainer col-12 my-3">
                  <img src={this.state.backgroundImage} id="imagen" />
                  <canvas id="myCanvas" />
                </div>
                <SharedPolygonsCategories
                  data={this.state.polygonsCategories}
                />

                <div id="canvasController" className="col-12">
                  <h3>Poligonos</h3>
                  <ul>{poligonos}</ul>
                </div>
              </React.Fragment>
            ) : null}
          </div>

          <button type="submit" className="btn btn-urban my-4 mb-5">
            Guardar Cambios
          </button>
        </form>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    api: state.settings.api_url,
    success: state.ui.success,
    user_type: state.auth.type,
    test_route: state.settings.test_route,
    offline: state.settings.offline
  };
}
export default connect(mapStateToProps)(Experiments);
