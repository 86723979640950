import React, { Component } from "react";
import axios from "axios";
import { connect } from "react-redux";
import urljoin from "url-join";
import { Link } from "react-router-dom";
import { handleChange } from "helpers";
import "./Categories.css";
class Categories extends Component {
  constructor(props) {
    super(props);
    this.addCategory = this.addCategory.bind(this);
    this.editCategory = this.editCategory.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.getData = this.getData.bind(this);
    this.handleChangeEdit = this.handleChangeEdit.bind(this);
    this.state = {
      error: "",
      success: "",
      nombre: "",

      edit: {
        nombre: "",
        color: "",
        id: "",
        active: false
      },
      add: {
        nombre: "",
        color: "#000000",
        id: "",
        active: false
      },
      active: true,
      table: []
    };
  }
  handleChange(e) {
    handleChange(e, this, "add");
  }

  handleChangeEdit(e) {
    handleChange(e, this, "edit");
  }
  getData() {
    const id = this.props.match.params.id;
    if (id) {
      axios
        .get(urljoin(this.props.api, "categories", id))
        .then(response => {
          this.setState({ edit: response.data });
        })
        .catch(res => {
          alert(res);
        });
    } else {
      axios
        .get(urljoin(this.props.api, "categories", "/"))
        .then(response => {
          console.log(response.data);
          this.setState({ table: response.data });
        })
        .catch(res => {
          alert(res);
        });
    }
  }
  componentDidMount() {
    this.getData();
    this.props.dispatch({
      type: "REMOVE_SUCCESS"
    });
  }

  addCategory(e) {
    e.preventDefault();
    axios
      .post(urljoin(this.props.api, "categories/add", "/"), {
        ...this.state.add
      })

      .then(response => {
        this.props.dispatch({
          type: "SHOW_SUCCESS",
          success: response.data.message
        });
        window.location.href = "/admin/categories/";
      })
      .catch(error => {
        alert(error);
      });
  }
  editCategory(e) {
    e.preventDefault();
    axios
      .put(
        urljoin(this.props.api, "category/", this.state.edit.id.toString()),
        {
          ...this.state.edit
        }
      )

      .then(response => {
        this.props.dispatch({
          type: "SHOW_SUCCESS",
          success: "Categoría actualizada correctamente"
        });
        window.location.href = "/admin/categories/";
      })
      .catch(error => {
        this.setState({
          error
        });
      });
  }

  componentDidUpdate = (prevProps, prevState) => {
    if (prevProps.location !== this.props.location) {
      this.getData();
      this.props.dispatch({
        type: "REMOVE_SUCCESS"
      });
    }
  };

  render() {
    if (this.props.match.params.id) {
      return (
        <form onSubmit={this.editCategory} method="post">
          {this.state.error ? (
            <div className="alert alert-danger" role="alert">
              {this.state.error}
            </div>
          ) : null}

          <h2 className="h4">Editar Categoría {this.state.edit.id}</h2>
          
          <div class="form-row my-3">
            <div className="form-group col-12 col-lg-6 field-input">
              <label htmlFor="nombre">Nombre Categoría</label>
              <input
                type="text"
                className="form-control"
                name="nombre"
                onChange={this.handleChangeEdit}
                value={this.state.edit.nombre}
                id="nombre"
                placeholder="Ingrese nombre"
              />
            </div>

            <div className="form-group col-12 col-lg-6 field-input">
              <label htmlFor="color">Color Categoría</label>
              <input
                type="color"
                className="form-control"
                name="color"
                onChange={this.handleChangeEdit}
                value={this.state.edit.color}
                id="color"
                placeholder="Ingrese color"
              />
            </div>

            <div className="form-group col-12 col-lg-6 field-input">
              <label htmlFor="active" className="ckb">
                Activo
                <input
                  type="checkbox"
                  className="form-control"
                  name="active"
                  onChange={this.handleChangeEdit}
                  value={this.state.edit.active}
                  checked={this.state.edit.active}
                  id="active"
                  placeholder="Ingrese color"
                />
                <i class="fas"></i>
              </label>
            </div>
          </div>

          <button type="submit" className="btn btn-urban">
            Editar Categoría
          </button>
        </form>
      );
    }

    const rows = [];
    this.state.table.forEach(row => {
      const { id, nombre, color, active } = row;

      rows.push(
        <tr key={id}>
          <td>{id}</td>
          <td>{nombre}</td>
          <td>
            {color} <div className="color" style={{ backgroundColor: color }} />{" "}
          </td>
          <td>
            <i className={`fas fa-${active ? "times-circle" : "check-circle"}`} />
          </td>
          <td>
            <Link to={`/admin/categories/${id}/edit`}>Editar</Link>
          </td>
        </tr>
      );
    });
    return (
      <div className="Categories">
        <form onSubmit={this.addCategory} method="post">
          <h2 className="h4">Añadir Categoría</h2>
          {this.state.error ? (
            <div className="alert alert-danger" role="alert">
              {this.state.error}
            </div>
          ) : null}
          {this.props.success ? (
            <div className="alert alert-urban alert-success" role="alert">
              {this.props.success}
            </div>
          ) : null}

          <div class="form-row my-3">
            <div className="form-group col-12 col-lg-6 field-input">
              <label htmlFor="nombre">Nombre Categoría</label>
              <input
                type="text"
                className="form-control"
                name="nombre"
                onChange={this.handleChange}
                value={this.state.add.nombre}
                id="nombre"
                placeholder="Ingrese nombre"
              />
            </div>
            <div className="form-group col-12 col-lg-6 field-input">
              <label htmlFor="color">Color Categoría</label>
              <input
                type="color"
                className="form-control"
                name="color"
                onChange={this.handleChange}
                value={this.state.add.color}
                id="color"
                placeholder="Ingrese color"
              />
            </div>
          </div>
          <button type="submit" className="btn btn-urban">
            Añadir Categoría
          </button>
        </form>

        <div className="urban-lista my-5">
          <h2 className="h6 mb-0">Listado de Categorías</h2>
          <table className="table">
            <thead>
              <tr>
                <th>ID</th>
                <th>Nombre</th>
                <th>Color</th>
                <th>Desactivado</th>
                <th>Acciones</th>
              </tr>
            </thead>
            <tbody>{rows}</tbody>
          </table>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    api: state.settings.api_url,
    success: state.ui.success
  };
}
export default connect(mapStateToProps)(Categories);
