import React, { Component } from "react";

import "./HeatMapImage.css";

class HeatmapImage extends Component {
  constructor(props) {
    super(props);
    this.onMouseEnter = this.onMouseEnter.bind(this);
    this.onMouseLeave = this.onMouseLeave.bind(this);
    this.onMouseMove = this.onMouseMove.bind(this);
    this.imageLoaded = this.imageLoaded.bind(this);

    this.imagen = React.createRef();
    this.state = {
      interval: 0,
      data: {},
      track: false,
      time: 0,
      x: -1,
      y: -1,
      maxTime: 6000,
      showLoading: true
    };
  }

  componentDidMount() {}
  imageLoaded() {
    if (this.props.record) {
      this.setState({
        showLoading: false,
        interval: setInterval(() => {
          if (this.state.time >= this.state.maxTime) {
            clearInterval(this.state.interval);
            //     console.log(this.state.data);
            this.props.onFinish(this.state.data);
          }
          if (this.state.track) {
            this.setState({
              data: {
                ...this.state.data,
                [this.state.time + 20]: [this.state.x, this.state.y]
              }
            });
          }

          this.setState({
            time: this.state.time + 20
          });
        }, 20) // 20 FPS
      });
    } else {
      this.setState({
        showLoading: false
      });
      this.props.onFinish({});
    }
  }
  componentWillUnmount() {
    clearInterval(this.state.interval);
  }

  onMouseMove(e) {
    const elemento = this.imagen.current;
    const x = e.pageX - elemento.getBoundingClientRect().left;
    const y = e.clientY - elemento.getBoundingClientRect().top;
    // console.log(x, y);
    if (0 <= x <= 1280 && 0 <= y <= 720) {
      this.setState({
        track: true,
        x: e.pageX - elemento.getBoundingClientRect().left,
        y: e.clientY - elemento.getBoundingClientRect().top
      });
    } else {
      this.setState({
        track: false
      });
    }
  }
  onMouseLeave() {}
  onMouseEnter() {}
  render() {
    return (
      <div
        className="HeatmapImage"
        onMouseLeave={this.onMouseLeave}
        onMouseEnter={this.onMouseEnter}
        onMouseMove={this.onMouseMove}
      >
        {this.state.showLoading ? (
          <div className="loading">
            <div>
              <i className="fas fa-spinner fa-spin" />
            </div>
          </div>
        ) : null}

        <img
          ref={this.imagen}
          src={this.props.url}
          onLoad={this.imageLoaded}
          width="1280"
          height="720"
        />
      </div>
    );
  }
}

export default HeatmapImage;
