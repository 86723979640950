import React, { Component } from "react";
import axios from "axios";
import { connect } from "react-redux";
import urljoin from "url-join";
import { handleChange, RequestError } from "helpers";
class TomarTest extends Component {
  constructor(props) {
    super(props);
    this.add = this.add.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.getData = this.getData.bind(this);
    this.state = {
      error: "",
      success: "",
      add: {
        user_id: "",
        experiment_id: sessionStorage.getItem("experiment_id")
          ? sessionStorage.getItem("experiment_id")
          : 0,
        eyetracker: true
      },
      experiments: []
    };
  }
  handleChange(e) {
    if (e.target.name === "experiment_id") {
      sessionStorage.setItem("experiment_id", parseInt(e.target.value, 10));
    }
    handleChange(e, this, "add");
  }
  getData() {
    axios
      .get(urljoin(this.props.api, "experiments", "/"))
      .then(response => {
        this.setState({ experiments: response.data });
      })
      .catch(res => {
        this.setState({ error: RequestError(res) });
      });
  }
  componentDidMount() {
    this.getData();
  }

  add(e) {
    e.preventDefault();
    window.location.href = `/experimentos/${
      this.state.add.experiment_id
    }?user_id=${this.state.add.user_id}&eyetracker=${
      this.state.add.eyetracker
    }`;
  }

  render() {
    const options = [];
    this.state.experiments.forEach(element => {
      const { id, name } = element;
      options.push(
        <option key={id} value={id}>
          {name}
        </option>
      );
    });

    return (
      <div className="TomarTest">
        <form onSubmit={this.add} method="post">
          <h2 className="h4">Tomar Test</h2>

          <div class="form-row my-3">
            <div className="form-group col-12 col-lg-6 field-input">
              <label htmlFor="user_id">ID Usuario</label>
              <input
                type="number"
                min="1"
                className="form-control"
                name="user_id"
                onChange={this.handleChange}
                value={this.state.add.user_id}
                id="user_id"
                placeholder="Ingrese user_id"
              />
            </div>

            <div className="form-group col-12 col-lg-6 field-select">
              <label htmlFor="experiment_id">Experimento</label>
              <select
                className="form-control"
                value={this.state.add.experiment_id}
                name="experiment_id"
                onChange={this.handleChange}
              >
                <option value="0">Seleccione</option>
                {options}
              </select>
            </div>
            <div className="form-group col-12 col-lg-6 field-select">
              <label htmlFor="eyetracker">Eye Tracker</label>
              <select
                className="form-control"
                value={this.state.add.eyetracker}
                name="eyetracker"
                onChange={this.handleChange}
              >
                <option value="true">Si</option>
                <option value="false">No</option>
              </select>
            </div>
          </div>

          <button type="submit" className="btn btn-urban">
            Tomar Test
          </button>
        </form>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    api: state.settings.api_url,
    user_type: state.auth.user_type
  };
}
export default connect(mapStateToProps)(TomarTest);
