import React, { Component } from "react";
import axios from "axios";
import { connect } from "react-redux";
import urljoin from "url-join";
import { Link } from "react-router-dom";
import EXIF from "exif-js";

import { handleChange, RequestError } from "helpers";

class BeforeAfter extends Component {
  constructor(props) {
    super(props);
    this.add = this.add.bind(this);
    this.handleFocus = this.handleFocus.bind(this);
    this.edit = this.edit.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.getData = this.getData.bind(this);
    this.readCoords = this.readCoords.bind(this);
    this.handleChangeEdit = this.handleChangeEdit.bind(this);
    this.getImages = this.getImages.bind(this);

    this.state = {
      section_name: "Imágenes Antes/Después",
      section_name_singular: "Imagen Antes/Después",
      url_folder: "imagesbeforeafter",
      error: "",
      success: "",
      edit: { type: "0", imagebank: {}, file: "", active: false },
      add: { type: "0", imagebank: "-1", file: "", active: false, coords: [] },
      table: [],
      users: [],
      experiments: [],
      imagesbanks: [],
      uploading: false
    };
  }
  handleChange(e) {
    if (e.target.name === "file") {
      this.readCoords();
    }
    if (e.target.name === "image_bank") {
      this.getImages(e);
    }
    handleChange(e, this, "add");
  }

  handleChangeEdit(e) {
    handleChange(e, this, "edit");
  }

  getImages(e) {
    const id = parseInt(e.target.value, 10);
    if (id > 0) {
      axios
        .get(urljoin(this.props.api, "imagebank_images", id.toString()))
        .then(response => {
          this.setState({ table: response.data });
        })
        .catch(res => {
          alert(res);
        });
    }
  }
  readCoords() {
    const file = document.getElementById("file").files[0];

    const reader = new FileReader();
    reader.onload = e => {
      //crea el marker con las coordenadas gps de la imagen
      EXIF.getData(file, () => {
        const latitud = EXIF.getTag(file, "GPSLatitude"); // console.log(EXIF.pretty(this));
        const longitud = EXIF.getTag(file, "GPSLongitude");
        if (latitud !== undefined && longitud !== undefined) {
          const latitudDD = (latitud[0] + latitud[1] / 60.0) * -1;
          const longitudDD = (longitud[0] + longitud[1] / 60.0) * -1;
          const coords = [latitudDD, longitudDD];
          this.setState({
            add: {
              ...this.state.add,
              coords
            }
          });
        }
      });
    };
    reader.readAsDataURL(file);
  }
  getData() {
    const id = this.props.match.params.id;

    axios
      .get(urljoin(this.props.api, "imagesbanks/"))
      .then(response => {
        this.setState({ imagesbanks: response.data });
      })
      .catch(res => {
        alert(res);
      });

    if (id) {
      axios
        .get(urljoin(this.props.api, "imagesbeforeafter", id))
        .then(response => {
          this.setState({ edit: response.data });
        })
        .catch(res => {
          alert(res);
        });
    }
    if (this.props.user_type === "A") {
      axios
        .get(urljoin(this.props.api, "clients"))
        .then(response => {
          this.setState({ users: response.data });
        })
        .catch(res => {
          alert(res);
        });
    }
  }

  componentDidMount() {
    this.getData();
    this.props.dispatch({
      type: "REMOVE_SUCCESS"
    });
  }

  add(e) {
    e.preventDefault();
    this.setState({
      uploading: true
    });
    let data = new FormData();
    for (var key in this.state.add) {
      data.append(key, this.state.add[key]);
    }

    axios
      .post(urljoin(this.props.api, "imagebeforeafter", "add", "/"), data)

      .then(response => {
        if (response.data.status === "success") {
          this.props.dispatch({
            type: "SHOW_SUCCESS",
            success: response.data.message
          });
          window.location.href = urljoin("/admin/", this.state.url_folder, "/");
        } else {
          this.setState({ error: response.data.message });
          this.setState({ uploading: false });
        }
      })
      .catch(res => {
        this.setState({ error: RequestError(res) });
        this.setState({ uploading: false });
      });
  }
  edit(e) {
    e.preventDefault();
    axios
      .put(
        urljoin(
          this.props.api,
          "imagebeforeafter",
          this.state.edit.id.toString()
        ),
        {
          ...this.state.edit,
          image_banks: this.state.edit.imagebank.id
        }
      )

      .then(response => {
        this.props.dispatch({
          type: "SHOW_SUCCESS",
          success: "Imagen actualizada correctamente"
        });
        window.location.href = urljoin("/admin/", this.state.url_folder, "/");
      })
      .catch(error => {
        alert(error);
      });
  }

  componentDidUpdate = (prevProps, prevState) => {
    if (prevProps.location !== this.props.location) {
      this.getData();
      this.props.dispatch({
        type: "REMOVE_SUCCESS"
      });
    }
  };
  handleFocus(event) {
    event.target.select();
  }

  // Se encarga de dar tamaño al canvas y llamar a las funciones necesarias;

  render() {
    const options = [];
    let key;

    this.state.imagesbanks.forEach(element => {
      const { id, name, client_id } = element;
      let usuario = "";
      if (this.props.user_type === "A") {
        usuario = this.state.users.filter(
          elem => elem.client_id === client_id
        )[0];
      }

      options.push(
        <option key={id} value={id}>
          {name}{" "}
          {this.props.user_type === "A"
            ? "(" + (usuario ? usuario.nombre_empresa : " ") + ")"
            : null}
        </option>
      );
    });
    if (this.props.match.params.id) {
      return (
        <div className="BeforeAfter">
          <form onSubmit={this.edit} method="post">
            {this.state.error ? (
              <div className="alert alert-danger" role="alert">
                {this.state.error}
              </div>
            ) : null}

            <h2 className="h4">
              Editar {this.state.section_name_singular} {this.state.edit.id}
            </h2>

            <div className="form-row mt-3">
              <div className="form-group col-12 col-lg-6 field-select">
                <label htmlFor="type">Type</label>
                <select
                  className="form-control"
                  value={this.state.edit.type}
                  name="type"
                  onChange={this.handleChangeEdit}
                >
                  <option value="0">Control (Antes)</option>
                  <option value="1">Tratamiento (Después)</option>
                </select>
              </div>

              <div className="d-none d-lg-flex w-100" />

              <div className="form-group col-12 col-lg-6 field-select">
                <label htmlFor="imagenbank">Banco de Imagennes</label>
                <select
                  className="form-control"
                  value={this.state.edit.imagebank.id}
                  name="image_banks"
                  onChange={this.handleChangeEdit}
                >
                  <option value="-1">Seleccione</option>
                  {options}
                </select>
              </div>

              <div className="d-none d-lg-flex w-100" />

              <div className="form-group col-12 col-lg-6">
                <label htmlFor="active" className="ckb">
                  Activo
                  <input
                    type="checkbox"
                    className="form-control"
                    name="active"
                    onChange={this.handleChangeEdit}
                    value={this.state.edit.active}
                    checked={this.state.edit.active}
                    id="active"
                  />
                  <i className="fas" />
                </label>
              </div>
            </div>

            <img src={this.state.edit.original_url} className="img-fluid" />

            <button type="submit" className="btn btn-urban my-3">
              Editar {this.state.section_name_singular}
            </button>
          </form>
        </div>
      );
    }

    const rows = [];

    for (key in this.state.table) {
      const el = this.state.table[key];

      rows.push(
        <tr key={el.id}>
          <td>{el.id}</td>
          <td>{el.type === "0" ? "Control" : "Tratamiento"}</td>
          <td>{el.imagebank.name}</td>
          <td>
            <img width={300} src={el.original_url} />
          </td>
          <td>
            <i className={`fas fa-${!el.active ? "times" : "check"}`} />
          </td>
          <td>
            <Link to={`/admin/${this.state.url_folder}/${el.id}/edit`}>
              Editar
            </Link>
          </td>
        </tr>
      );
    }

    return (
      <div className="BeforeAfter">
        <form onSubmit={this.add} method="post" encType="multipart/form-data">
          {this.props.offline ? null : (
            <React.Fragment>
              <h2 className="h4">Añadir {this.state.section_name_singular}</h2>
              {this.state.error ? (
                <div className="alert alert-danger" role="alert">
                  {this.state.error}
                </div>
              ) : null}
              {this.props.success ? (
                <div className="alert alert-urban alert-success" role="alert">
                  {this.props.success}
                </div>
              ) : null}

              <div className="form-row mt-3">
                <div className="form-group col-12 col-lg-6 field-select">
                  <label htmlFor="type">Type</label>
                  <select
                    className="form-control"
                    value={this.state.add.type}
                    name="type"
                    onChange={this.handleChange}
                  >
                    <option value="0">Control (Antes)</option>
                    <option value="1">Tratamiento (Después)</option>
                  </select>
                </div>
              </div>

              <div className="form-group up-images">
                <label htmlFor="file">
                  Imagen
                  <div className="btn btn-outline mt-2">
                    Seleccionar archivo{" "}
                    <i className="fas fa-upload color-urban ml-3" />
                  </div>
                </label>
                <input
                  type="file"
                  className="form-control"
                  name="file"
                  id="file"
                  onChange={this.handleChange}
                />
              </div>
            </React.Fragment>
          )}
          <div className="form-row">
            <div className="form-group col-12 col-lg-6 field-select">
              <label htmlFor="image_bank">Banco de Imágenes</label>
              <select
                className="form-control"
                value={this.state.add.image_bank}
                name="image_bank"
                onChange={this.handleChange}
              >
                <option value="-1">Seleccione</option>
                {options}
              </select>
            </div>
          </div>
          {this.props.offline ? null : (
            <button
              type="submit"
              className="btn btn-urban"
              disabled={this.state.uploading || this.state.add.image_bank < 0}
            >
              Añadir {this.state.section_name_singular}
              {this.state.uploading ? (
                <i className="fas fa-spinner fa-pulse" />
              ) : null}
            </button>
          )}
        </form>

        <div className="urban-lista my-5">
          <h2 className="h6 mb-0">Listado de {this.state.section_name}</h2>
          {rows.length == 0 ? (
            <p className="sindatos">
              Seleccione un banco de imágenes para ver las imágenes asociadas
            </p>
          ) : (
            <table className="table">
              <thead>
                <tr>
                  <th>ID</th>
                  <th>type</th>
                  <th>Banco</th>
                  <th>Imagen</th>
                  <th>Activa</th>
                  <th>Acciones</th>
                </tr>
              </thead>
              <tbody>{rows}</tbody>
            </table>
          )}
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    api: state.settings.api_url,
    success: state.ui.success,
    user_type: state.auth.type,
    test_route: state.settings.test_route,
    offline: state.settings.offline
  };
}
export default connect(mapStateToProps)(BeforeAfter);
