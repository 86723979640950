import React, { Component } from "react";
import axios from "axios";
import { connect } from "react-redux";
import urljoin from "url-join";
import { Link } from "react-router-dom";
import { ClientsToOptions, handleChange, RequestError } from "helpers";

class Preguntas extends Component {
  constructor(props) {
    super(props);
    this.add = this.add.bind(this);
    this.edit = this.edit.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.getData = this.getData.bind(this);
    this.handleChangeEdit = this.handleChangeEdit.bind(this);
    this.state = {
      section_name: "Preguntas",
      section_name_singular: "Pregunta",
      url_folder: "questions",
      error: "",
      success: "",
      edit: {
        question: "",
        id: "",
        user_id: -1,
        active: false
      },
      add: {
        question: "",
        user_id: -1,
        active: true
      },
      table: [],
      users: {}
    };
  }
  handleChange(e) {
    handleChange(e, this, "add");
  }

  handleChangeEdit(e) {
    handleChange(e, this, "edit");
  }
  getData() {
    const id = this.props.match.params.id;
    axios
      .get(urljoin(this.props.api, "clients/"))
      .then(response => {
        this.setState({ users: response.data });
      })
      .catch(res => {
        alert(res);
      });
    if (id) {
      axios
        .get(urljoin(this.props.api, this.state.url_folder, id))
        .then(response => {
          this.setState({ edit: response.data });
        })
        .catch(res => {
          alert(res);
        });
    } else {
      axios
        .get(urljoin(this.props.api, this.state.url_folder))
        .then(response => {
          this.setState({ table: response.data });
        })
        .catch(res => {
          this.setState({ error: RequestError(res) });
        });
    }
  }
  componentDidMount() {
    this.getData();
    this.props.dispatch({
      type: "REMOVE_SUCCESS"
    });
  }

  add(e) {
    e.preventDefault();
    this.setState({
      add: {
        ...this.state.add,
        user_id: parseInt(this.state.add.user_id, 10)
      }
    });
    axios
      .post(urljoin(this.props.api, this.state.url_folder, "add/"), {
        ...this.state.add
      })

      .then(response => {
        if (response.data.status === "success") {
          this.props.dispatch({
            type: "SHOW_SUCCESS",
            success: response.data.message
          });
          window.location.href = urljoin("/admin/", this.state.url_folder);
        } else {
          this.setState({
            error: response.data.message
          });
        }
      })
      .catch(error => {
        alert(error);
      });
  }
  edit(e) {
    e.preventDefault();
    axios
      .put(
        urljoin(
          this.props.api,
          "update_question/",
          this.state.edit.id.toString()
        ),
        {
          ...this.state.edit
        }
      )

      .then(response => {
        this.props.dispatch({
          type: "SHOW_SUCCESS",
          success: "Pregunta actualiza correctamente"
        });
        window.location.href = urljoin("/admin/", this.state.url_folder);
      })
      .catch(error => {
        this.setState({ error });
      });
  }

  componentDidUpdate = (prevProps, prevState) => {
    if (prevProps.location !== this.props.location) {
      this.getData();
      this.props.dispatch({
        type: "REMOVE_SUCCESS"
      });
    }
  };

  render() {
    const users = ClientsToOptions(this.state.users);

    if (this.props.match.params.id) {
      return (
        <div className="Questions">
          <form onSubmit={this.edit} method="post">
            {this.state.error ? (
              <div className="alert alert-danger" role="alert">
                {this.state.error}
              </div>
            ) : null}

            <h2 className="h4">
              Editar {this.state.section_name_singular} {this.state.edit.id}
            </h2>

            <div class="form-row my-3">
              <div className="form-group col-12 col-lg-3 field-input">
                <label htmlFor="question">Pregunta</label>
                <input
                  type="text"
                  className="form-control"
                  name="question"
                  onChange={this.handleChangeEdit}
                  value={this.state.edit.question}
                  id="question"
                  placeholder="Ingrese texto"
                />
              </div>
              <div className="form-group col-12 col-lg-3 field-input">
                <label htmlFor="text_left">Texto Calificación Minima</label>
                <input
                  type="text"
                  className="form-control"
                  name="text_left"
                  onChange={this.handleChangeEdit}
                  value={this.state.edit.text_left}
                  id="text_left"
                  placeholder="Ingrese texto"
                />
              </div>
              <div className="form-group col-12 col-lg-3 field-input">
                <label htmlFor="text_right">Texto Calificación Máxima</label>
                <input
                  type="text"
                  className="form-control"
                  name="text_right"
                  onChange={this.handleChangeEdit}
                  value={this.state.edit.text_right}
                  id="text_right"
                  placeholder="Ingrese texto"
                />
              </div>
              {this.props.user_type === "A" ? (
                <div className="form-group col-12 col-lg-3 field-select">
                  <label htmlFor="user_id">user_id</label>

                  <select
                    className="form-control select"
                    value={this.state.edit.user_id}
                    name="user_id"
                    onChange={this.handleChangeEdit}
                  >
                    <option value="-1">Todos</option>
                    {users}
                  </select>
                </div>
              ) : null}

              <div className="form-group col-12 col-lg-3 field-input">
                <label htmlFor="active" className="ckb">
                  Activo
                  <input
                    type="checkbox"
                    className="form-control"
                    name="active"
                    onChange={this.handleChangeEdit}
                    value={this.state.edit.active}
                    checked={this.state.edit.active}
                    id="active"
                  />
                  <i class="fas"></i>
                </label>
              </div>
            </div>

            <button type="submit" className="btn btn-urban">
              Guardar
            </button>
          </form>
        </div>
      );
    }

    const rows = [];

    this.state.table.forEach(row => {
      const { id, question, active, user_name } = row;

      rows.push(
        <tr key={id}>
          <td>{id}</td>
          <td>{question}</td>
          {this.props.user_type === "A" ? <td>{user_name}</td> : null}
          <td>
            <i className={`fas fa-${!active ? "times-circle" : "check-circle"}`} />
          </td>
          <td>
            <Link to={`/admin/questions/${id}/edit`}>Editar</Link>
          </td>
        </tr>
      );
    });

    return (
      <div className="Questions">
        <form onSubmit={this.add} method="post">
          <h2 className="h4">Añadir {this.state.section_name_singular}</h2>
          {this.state.error ? (
            <div className="alert alert-danger" role="alert">
              {this.state.error}
            </div>
          ) : null}
          {this.props.success ? (
            <div className="alert alert-urban alert-success" role="alert">
              {this.props.success}
            </div>
          ) : null}
          <div class="form-row my-3">
            <div className="form-group col-12 col-lg-3 field-input">
              <label htmlFor="question">Texto Pregunta</label>
              <input
                type="text"
                className="form-control"
                name="question"
                onChange={this.handleChange}
                value={this.state.add.question}
                id="question"
                placeholder="Ingrese text"
              />
            </div>
            <div className="form-group col-12 col-lg-3 field-input">
              <label htmlFor="text_left">Texto Calificación Minima</label>
              <input
                type="text"
                className="form-control"
                name="text_left"
                onChange={this.handleChange}
                value={this.state.add.text_left}
                id="text_left"
                placeholder="Ingrese texto"
              />
            </div>

            <div className="form-group col-12 col-lg-3 field-input">
              <label htmlFor="text_right">Texto Calificación Máxima</label>
              <input
                type="text"
                className="form-control"
                name="text_right"
                onChange={this.handleChange}
                value={this.state.add.text_right}
                id="text_right"
                placeholder="Ingrese texto"
              />
            </div>

            {this.props.user_type === "A" ? (
              <div className="form-group col-12 col-lg-3 field-select">
                <label htmlFor="user_id">Usuario</label>
                <select
                  className="form-control"
                  value={this.state.add.user_id}
                  name="user_id"
                  onChange={this.handleChange}
                >
                  <option value="-1">Todos</option>
                  {users}
                </select>
              </div>
            ) : null}
          </div>
          <button type="submit" className="btn btn-urban">
            Añadir {this.state.section_name_singular}
          </button>
        </form>

        <div className="urban-lista my-5">
          <h2 className="h6 mb-0">Listado de {this.state.section_name}</h2>
          <table className="table">
            <thead>
              <tr>
                <th>ID</th>
                <th>Texto</th>
                {this.props.user_type === "A" ? <th>Usuario</th> : null}
                <th>Activo</th>
                <th>Acciones</th>
              </tr>
            </thead>
            <tbody>{rows}</tbody>
          </table>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    api: state.settings.api_url,
    success: state.ui.success,
    user_type: state.auth.type
  };
}
export default connect(mapStateToProps)(Preguntas);
